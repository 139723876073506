import React, { useEffect, useState, useRef } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ListIcon from '@mui/icons-material/List';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import TagIcon from '@mui/icons-material/Tag';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import PercentIcon from '@mui/icons-material/Percent';

import ReportList from '../ReportList.js';
import CustomColumnBlock from './CustomColumnBlock';
import PresetColumnBlock from './PresetColumnBlock';
import GroupedColumnBlock from './GroupedColumnBlock';
import DefaultColumnBlock from './DefaultColumnBlock.js';

function InteractiveBar({
    attrList,
    session,
    columnForm,
    attributeForm,
    currentAlteringAttr,
    attributeData,
    groupColumnsForm,
    currentView,
    formDropdownHandler,
    friendlyTermsArray,
    selectAttribute,
    reportCategoryObj,
    selectedFolder,
    resetDropdowns,
    attributeReady,
    reportingView,
    alterCustomColumn,
    addOrRemoveColumns
  }) {

    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    const newReportTemplate = reporting?.data?.reset;
    const updateReporting = session?.reporting?.setData;
    const viewType = session?.env?.viewport?.viewType;
    const baseModule = "reporting";

    const [groupColumnHover, setGroupColumnHover] = useState(false);

    function summaryGoBack(){
        return (
            <div
                key="createBtn"
                className={`row default action g cC bR s e fR nS bold p`}
                onClick={() => {
                    const newSelectedReport = {...newReportTemplate};
                    newSelectedReport.selectAll = false;
                    newSelectedReport.branch = "summary";
                    newSelectedReport.stem = "summary";

                    // updateReporting("selectedReport", newSelectedReport);
                    session?.set(baseModule, "selectedReport", newSelectedReport);
                    attributeForm?.set([]);
                    groupColumnsForm?.set([]);
                    columnForm?.set([]);
                }}
            >
                <div className={`f cR gC1 zTPC`}>
                    <ChevronLeftTwoToneIcon/>
                </div>
                <div className={`bold f cC${viewType === "mobile" ? " gC2" : ''}`}>
                    All Summary Sources
                </div>
            </div>
        )
    }

    function printColumnDefaults(){
        return (
            <div
                key="addDefaultColumns"
                className="row default action g p cC bR s e fR nS bold"
                onClick={()=>{
                    if(selectedReport?.stem === "summary"){
                        addOrRemoveColumns({
                            special : "summaryCount",
                        })
                    }else{
                        addOrRemoveColumns({
                            special : "defaults",
                            value : reporting?.data?.defaultColumns?.[selectedReport?.stem]?.plainArray,
                        })
                    }
                }}
            >
                <div className="f cC gC1">
                    <ViewColumnIcon/>
                </div>
                <div className="f cC gC2 gCW">
                    Add Default Columns
                </div>
            </div>
        )
    }

    function summaryCheck(){
        if(selectedReport?.stem !== "summary"){
            return true;
        }else{
            return selectedReport?.referenceBranch && selectedReport?.referenceStem;
        }
    }

    function printSelectAllOption(){
        return (
            <div
                key="addDefaultColumns"
                className={`row default action g cC bR s e fR nS bold${
                    selectedReport?.selectAll ? " selected" : ''}${
                        currentAlteringAttr ? " inactive" : " p"}`}
                onClick={()=>{
                    if(!currentAlteringAttr){
                        const tempSelectedReport = {...selectedReport}

                        // updateReporting("selectedReport", "selectAll", !tempSelectedReport?.selectAll);
                        session?.set(baseModule, "selectedReport.selectAll", !tempSelectedReport?.selectAll);
                        if(tempSelectedReport?.selectAll){
                            // updateReporting("selectedReport", "query", '');
                            session?.set(baseModule, "selectedReport.query", '');
                        }

                        attributeForm?.set(tempSelectedReport?.selectAll ? [] : [{
                            "attr" : reporting?.data?.selectAllVar,
                            "criterion" : {not_blank: true},
                            "formType" : "int",
                            "groupID" : 1,
                            "groupOperator" : "AND",
                            "inlineOperator" : "AND",
                            "relationType" : undefined,
                            "relativeKey" :undefined,
                        }]);
                    }
                }}
            >
                <div className="f cC gC1">
                    <FilterAltOffIcon/>
                </div>
                <div className={`f cC gC2 gCW`}>
                    Select All
                </div>
            </div>
        )
    }

    function columnClassHandler(key, columnData) {
        let className = '';
        if (currentView === "properties") {
            className += " alt";
        }

        if(columnData?.groupOperator === "groupBy"){
            if(columnData?.attr && columnData?.combineBy && columnData?.friendlyTerm){
                className += " active";
            }else{
                className += " error";
            }
        }else if(columnForm?.data.some((item) => item?.columnName === key)) {
            className += " active";
        }

        return className;
    }

    function processColumns(columnData, columns, isCustom = false) {
        console.log(columnData);
        return columns
            .sort((a, b) => {
                if (isCustom) {
                    return (a?.customKey ?? 0) - (b?.customKey ?? 0);
                } else {
                    if(columnData?.type === "grouping"){
                        return 0;
                    }

                    if(columnData?.type === "default"){
                        const termA = (typeof a === "object" ? a?.friendlyTerm : attributeData?.[a]?.friendlyTerm)?.toUpperCase() ?? '';
                        const termB = (typeof b === "object" ? b?.friendlyTerm : attributeData?.[b]?.friendlyTerm)?.toUpperCase() ?? '';
                        return termA.localeCompare(termB);
                    }
                }
            })
            .filter(key => {
                const attr = (typeof key === "object" ? key : attributeData?.[key]) || {};
                let hasAccess = true;

                // Check if the accessList attribute exists and perform the check against session data
                if (attr?.accessList) {
                    // Flatten accountData keys to check against accessList
                    const accountIDs = Object.keys(session?.user?.data?.accountData || {}).filter(k => k !== "opportunityTypes");
                    // Ensure that at least one id from accessList is present in accountIDs
                    hasAccess = attr.accessList.some(accessId => accountIDs.includes(accessId.toString()));
                }
                // Add a condition to check if 'placeholder' is not true
                return !attr?.placeholder && (attr?.stems?.includes(selectedReport?.referenceStem ?? selectedReport?.stem) || typeof key === "object") && hasAccess;
            })
            .map((key, index) => renderColumn(columnData?.columns, key, key?.id));
    }

    function formulateGroupColumns() {
        const filteredAndMapped = columnForm?.data
            .filter(groupBy => groupBy?.inactive !== true)  // Filter out items where inactive is true
            .map(groupBy => {
                return groupBy
            });
            return filteredAndMapped;
    }

    function formulateDefaultColumns() {
        console.log(777, session?.case?.data?.attributeData, session?.case?.data);
        console.log(Object.keys(session?.case?.data?.attributeData || {})
        .filter(key => attributeData?.[key]?.stems?.includes(selectedReport?.referenceStem ?? selectedReport?.stem))
        .sort((a, b) => (attributeData?.[a]?.friendlyTerm?.toUpperCase() ?? '').localeCompare(attributeData?.[b]?.friendlyTerm?.toUpperCase() ?? '')));
        
        return Object.keys(session?.case?.data?.attributeData || {})
            .filter(key => attributeData?.[key]?.stems?.includes(selectedReport?.referenceStem ?? selectedReport?.stem))
            .sort((a, b) => (attributeData?.[a]?.friendlyTerm?.toUpperCase() ?? '').localeCompare(attributeData?.[b]?.friendlyTerm?.toUpperCase() ?? ''));
    }

    

    function renderColumn(availableColumns, key, index) {
        console.log(availableColumns, key, index);
        function renderIconBasedOnAttribute(key) {
            const { formType, returnType } = attributeData[key] || {};
            if (returnType === "currency") return <AttachMoneyIcon />;
            if (returnType === "percentage") return <PercentIcon />;
            if (formType === "generatedList") return <ListIcon />;
            if (formType === "dropdown") return <ArrowDropDownCircleIcon />;
            if (formType === "date") return <CalendarMonth />;
            if (formType === "int" && returnType !== "currency") return <TagIcon />;
            if (formType === "string") return <FormatQuoteIcon />;
        }

        function renderColumnIcon(key) {
            if (currentView === 'properties') {
                return columnForm?.data.some((item) => item?.columnName === key) ? <BackspaceIcon /> : <NavigateNextIcon />;
            }
            if (currentView === 'requirements' && currentAlteringAttr) {
                return <SwapHorizIcon />;
            }
            return <NavigateNextIcon />;
        }

        function renderCheckCircle(id, columnData) {
            if (currentView !== "properties") return null;

            const checkCompleteCustomColumn = () => 
                columnData?.custom &&
                columnForm?.data?.some(item =>
                    item?.id === columnData?.id &&
                    item?.fieldBy &&
                    item?.summarizeBy &&
                    item?.friendlyTerm
            );

            const checkGroupByColumn = () =>
                columnData?.groupOperator === "groupBy" &&
                columnData?.attr &&
                columnData?.combineBy &&
                columnData?.friendlyTerm;

            const checkColumnFormMatch = () =>
                !columnData?.custom &&
                columnData?.groupOperator !== "groupBy" &&
                columnForm?.data.some(item => item?.columnName === id);

            if (checkCompleteCustomColumn() || checkGroupByColumn() || checkColumnFormMatch()) {
                return <CheckCircleOutlineIcon />;
            } else {
                return columnData?.custom ? <PendingActionsRoundedIcon /> : <RadioButtonUncheckedIcon />;
            }
        }

        const attr = (attributeData?.[key?.attr ?? key]) ?? key ?? {};
        const groupColumn = groupColumnsForm?.data?.find(item => item.id === index);
        const customColumn = availableColumns.find(col => col?.id === key?.id && key?.customKey !== undefined);
        const statusIcon = renderCheckCircle(key, customColumn ?? groupColumn ?? undefined);
        const classNames = columnClassHandler(key, groupColumn ?? undefined);
        const attributeTypeIcon = renderIconBasedOnAttribute(key?.columnName ?? key);

        const baseProps = {
            addOrRemoveColumns: addOrRemoveColumns,
            attr: attr,
            blockID: key?.id || `${key}-${index}`,
            columnObject: customColumn ?? groupColumn ?? key,
            currentView: currentView,
            statusIcon: statusIcon,
        };

        const groupRelatedProps = {
            deleteIcon: <BackspaceIcon />, 
            groupColumnHover: { data: groupColumnHover, set: setGroupColumnHover },
        };


        if (customColumn) {
            const friendlyTerm = customColumn?.friendlyTerm ?? '';
            const fieldByStatus = customColumn?.fieldBy ? true : false;
            const summarizeStatus = customColumn?.summarizeBy ? true : false;

            const completeSummarization = fieldByStatus && summarizeStatus;

            if(customColumn?.custom === "preset"){
                return (
                    <PresetColumnBlock
                        key={baseProps?.blockID}
                        {...baseProps}
                        {...groupRelatedProps}
                    />
                );
            }else{
                return (
                    <CustomColumnBlock
                        key={baseProps?.blockID}
                        {...baseProps}
                        {...groupRelatedProps}
                        alterCustomColumn={alterCustomColumn}
                        attributeData={attributeData}
                        completeSummarization={completeSummarization}
                        formDropdownHandler={formDropdownHandler}
                        friendlyTermsArray={friendlyTermsArray}
                        friendlyTerm={friendlyTerm}
                        index={index}
                        session={session}
                    />
                );
            }
        } else if (groupColumn?.attr && currentView !== "requirements") {
            const firstActiveIndex = groupColumnsForm?.data?.findIndex(item => !item?.inactive);

            return (
                <GroupedColumnBlock
                    key={baseProps?.blockID}
                    {...baseProps}
                    {...groupRelatedProps}
                    attributeTypeIcon={attributeTypeIcon}
                    classNames={classNames}
                    firstActiveIndex={firstActiveIndex}
                    index={index}
                />
            );
        } else {
            console.log(key);
            return (
                <DefaultColumnBlock
                    key={baseProps?.blockID}
                    {...baseProps}
                    attributeTypeIcon={attributeTypeIcon}
                    attrKey={key}
                    classNames={classNames}
                    currentAlteringAttr={currentAlteringAttr}
                    selectAttribute={selectAttribute}
                    transitionIcon={renderColumnIcon}
                />
            );
        }
    }

    if(selectedReport?.stem === "summary" && (!selectedReport?.referenceStem)){
        return (
            <div className="summarySource g dG f dP">
                <ReportList
                    session={session}
                    requestObject={undefined}
                    reportCategoryObj={reportCategoryObj}
                    selectedFolder={selectedFolder}
                    resetDropdowns={resetDropdowns}
                    reportingView={reportingView}
                    summary={true}
                />
            </div>
        )
    }

    const availableColumns = {
        columns : selectedReport?.stem === "summary" && currentView === "properties" ? formulateGroupColumns() : formulateDefaultColumns(),
        type : selectedReport?.stem === "summary" ? "grouping" : "default"
    }
    console.log(availableColumns);
    const defaultColumns = availableColumns?.columns?.filter(column => !column?.custom);
    const customColumns = availableColumns?.columns?.filter(column => column?.custom);
    let defaultColumnRows = processColumns(availableColumns, defaultColumns);
    let customColumnRows = processColumns(availableColumns, customColumns, true);
    const isSummaryReport = selectedReport?.stem === "summary";

    function createGroupingBlock(title, rowsData) {
        const emptyList = (<div className="emptyList f dP cC bold bR">No Existing Columns</div>);
        return (
            <>
                {currentView === "properties" ? (
                    <div className="groupingBlock g dG dP bR fC">
                        <div className="categoryTitle f cC bold">{title}</div>
                        {rowsData?.length ? rowsData : emptyList}
                    </div>
                ) : (
                    <>
                        {isSummaryReport && title &&
                            <div className="categoryTitle f cC bold">
                                {title}
                            </div>
                        }
                        {rowsData?.length ? rowsData : emptyList}
                    </>
                )}
            </>
        );
    }

    if (isSummaryReport) {
        defaultColumnRows = createGroupingBlock(currentView === "properties" && "Group By", defaultColumnRows);
        customColumnRows = createGroupingBlock("Summarized By", customColumnRows);
    } else {
        customColumnRows = createGroupingBlock(undefined, defaultColumnRows);
    }

    return (
        <div className="interactiveList list g fC dP tO" ref={attrList}>
            <div className="actionBlock g dG dP bR">
                {currentView === "requirements" && selectedReport?.referenceStem && summaryGoBack()}
                {currentView === "properties" && printColumnDefaults()}
                {currentView === "requirements" && summaryCheck() && printSelectAllOption()}
                {currentView === "properties" && selectedReport?.stem === "summary" &&
                    <>
                        <div className="row default action g p cC bR s e fR nS bold" onClick={()=>{
                            addOrRemoveColumns({
                                special : "append",
                                value : columnForm?.data,
                                customSet : true,
                            });
                        }}>
                            <div className="f cC gC2 gCW">
                                Create Column
                            </div>
                        </div>
                        {selectedReport?.stem !== "summary" &&
                            <div className="divider">
                            </div> 
                        }
                    </>
                }
            </div>
            {defaultColumnRows}
            {currentView === "properties" && customColumnRows}
        </div>
    );
};

export default InteractiveBar;
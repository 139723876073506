import React, { useEffect, useState } from 'react';

function Notifications(props){
    const [data, setData] = useState();

    function loadNotifications(notificationData){
        setData(notificationData);
    }

    const functions = {
        loadNotifications
    }

    const notifications = {
        data,
        functions
    }

    return notifications;
};

export default Notifications;

import React from 'react';
import { useState, useEffect, useRef } from 'react';

import { authorizeRequest } from '../../envHandler.js';
import { convertIntToCurrency } from '../../envFunctions.js';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

function PremiumSchedule(props){
    const [downloadStatus, setDownloadStatus] = useState(false);
    const [existingPremiums, setExistingPremiums] = useState(false);

    const generateButtonClass = (isActive, extraClasses = '') => {
        return `btnWIcon cC g e bR fMP ${isActive ? "p" : "inactive"} ${extraClasses}`;
    };

    function downloadPremiumSchedule(){
        setDownloadStatus(true);
        const today = new Date();
        const dateData = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = today.toLocaleDateString('en-US', dateData);
        let paramVals = {
          "recordID": props.policyData.recordID,
          "startDate": formattedDate,
        };
      
        let requestURL = "servicing/downloadPremiumSchedule";
      
        if (props.displayType === "shipping") {
          paramVals = {
            "taskID": props.policyData.recordID,
            "recordID": props.premiumScheduleData[props.selectedSchedule].recordID,
          };
      
          requestURL = "marketplace/downloadPremiumSchedules";
        }

        props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals, {"Accept" : "text/csv"})
        .then((blob) => {
            setDownloadStatus(false);
            const objUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.download = "PremiumSchedule " + formattedDate + ".csv";
            link.click();
        });  
    }

    useEffect(() => {
        if(props.premiumScheduleData?.length > 0 && props?.policyData?.fundID){
            setExistingPremiums(true);
        }
    }, [props.premiumScheduleData, props?.policyData?.fundID]);

    let premiumSchedule = [];
    let premiumTableData = [];

    premiumSchedule.push(
        <div key="premiumScheduleHeader" className="premiumScheduleHeader">
            <div key="actionbar" className={`premiumScheduleActionBar ${props.displayType === "shipping" ? "alt" : ""} cC ns fR`}>
                {props.displayType === "shipping" && 
                    <div 
                        className={generateButtonClass(existingPremiums)}
                        onClick={() => existingPremiums && props.setShowPremiumOptions(true)}
                    >
                        <span>Option {(parseInt(props.selectedSchedule) + 1)}</span>
                        <EventRepeatIcon/>
                    </div>
                }
                <div 
                    className={generateButtonClass(existingPremiums, 'p')} 
                    onClick={() => existingPremiums && props.setShowPremiumChart(true)}
                >
                    <span>View Graph</span>
                    <BarChartIcon/>
                </div>
                {downloadStatus ?
                    <div className="btnWIcon cC bR progress alt fMP p">
                        <CircularProgress/>
                    </div>
                    :
                    <div 
                        className={generateButtonClass(existingPremiums, 'p')} 
                        onClick={() => existingPremiums && downloadPremiumSchedule()}
                    >
                        <span>Download</span>
                        <DownloadIcon/>
                    </div>
                }
            </div>
            <div key="premiumSchedulePanelHeader" className="premiumSchedulePanelHeader">
                <div className="premiumScheduleTableRow">
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue">
                            <div>Date</div>
                            <div><CalendarMonthIcon style={{color: "#607d8b"}}/></div>
                        </div>
                    </div>
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue">
                            <div>Premium</div>
                            <div><MonetizationOnIcon style={{color: "#4caf50"}}/></div>
                        </div>
                    </div>
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue">
                            <div>Death Benefit</div>
                            <div><PaymentsIcon style={{color: "#ba68c8"}}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);

    if(props.bidsLoadingStatus){
        premiumSchedule.push(
            <div key="table" className="bidPanelTable">
                <div className="singlePolicyTimelineInner loading loadingDisplay">
                    <div>
                        <CircularProgress color="inherit"/>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="singlePolicyTimelineTracker">
                <div className="bidsPanel">
                    {premiumSchedule}
                </div>
            </div>
        );
    }

    if(props.premiumScheduleData === null){
        premiumSchedule.push(
            <div key="noPremiums" className="noPremiums g cC f">
                Loading
            </div>
        );

        return premiumSchedule;
    }

    if(!props.premiumScheduleData || Object.keys(props.premiumScheduleData)?.length === 0){
        premiumSchedule.push(
            <div key="noPremiums" className="noPremiums g cC f">
                No premium schedule to display
            </div>
        );

        return premiumSchedule;
    }

    let x = 0;
    let currentPremiumSchedule = props.premiumScheduleData;
    let premiumKey = "projectedPremium";
    let deathBenefitKey = "policyDeathBenefit";

    if(props.displayType === "shipping"){
        premiumKey = "premium";
        deathBenefitKey = "deathBenefit";
        currentPremiumSchedule = props.premiumScheduleData[props.selectedSchedule].premiumSchedule;
    }

    for (var key of Object.keys(currentPremiumSchedule)){
        if(x < props.visiblePremiumCount){
            premiumTableData.push(
                <div key={key} className="premiumSchedulePanelTableRow">
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue date">
                            {currentPremiumSchedule[key].date}
                        </div>
                    </div>
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue bold">
                            { convertIntToCurrency(currentPremiumSchedule[key][premiumKey]) }
                        </div>
                    </div>
                    <div className="premiumSchedulePanelDetail">
                        <div className="premiumSchedulePanelDetailValue bold">
                            { convertIntToCurrency(currentPremiumSchedule[key][deathBenefitKey]) }
                        </div>
                    </div>
                </div>
            );
        }
        x++;
    }

    premiumSchedule.push(
        <div key="premiumSchedulePanelTable" className="premiumSchedulePanelTable">
            {premiumTableData}
        </div>
    )

    return premiumSchedule
}
export default PremiumSchedule;
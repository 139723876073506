import React from 'react';
import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import { lightBlue } from '@mui/material/colors';

function CreateLeadNote(props){
    const [noteContent, setNoteContent] = useState("");

    const handleChange = (event) => {
        setNoteContent(event.target.value);
    };
  
    function toggleLeadNotesPanel(){
        props.setShowLeadOptions(false);
    }

    function createNote(){
        const paramVals = {
            "leadID" : props.currentLeadInfo.leadID,
            "content" : noteContent,
            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName,
            "emailOfSender" : props.sessionUser.email,
        };

        props.session?.env?.functions?.buildFetchRequest("createNote", paramVals)
          .then(response => response.json())
          .then(resData => {
            if(resData.status === 200){
                toggleLeadNotesPanel();

                var now = new Date();
                var isoString = now.toISOString();
        
                var newTimelineObj = {  "timeStamp" : isoString,
                                        "type" : "Note",
                                        "status" : noteContent
                                        }
                props.insertNewTimelinePost(newTimelineObj);
            }
          });
    }

    return (
        <div className="leadOptionsBoard" onClick={noteContent === '' ? (evt)=> {props.handleClose(evt)} : null }>
            <div className="leadOptionsBoardInnerWrap moduleBlock">
                <div className="miniModuleHeader">
                    <div className="notificationHeaderTitle">
                        Create a Note
                    </div>
                    <div className="closeBtn" onClick={()=> {toggleLeadNotesPanel()}}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="miniModuleBody">
                    <div className="leadNoteCreate">
                        <div className="leadNoteIcon">
                            <MessageIcon sx={{ color: lightBlue[500] }}/>
                        </div>
                        <textarea   className="messageBox"
                                    placeholder="Create a note"
                                    onChange={handleChange}
                                    rows={20}/>
                    </div>
                    <div className={noteContent === '' ? "updateLeadQuickAccess ns inactive" : "updateLeadQuickAccess ns"}
                        onClick={noteContent === '' ? null : ()=>{createNote()}}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateLeadNote;
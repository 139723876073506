import { useState, useEffect, useRef, useCallback } from 'react';
import Dropdown from '../Dropdown';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function EditMode(props){
    const session = props?.session;
    const baseData = props?.baseData;
    const reporting = session?.reporting;
    const selectedReport = props?.selectedReport
    const rowIndex = props?.rowIndex;
    const colIndex = props?.colIndex;
    const columnName = props?.columnData?.columnName;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;
    const columnData = attributeData?.[columnName];
    const inputRef = props?.inputRef;
    const dataArray = selectedReport?.editingRowValues ?? [];
    const recordID = props?.rowObj?.[reporting?.data?.selectAllVar];
    const foundRowObject = dataArray.find(obj => obj?.[reporting?.data?.selectAllVar] === recordID);
    const columnValue = foundRowObject?.[columnName] ?? props?.columnValue;

    // const [columnValue, setColumnValue] = useState(foundRowObject?.[columnName] ?? props?.columnValue);
    const [showMenu, setShowMenu] = useState(props?.editCellDropdownHandler?.data?.showMenu ?? undefined);
    const [localValue, setLocalValue] = useState(initializeLocalValue());
    const [rawDate, setRawDate] = useState(initializeLocalValue());
    const [formattedValue, setFormattedValue] = useState(initializeFormattedValue());

    function formatDateValue(value) {
        if (value && value.includes('T')) {
            return value.split('T')[0];
        }
        return value;
    }

    function initializeFormattedValue() {
        if (columnData?.returnType === 'currency') {
            let value = foundRowObject?.[columnName] ?? columnValue;
            value = value?.toString()?.replace(/\D/g, '');
    
            if (value === '') {
                return '$0';
            }
    
            return formatCurrencyValue(value);
        }
        return columnValue;
    }

    function formatCurrencyValue(value) {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
            return `$${numericValue.toLocaleString()}`;
        }
        return '';
    }

    function initializeLocalValue(){
        if (columnData?.formType === 'date') {
            return formatDateValue(foundRowObject?.[columnName] ?? columnValue);
        } else if (columnData?.returnType === 'currency') {
            return formatCurrencyValue(foundRowObject?.[columnName] ?? columnValue);
        }

        return foundRowObject?.[columnName] ?? columnValue;
    };

    useEffect(() => {
        console.log("recalibrate?");
        setLocalValue(initializeLocalValue());
        setRawDate(initializeLocalValue());
        setFormattedValue(initializeFormattedValue());
    }, [, baseData?.sorting, selectedReport?.search, selectedReport?.specialColumns, selectedReport?.columns, props?.columnValue]);

    const deepEqual = (a, b) => {
        if (typeof a === 'string' && typeof b === 'number') {
            return a === b.toString();
        }
        if (typeof a === 'number' && typeof b === 'string') {
            return a.toString() === b;
        }
    
        if (typeof a !== typeof b) {
            return false;
        }
        
        if (typeof a !== 'object' || a === null || b === null) {
            return a === b;
        }
        
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length) {
                return false;
            }
            for (let i = 0; i < a.length; i++) {
                if (!deepEqual(a[i], b[i])) {
                    return false;
                }
            }
            return true;
        } else { // Handle plain objects
            const keysA = Object.keys(a);
            const keysB = Object.keys(b);
            if (keysA.length !== keysB.length) {
                return false; // Different number of properties
            }
            
            for (let key of keysA) {
                if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
                    return false; // Different properties or unequal property values
                }
            }
            return true; // All properties are equal
        }
    };

    const handleChange = (e, val) => {
        const inputType = columnData?.formType;
        let newValue = e ? e.target.value : val;

        if (inputType === "int") {
            newValue = newValue.replace(/\D/g, '');
    
            if (columnData?.returnType === "currency") {
                if (newValue === '') {
                    setFormattedValue('$0');
                } else {
                    setFormattedValue(formatCurrencyValue(newValue));
                }
            }
        }

        if (inputType === "date") {
            setRawDate(newValue);
        } else {
            setLocalValue(newValue);
        }

        props?.handleEditModeInputChange(props?.rowObj?.recordID, columnName, newValue);
    };
    
    function inputType(type) {
        if (type === "generatedList") {
            const list = attributeData?.[columnName]?.list?.[baseData?.selectedReport?.stem];
            const filteredList = Object?.entries(list || {}).reduce((acc, [key, value]) => {
                if (value?.[baseData?.selectedReport?.stem]) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            return (
                <Dropdown
                    generatedList={filteredList}
                    setShowMenu={(input) => {
                        const newState = input ?? (showMenu ? undefined : `${rowIndex}-${colIndex}`);
                        setShowMenu(!newState);
                        props?.editCellDropdownHandler?.set(newState);
                        // debouncedDropdown(newState);
                    }}
                    default={localValue ?? props?.columnValue}
                    showMenu={showMenu}
                    onClick={(value) => {
                        handleChange(null, value);
                    }}
                    reset={props?.resetDropdowns}
                    currentValue={localValue || ''}
                    inputRef={inputRef}
                />
            );
        }

        if (type === "dropdown") {
            return (
                <Dropdown
                    list={columnData?.list}
                    setShowMenu={(input) => {
                        const newState = input ?? (showMenu ? undefined : `${rowIndex}-${colIndex}`);
                        setShowMenu(!newState)
                        props?.editCellDropdownHandler?.set(newState);
                        // debouncedDropdown(newState);
                    }}
                    default={localValue ?? props?.columnValue}
                    showMenu={showMenu}
                    onClick={(value) => {
                        handleChange(null, value);
                    }}
                    reset={props?.resetDropdowns}
                    currentValue={localValue || ''}
                    inputRef={inputRef}
                />
            );
        }

        if (type === "int") {
            return (
                <input
                    type="text"
                    value={formattedValue || ''}
                    onChange={(e) => handleChange(e)}
                    className="reportingInput f cC s e bold"
                    ref={inputRef}
                />
            );
        }

        if (type === "string") {
            return (
                <input
                    key={rowIndex + " - " + colIndex}
                    className="reportingInput f cC s e bold"
                    value={localValue || ''}
                    onChange={(e) => handleChange(e)}
                    ref={inputRef}
                />
            );
        }

        if (type === "date") {
            return (
                <input
                    className="inputMatch f bold"
                    type="date"
                    value={rawDate || ''}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    ref={inputRef}
                />
            );
        }
    }

    useEffect(() => {
        const isActive = props?.editCellDropdownHandler?.data?.showMenu === `${rowIndex}-${colIndex}`;
        if(showMenu !== isActive){
            setShowMenu(isActive);
        }
    }, [props?.editCellDropdownHandler?.data]);

    return (
        <div
            key={rowIndex + colIndex}
            className={`inputMatchContainer active g f cC fC${
                foundRowObject?.[columnName] && foundRowObject?.[columnName] !== props?.columnValue ? " changed" : ''}`}
        >
            {inputType(columnData?.formType)}
            {columnData?.formType === "date" &&
                <CalendarMonthIcon sx={{ color: "#42a5f5" }} />
            }
        </div>
    )
};

export default EditMode;
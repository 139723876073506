import { useState, useEffect } from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

function TimelineRequest(props){
    const session = props?.session;
    const [checkStatusClass, setCheckStatusClass] = useState("open");

    function updateCheckStatus(toggle){
        if(props.currentCase?.checkStatus === "Completed"){
            setCheckStatusClass("completed");
                return;
        }

        if(toggle){
            const tempCurrentCase = {...props?.currentCase}

            if(checkStatusClass === "open"){
                setCheckStatusClass("submitted");
                toggleCheckStatus("Submitted");
                tempCurrentCase.data.timelineData.checkStatus = "Submitted";
                if(props?.closingTab === undefined){
                    tempCurrentCase.data.timelineData.openRequestCount--;
                }
            }else{
                setCheckStatusClass("open");
                toggleCheckStatus("Open");
                tempCurrentCase.data.timelineData.checkStatus = "Open";

                if(props?.closingTab === undefined){
                    tempCurrentCase.data.timelineData.openRequestCount++;
                }
            }

            session?.set("case", `${props?.branch}.${props?.stem}[${props?.currentCase?.index}`, tempCurrentCase?.data);
            return;
        }

        if(props.currentCase?.checkStatus === "Submitted"){
            setCheckStatusClass("submitted");  
        }
        if(props.currentCase?.checkStatus === "Open"){
            setCheckStatusClass("open");
        }
    }

    function toggleCheckStatus(toggle){
        const paramVals = {
            "relatedActivityLogID" : props?.currentCase?.relatedActivityLogID,
            "checkStatus" : toggle
        };

        session?.env?.functions?.buildFetchRequest("toggleCheckStatus", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){

                }else{
                    console.log("Error on check status toggle.");
                    console.log(resData);
                }
        });
    }

    var internal = '';
    if(props?.currentCase?.assignedTo === "Internal"){
        internal = " internal";
    }

    useEffect(() => {
        updateCheckStatus();
    }, []);

    if (props?.stem === "servicing") {
        const checkStatusMap = {
            closed: { status: "completed", color: "#29b6f6" },
            new: { status: "open", color: "#ef5350" },
            progress: { status: "submitted", color: "#fbc02d" },
            problem: { status: "issue", color: "#00c853" },
        };
    
        const promptMap = {
            "VOC": "VOC",
            "Trading VOC": "VOC",
            "Request Docs": "Document Request",
            "Conversion": "Conversion Process",
            "EFT": "EFT Setup",
            "_other": "Other",
            "Reallocation and illustration": "Document Request",
            "Follow Up": "Follow Up",
        };
    
        const currentPrompt = props?.currentCase?.carrierTask;
        const statementPrompt = promptMap[currentPrompt] || currentPrompt;
        
        if (!statementPrompt) return;  // exit if the prompt is not mapped
    
        let checkStatus = props?.currentCase?.taskStatus.toLowerCase();
        const { status: mappedStatus, color: statusColor } = checkStatusMap[checkStatus] || {};
        checkStatus = mappedStatus || checkStatus;
    
        return (
            <div className={`timelineRequest VOC ${checkStatus}`} onClick={props?.onClick}>
                <div className="timelinePostTop alt">
                    <div className="timelineRequestIcon cL s">
                        {props?.currentCase.type === "VOC" && <VerifiedUserIcon style={{ color: statusColor }} />}
                    </div>
                    <div className="timelineRequestStatement cL s">
                        {statementPrompt}
                    </div>
                    <div className="timelineRequestInnerWrap servicing">
                        {checkStatus === "open" && <div className="requestInternalPrompt cR e"><span>New</span></div>}
                        {checkStatus === "submitted" && <div className="requestInternalPrompt cR e"><span>Pending</span></div>}
                        {checkStatus === "completed" && <div className="requestInternalPrompt cR e"><span>Done</span></div>}
                    </div>
                    <div className="timelineRequestCheckbox cR e">
                        <div className={`timelineRequestCheckboxStatus internal ${checkStatus}`}>
                            <CheckCircleIcon />
                        </div>
                    </div>
                </div>
                <div className="timelinePostSeparator"></div>
                <div className="timelinePostBottom alt">
                    <div className="cL s">
                        <span>COI: </span>
                        <span>{session?.env?.functions?.convertIntToCurrency(props?.currentCase?.vocData?.currentCOI)}</span>
                    </div>
                    <div className="cC">
                        <span>AV: </span>
                        <span>{session?.env?.functions?.convertIntToCurrency(props?.currentCase?.vocData?.accountValue)}</span>
                    </div>
                    <div className="cC">
                        <span>CSV: </span>
                        <span>{session?.env?.functions?.convertIntToCurrency(props?.currentCase?.vocData?.cashValue)}</span>
                    </div>
                    <div className="timelineRequestTimestamp cR e">
                        <div></div>
                        <div>
                            {session?.env?.functions?.reformatDate(
                                props?.currentCase?.completedDate || props?.currentCase?.dateCreated
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`timelineRequest ${checkStatusClass}`}>
            {props?.closingTab === undefined &&
                <div className="timelineRequestTreeline">
                </div>
            }
            
            <div className="timelineRequestIcon cC">
                <HistoryEduIcon />
            </div>
    
            <div className="timelineRequestInnerWrap">
                <div className="timelineRequestStatement cL">
                    {props?.currentCase?.status}
                </div>
                
                {props?.currentCase?.directLink && (
                    <div
                        className="requestLink cC"
                        onClick={
                            checkStatusClass !== "completed"
                                ? () => props?.activityView?.set({
                                    "view" : "documents",
                                    "shareLink" : props?.currentCase?.directLink
                                })
                            : null
                        }
                    >
                        {checkStatusClass === "completed" 
                            ? null 
                            : checkStatusClass === "open" 
                            ? "Upload" 
                            : "View File"}
                    </div>
                )}
    
                {internal && checkStatusClass !== "completed" && (
                    <div className="requestInternalPrompt cC">
                        <span>Pending</span>
                    </div>
                )}
            </div>
    
            <div className="timelineRequestCheckbox cC">
                <div
                    className={`timelineRequestCheckboxStatus ${checkStatusClass}${internal}`}
                    onClick={
                        props?.currentCase?.assignedTo === "Internal"
                            ? null
                            : () => updateCheckStatus(true)
                    }
                >
                    {checkStatusClass === "open" && (
                        props?.currentCase?.assignedTo === "Internal"
                            ? <PendingActionsIcon />
                            : <RadioButtonUncheckedIcon />
                    )}
                    {checkStatusClass === "completed" && <CheckCircleIcon />}
                    {checkStatusClass === "submitted" && (
                        props?.currentCase?.assignedTo === "Internal"
                            ? <PendingActionsIcon />
                            : <CheckCircleOutlineIcon />
                    )}
                </div>
            </div>
        </div>
    );
}

export default TimelineRequest;

import { useState, useEffect } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

function AssignPanel(props){
    const [assignedUsersIDs, setAssignedUsersIDs] = useState([...props.assignedList]);

    function detectChanges() {
        var a = props.assignedList;
        var b = assignedUsersIDs;

        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
    }

    function toggleAssignPanel(){
        props.setShowAssignPanel(false);
    }

    function toggleTeamMember(userID){
        if(assignedUsersIDs.includes(userID.toString())){
            setAssignedUsersIDs(assignedUsersIDs.filter(useID => useID !== userID.toString()));
        }else{
            setAssignedUsersIDs(assignedUsersIDs => [...assignedUsersIDs, userID.toString()]);
        }
    }

    function displayTeamList(){
        var returningListBlock = [];

        if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.teamList === undefined){
            return (
                <div className="assignTeamMemberList">
                    <div className="loadingBlock">
                        <div className="loadingBlockMessage">
                            No team members on file
                        </div>
                    </div>
                </div>
            )
        }

        props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.teamList.forEach(function (teamMember) {
            if(!teamMember.isTestAccount){
                returningListBlock.push(
                    <div key={teamMember.email}
                        className={assignedUsersIDs.includes(teamMember.userID.toString()) ? "assignTeamMemberRow active ns" : "assignTeamMemberRow ns"}
                        onClick={()=>{toggleTeamMember(teamMember.userID)}}>
                        <div className="assignTeamMemberListName">
                            <div className="assignRowText">
                                {teamMember.firstName + " " + teamMember.lastName}
                            </div>
                        </div>
                        <div className="assignTeamMemberListName">
                            <div className="assignRowText">
                                {teamMember.email}
                            </div>
                        </div>
                        <div className="assignTeamMemberListCheck">
                            {assignedUsersIDs.includes(teamMember.userID.toString()) ?
                                <CheckCircleIcon/>
                            :
                                <CheckCircleOutlineIcon/>
                            }
                        </div>
                    </div>
                )
            }
        });

        return (
            <div className="assignTeamMemberList">
                {returningListBlock}
            </div>
        )
    }

    return (
        <div className="leadOptionsBoard" onClick={(evt)=> {props.handleClose(evt)}}>
            <div className="leadOptionsBoardInnerWrap moduleBlock">
                <div className="miniModuleHeader">
                    <div className="notificationHeaderTitle">
                        Assign List
                    </div>
                    <div className="closeBtn" onClick={()=> {toggleAssignPanel()}}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="miniModuleBody">
                    {displayTeamList()}
                    <div className={detectChanges() ? "updateLeadQuickAccess ns inactive" : "updateLeadQuickAccess ns"}
                        onClick={detectChanges() === props.assignedList ? null : ()=>{props.updateAssignList(assignedUsersIDs); toggleAssignPanel(); }}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignPanel;
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import Timelinetab from './TimelineTab.js';
import DocumentsTab from './DocumentsTab.js';
import MessageBoard from '../Components/MessageBoard/MessageBoard';
import LifeExpectancies from './LifeExpectancies.js';
import PremiumSchedule from './PremiumSchedule.js';

function CurrentActivity(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const bids = props?.session?.bids;
    const activityView = props?.activityView;
    const currentCase = props?.currentCase;
    const verifiedTabs = props?.verifiedTabs;
    const active = props?.active ?? true;
    const currentCaseData = session?.case?.data?.[props?.branch]?.[props?.stem]?.[currentCase?.index];

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const padded = ["timeline", "premiums", "les"]
    const tabView = (tab) => {
        return activityView?.data?.tab === tab && verifiedTabs?.includes(tab)
    }

    return (
        active ? (
            <div
                key={activityView?.data?.tab}
                // className={`currentActivity g f tC fC fR bR oA${
                className={`currentActivity g f tC fC bR dG oA${
                    padded?.includes(activityView?.data?.tab) ? " dP" : " fR"}${
                        !active ? " inactive" : ''} ${
                            activityView?.data?.tab
                            }${
                                props?.loadingHandler?.[activityView?.data?.tab] ? " loading" : ''}`}
            >
                {tabView("timeline") && (
                    <Timelinetab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        currentCase={props?.currentCase}
                        loadingStatus={props?.loadingHandler?.timeline}
                        activityView={activityView}
                        active={active}
                        instance={props?.instance}
                        timelineData={props?.timelineData}
                    />
                )}
                {tabView("documents") && (
                    <DocumentsTab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        shareLink={props?.shareLink ?? currentCaseData?.generatedShareLink}
                    />
                )}
                {tabView("les") && (
                    <LifeExpectancies
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        leData={currentCaseData?.lifeExpectancies}
                        loadingStatus={props?.loadingHandler?.les}
                    />
                )}
                {tabView("premiums") && (
                    <PremiumSchedule
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        premiumScheduleData={currentCaseData?.premiums}
                        loadingStatus={props?.loadingHandler?.premiums}
                        dropdowns={props?.dropdowns}
                        resetDropdowns={props?.resetDropdowns}
                    />
                )}
                {tabView("messages") && (
                    <div className="communicationWrapper g f pR">
                        <div className="messageInfoHeader g cC">
                            <div className="policyDetailsHolderTag">
                                <QuestionAnswerIcon />
                            </div>
                            <span>Messages</span>
                        </div>
                        <MessageBoard
                            relatedPolicyID={currentCase?.recordID}
                            sessionUser={props.sessionUser}
                            loadNotifications={props?.loadNotifications}
                            markSessionPolicyAsRead={props?.markSessionPolicyAsRead}
                            policyData={currentCase}
                            loadingStatus={loadingStatus}
                            pullType="shipping"
                            sessionVars={props?.sessionVars}
                            session={props?.session}
                            branch={props?.branch}
                            stem={props?.stem}
                        />
                    </div>
                )}
            </div> )
        :
            <div className="loading f cC g bR">
                {props?.requirementPrompt}
            </div>
    );
}

export default CurrentActivity;
import React, { useEffect, useRef, useState } from 'react';

import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import DownloadIcon from '@mui/icons-material/Download';

import Dropdown from '../../Dropdown.js';
import DownloadBtn from "../../DownloadBtn.js";
import StringInput from "../../StringInput.js";

function LineChart(props) {
  const session = props?.session;
  const overlayProps = props?.overlayProps;
  const canvasRef = useRef(null);
  const [hoveredPoint, setHoveredPoint] = useState(null); // To track the hovered data point

  const plotPointAttr = overlayProps?.plotPointAttr; // Attribute to plot on the Y-axis
  const xAxisLabel = overlayProps?.xAxisLabel; // X-axis label
  const yAxisLabel = overlayProps?.yAxisLabel; // Y-axis label
  const reportName = overlayProps?.reportName; // Report title

  const [selectedReport, setSelectedReport] = useState({
    graphData : overlayProps?.reportList?.[overlayProps?.selectedReportKey]?.[overlayProps?.dataPointer] ?? [],
    selectedReportKey : overlayProps?.selectedReportKey ?? 0,
    reportName : overlayProps?.reportName ?? "Report",
    cycles : overlayProps?.cycles,
  });

  const updateSelectedReport = (keys, values) => {
    // Convert non-array inputs to arrays for consistency
    const keysArray = Array.isArray(keys) ? keys : [keys];
    const valuesArray = Array.isArray(values) ? values : [values];
  
    setSelectedReport(prev => {
      // Build the new state object by updating the keys with corresponding values
      const updatedState = keysArray.reduce((acc, key, index) => {
        acc[key] = valuesArray[index];
        return acc;
      }, {});
  
      return {
        ...prev,
        ...updatedState,
      };
    });
  };

  const [dropdowns, setDropdowns] = useState({
    reports : {
      showMenu : false,
      onClick: (value, data) => {
        const newGraphData = data?.path?.split('.').reduce((acc, key) => acc?.[key], overlayProps?.reportList);
        updateSelectedReport(["graphData", "selectedReportKey", "reportName"], [newGraphData, data?.reportKey, data?.reportName]);
      },
      value : `${overlayProps?.reportNamePrompt}`
    },
    cycles : {
      showMenu : false,
      onClick: (value, data) => {
        console.log(data);
        updateSelectedReport("cycles", data?.cycles);
      }
    },
    download : {
      showMenu : false,
      onClick: (value, data) => {
        console.log(data);
        updateSelectedReport("download", data?.cycles);
      }
    }
  });

  const updateDropdown = (key, value) => {
    setDropdowns((prev) => ({
      ...prev,
      [key]: { ...prev[key], showMenu: value }
    }));
  };

  const resetDropdowns = () => {
    setDropdowns((prev) => {
      const updated = Object.keys(prev).reduce((acc, key) => {
        acc[key] = { ...prev[key], showMenu: false };
        return acc;
      }, {});

      return updated;
    });
  };

  // const downloadGraph = () => {
  //   const canvas = canvasRef.current;
  //   const dataUrl = canvas.toDataURL("image/png"); // Convert canvas to image
  //   const link = document.createElement('a'); // Create a temporary link element
  //   link.href = dataUrl;
  //   link.download = `${reportName}_Illustration.png`; // Filename for the downloaded image
  //   link.click(); // Programmatically trigger the download
  // };

  useEffect(() => {
    const illustrationData = selectedReport?.graphData?.slice(0, selectedReport?.cycles);
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
  
    const width = canvas.width;
    const height = canvas.height;
    const labelPadding = 10;
    const gridLineWidth = 1;
    const borderRadius = 5; // Radius for rounded corners
        
    if (!illustrationData || !plotPointAttr) return;
  
    // Calculate min and max values for scaling (ensure Y-axis starts at 0 and add buffer to maxValue)
    const maxValue = Math.max(1, ...illustrationData.map(item => item?.[plotPointAttr])) * 1.1;
    const minValue = 0; // Ensure Y-axis always starts at 0

    // Determine the largest Y-axis label value to calculate dynamic left padding
    const largestLabel = session?.env?.functions?.convertIntToCurrency(maxValue.toFixed(2));

    // Function to calculate text width for dynamic padding
    const calculateTextWidth = (ctx, text, font) => {
      ctx.font = font;
      return ctx.measureText(text).width;
    };

    // Calculate the left padding dynamically based on the largest Y-axis label
    const paddingLeft = calculateTextWidth(ctx, largestLabel, "12px system-ui") + 40;
    const paddingBottom = 40; // Keep the padding for the X-axis
    const paddingRight = 40;  // Minimize right padding
    const paddingTop = 20;    // Minimize top padding
  
    const scaleX = (index) => paddingLeft + (index / (illustrationData.length - 1)) * (width - paddingLeft - paddingRight);
    const scaleY = (plotPoint) => height - paddingBottom - ((plotPoint - minValue) / (maxValue - minValue)) * (height - paddingBottom - paddingTop);
  
    // Clear the canvas before drawing
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#FFFFFF'; // Set the background color to white
    ctx.fillRect(0, 0, width, height); // Fill the entire canvas with white
    // Clip to rounded rectangle area
    ctx.save(); // Save the current context state
    ctx.beginPath();
    ctx.moveTo(paddingLeft + borderRadius, paddingTop);
    ctx.lineTo(width - paddingRight - borderRadius, paddingTop); // Top line
    ctx.quadraticCurveTo(width - paddingRight, paddingTop, width - paddingRight, paddingTop + borderRadius); // Top-right corner
    ctx.lineTo(width - paddingRight, height - paddingBottom - borderRadius); // Right line
    ctx.quadraticCurveTo(width - paddingRight, height - paddingBottom, width - paddingRight - borderRadius, height - paddingBottom); // Bottom-right corner
    ctx.lineTo(paddingLeft + borderRadius, height - paddingBottom); // Bottom line
    ctx.quadraticCurveTo(paddingLeft, height - paddingBottom, paddingLeft, height - paddingBottom - borderRadius); // Bottom-left corner
    ctx.lineTo(paddingLeft, paddingTop + borderRadius); // Left line
    ctx.quadraticCurveTo(paddingLeft, paddingTop, paddingLeft + borderRadius, paddingTop); // Top-left corner
    ctx.clip(); // Clip everything inside this rounded rectangle
  
    // Draw the light gray grid inside the clipped area
    ctx.strokeStyle = "#EFEFEF"; // Light gray for grid lines
    ctx.lineWidth = gridLineWidth;
  
    // Horizontal grid lines
    const numberOfGridLinesY = 5;
    for (let i = 0; i <= numberOfGridLinesY; i++) {
      const value = minValue + ((maxValue - minValue) / numberOfGridLinesY) * i;
      const y = scaleY(value);
      ctx.beginPath();
      ctx.moveTo(paddingLeft, y);
      ctx.lineTo(width - paddingRight, y);
      ctx.stroke();
    }
  
    // Draw the X and Y axis (inside the clipped region)
    ctx.beginPath();
    ctx.moveTo(paddingLeft, height - paddingBottom);
    ctx.lineTo(width - paddingRight, height - paddingBottom); // X axis
    ctx.moveTo(paddingLeft, height - paddingBottom);
    ctx.lineTo(paddingLeft, paddingTop); // Y axis
    ctx.strokeStyle = "#E2E2E2"; // Axis color
    ctx.lineWidth = 2; // Axis thickness
    ctx.stroke();
  
    // Restore context state after clipping (ensuring labels are drawn outside the clipping region)
    ctx.restore();
  
    // Draw Y-axis labels (e.g., deathBenefit or premium)
    ctx.fillStyle = "#000"; // Label color
    ctx.font = "12px system-ui"; // Font for labels
    ctx.textAlign = "right"; // Align text to the right for Y-axis
    for (let i = 0; i <= numberOfGridLinesY; i++) {
      const value = minValue + ((maxValue - minValue) / numberOfGridLinesY) * i;
      const y = scaleY(value);
      ctx.fillText(`${session?.env?.functions?.convertIntToCurrency(value.toFixed(2))}`, paddingLeft - labelPadding * 2, y + 3); // Adjusted paddingLeft
    }
  
    // Define the maximum number of labels you want to display
    const maxXLabels = 24;
    const step = Math.ceil(illustrationData.length / maxXLabels); // Calculate how often to display labels

    // Draw X-axis labels (Dates)
    ctx.textAlign = "center";
    ctx.font = "10px system-ui";
    illustrationData.forEach((item, index) => {
      if (index % step === 0) { // Only display every nth label based on 'step'
        const x = scaleX(index);
        const date = new Date(item?.[xAxisLabel]);
        const isNewYear = date.getMonth() === 0; // Detect new year

        ctx.save();
        ctx.translate(x, height - paddingBottom + labelPadding * 2);
        ctx.rotate(-Math.PI / 6); // Rotate labels to -30 degrees

        if (isNewYear) {
          ctx.font = "bold 10px system-ui";
        }
        ctx.fillText(session?.env?.functions?.reformatDate(item?.[xAxisLabel]), 0, 0);
        ctx.restore();
      }
    });
  
    // Draw X-axis label (at bottom center)
    // ctx.font = "14px system-ui";
    // ctx.fillText(xAxisLabel, width / 2, height - labelPadding);
  
    // Draw Y-axis label (rotated for left side)
    ctx.save();
    // ctx.translate(paddingLeft / 4, height / 2);
    // ctx.rotate(-Math.PI / 2);
    // ctx.fillText(yAxisLabel, 0, 0);
    // ctx.restore();
  
    // Plot the data points and connect them with lines (inside clipped region)
    ctx.beginPath();
    illustrationData.forEach((item, index) => {
      const x = scaleX(index);
      const y = scaleY(item?.[plotPointAttr] || 0); // Ensure that zero values are used
      if (index === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    });
    ctx.strokeStyle = "#00aaff"; // Line color
    ctx.lineWidth = 2; // Line width
    ctx.stroke();
  
    // Draw circles only if cycles <= 48
    if (selectedReport?.cycles <= 48) {
      illustrationData.forEach((item, index) => {
        const x = scaleX(index);
        const y = scaleY(item?.[plotPointAttr]);
        ctx.beginPath();
        ctx.arc(x, y, 3, 0, 2 * Math.PI); // Draw circle for each data point
        ctx.fillStyle = "#00aaff"; // Color of the circles
        ctx.fill();
      });
    }
  
    // Draw rounded border around the chart (after clipping)
    ctx.beginPath();
    ctx.moveTo(paddingLeft + borderRadius, paddingTop);
    ctx.lineTo(width - paddingRight - borderRadius, paddingTop); // Top line
    ctx.quadraticCurveTo(width - paddingRight, paddingTop, width - paddingRight, paddingTop + borderRadius); // Top-right corner
    ctx.lineTo(width - paddingRight, height - paddingBottom - borderRadius); // Right line
    ctx.quadraticCurveTo(width - paddingRight, height - paddingBottom, width - paddingRight - borderRadius, height - paddingBottom); // Bottom-right corner
    ctx.lineTo(paddingLeft + borderRadius, height - paddingBottom); // Bottom line
    ctx.quadraticCurveTo(paddingLeft, height - paddingBottom, paddingLeft, height - paddingBottom - borderRadius); // Bottom-left corner
    ctx.lineTo(paddingLeft, paddingTop + borderRadius); // Left line
    ctx.quadraticCurveTo(paddingLeft, paddingTop, paddingLeft + borderRadius, paddingTop); // Top-left corner
    ctx.strokeStyle = "#E2E2E2"; // Border color
    ctx.lineWidth = 1; // Border thickness
    ctx.stroke();
  
    // Mouse hover detection
    canvas.addEventListener('mousemove', function (e) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
  
      let foundPoint = null;
      illustrationData.forEach((item, index) => {
        const x = scaleX(index);
        const y = scaleY(item?.[plotPointAttr]);
        const radius = 5;
        if (Math.abs(mouseX - x) < radius && Math.abs(mouseY - y) < radius) {
          foundPoint = { x, y, data: item };
        }
      });
      setHoveredPoint(foundPoint);
    });
  }, [
    selectedReport?.graphData,
    plotPointAttr,
    xAxisLabel,
    yAxisLabel,
    selectedReport?.reportName,
    selectedReport?.cycles
  ]);

  useEffect(() => {
    console.log(999, props);
  }, [
    props
  ]);
  
  return (
    <div className="lineGraph g" onClick={()=>{resetDropdowns()}}>
      <div className="actionBar g s e dG cC">
        <Dropdown
          itemNamePointer="reportName"
          setShowMenu={(input) => {
            updateDropdown("reports", input ?? !dropdowns?.reports?.showMenu);
          }}
          showMenu={dropdowns?.reports?.showMenu}
          default={selectedReport?.reportName}
          generatedList={overlayProps?.reportList}
          onClick={(value, data) => dropdowns?.reports?.onClick(value, data)}
          reset={resetDropdowns}
        />
        <StringInput
          placeholder={`Number of ${overlayProps?.cyclesFriendlyTerm ?? "Cycles"}`}
          value={selectedReport?.cycles || ''}
          // onClick={() => console.log(attributeData?.[key], key, sectionIndex, groupIndex)}
          onChange={(e) => updateSelectedReport("cycles", e?.target?.value)} // Update state with new value
          textData={"int"}
          readOnly={false}
        />
        <DownloadBtn
          session={session}
          setShowMenu={(input) => {
            updateDropdown("download", input ?? !dropdowns?.cycles?.showMenu);
          }}
          showMenu={dropdowns?.download?.showMenu}
          inactive={!selectedReport?.graphData?.length}
          onClickDownload={() => 
            selectedReport?.graphData?.length && (
              overlayProps?.formatList?.[session?.[overlayProps?.branch]?.data?.[overlayProps?.stem]?.preferredFormat]?.onClick?.() ||
              session?.env?.functions?.downloadLineGraph(session, canvasRef, selectedReport?.reportName)
            )
          }
          preferredFormat={session?.[overlayProps?.branch]?.data?.[overlayProps?.stem]?.preferredFormat}
          formatList={Object?.values(overlayProps?.formatList || {})}
      />
        {/* <div className={`btnWIcon bold bR f cC ns p`} onClick={()=>{session?.env?.functions?.downloadLineGraph(session, canvasRef, reportName)}}>
          <div className="gCW f">
              Download
          </div>
          <DownloadIcon />
        </div> */}
      </div>
      <canvas ref={canvasRef} width={800} height={500}></canvas>
      {hoveredPoint && (
        <div
          className="chartBubble dP g bR dG"
          style={{
            top: hoveredPoint.y - 20,
            left: hoveredPoint.x + (hoveredPoint.x > 500 ? -10 : 10),
            transform: hoveredPoint.x > 500 ? 'translateX(-100%)' : 'none' // Invert the tooltip if it is too far right
          }}
        >
          <div className="f cL lH">
            <div className="point cL g dG currency" title={`Amount: ${session?.env?.functions?.convertIntToCurrency(hoveredPoint?.data?.[plotPointAttr])}`}>
              <PaidTwoToneIcon/>
              <span className="f gCW">
                Amount: <span className="bold">{session?.env?.functions?.convertIntToCurrency(hoveredPoint?.data?.[plotPointAttr])}</span>
              </span>
            </div>
          </div>
          <div className="f cL lH">
            <div className="point cL g dG date" title={`Date: ${session?.env?.functions?.reformatDate(hoveredPoint?.data?.[xAxisLabel])}`}>
              <CalendarMonthTwoToneIcon/>
              <span className="f gCW">
                Date: <span className="bold">{session?.env?.functions?.reformatDate(hoveredPoint?.data?.[xAxisLabel])}</span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LineChart;
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import PolicyRows from './PolicyRows';
import PoliciesSearchBar from './PoliciesSearchBar';
import PoliciesFilters from './PoliciesFilters';

import FilterListIcon from '@mui/icons-material/FilterList';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function PoliciesOverview(props){
    const policiesBoard = useRef();
    function handleScroll(event) {
        const element = event.target;
        // if(props.maxVisiblePolicies > Object.keys(props.policies).length || element.scrollTop === 0){
        if(props.maxVisiblePolicies > props?.session?.case?.data?.allCases || element.scrollTop === 0){
            return;
        }

        const isScrollHeightDouble = element.scrollHeight >= 2 * element.offsetHeight;

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100 && isScrollHeightDouble) {
            props.setMaxVisiblePolicies?.(props.maxVisiblePolicies + 48);
        }
    }


    function policyResults(){
        if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type && props?.policies){
            let returningPolicyResults = [
                <div key={0} className="policyResultsTotals">
                    {"Showing " + (props.visiblePolicies) + " out of " + (Object.keys(props.policies)?.length) + " existing policies."}
                </div>
            ];
            if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type !== "Fund"){
                returningPolicyResults.push(
                    <div key={1} className="policyActive">
                        <span>
                            {"Active: " + props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.activeCaseCount}
                        </span>
                        <span>
                            {"Closed: " + props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.closedCaseCount}
                        </span>
                        <span>
                            {"Inactive: " + props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.inactiveCaseCount}
                        </span>
                    </div>
                );
            }
            return ( returningPolicyResults )
        }

        return null;
    }

    function toggleFilterStatus(){
        props.filterStatus ? props.setFilterStatus(false) : props.setFilterStatus(true);
    }

    function updateEmptyDeathBenefitStatus(){
        let newStatus = true;
        if(props.emptyDeathBenefit=== true){
            newStatus = false;
        }

        props.setEmptyDeathBenefit(newStatus);
    }

    function resetDeathBenefitsRange(){
        var fixedMinVal = props.deathBenefitsRange.fixedMinVal;
        var fixedMaxVal = props.deathBenefitsRange.fixedMaxVal;
        props.setDeathBenefitsRange( prevState => ({ ...prevState, minVal : fixedMinVal, maxVal : fixedMaxVal }));
        props.setDeathBenefitsRangeReset(true);
    }

    function updateFilters(toggleData, filterGroup, key, nested) {
        let activeStatus = !toggleData.active;

        if(nested){
            const updatedFunds = { ...props.filterHandler[filterGroup][key].funds };
            
            let activeCounter = 0;
            let accountActiveStatus = false;

            Object.keys(updatedFunds).forEach((key) => {
                if(updatedFunds[key].fundID === nested){
                    updatedFunds[key].active = activeStatus;
                }

                if(updatedFunds[key].active === true){
                    activeCounter++;
                };
            });

            if(activeCounter > 0){
                accountActiveStatus = true;
            }

            props.setFilterHandler((prevFilterHandler) => {
                const updatedFilterHandler = {
                  ...prevFilterHandler,
                  [filterGroup]: {
                    ...prevFilterHandler[filterGroup],
                    [key]: {
                      ...prevFilterHandler[filterGroup][key],
                      active: accountActiveStatus,
                      funds: updatedFunds
                    }
                  }
                };
                return updatedFilterHandler;
            });

            props?.policiesProps?.sessionVars?.envVars?.setAdminHandler(prevState => ({
                ...prevState,
                [key]: {
                  ...prevState[key],
                  activeHandler: {
                    ...prevState[key]?.activeHandler,
                    policies: activeStatus
                  }
                }
            }));

        }else{
            if (props.filterHandler[filterGroup][key]?.funds) {             
                const updatedFunds = { ...props.filterHandler[filterGroup][key].funds };
              
                Object.keys(updatedFunds).forEach((key) => {
                  updatedFunds[key].active = activeStatus;
                });
              
                props.setFilterHandler((prevFilterHandler) => {
                  const updatedFilterHandler = {
                    ...prevFilterHandler,
                    [filterGroup]: {
                      ...prevFilterHandler[filterGroup],
                      [key]: {
                        ...prevFilterHandler[filterGroup][key],
                        active: activeStatus,
                        funds: updatedFunds
                      }
                    }
                  };
                  return updatedFilterHandler;
                });
            }else{
                props.setFilterHandler({
                    ...props.filterHandler,
                    [filterGroup]: {
                        ...props.filterHandler[filterGroup],
                        [key]: {
                        ...props.filterHandler[filterGroup][key],
                            active: activeStatus
                        }
                    }
                });
            }
            
            props?.policiesProps?.sessionVars?.envVars?.setAdminHandler(prevState => ({
                ...prevState,
                [key]: {
                  ...prevState[key],
                  activeHandler: {
                    ...prevState[key]?.activeHandler,
                    policies: activeStatus
                  }
                }
            }));
        }
    }

    function toggleFiltersGroup(type){
        const newStatusGroup = { ...props.filterHandler[type] };
        let shouldToggle = true;

        Object.keys(newStatusGroup).forEach((key) => {
            if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund"){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object" && newStatusGroup[key].funds[fundKey].active) {
                        shouldToggle = false;
                    }
                });
            }

            if (typeof newStatusGroup[key] === "object" && newStatusGroup[key].active) {
              shouldToggle = false;
            }
        });

        Object.keys(newStatusGroup).forEach((key) => {
            if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund"){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object") {
                        newStatusGroup[key].funds[fundKey].active = shouldToggle;
                    }
                });
            }

            if(typeof newStatusGroup[key] === "object"){
                newStatusGroup[key].active = shouldToggle;
            }
        });

        props.setFilterHandler({
          ...props.filterHandler,
          [type]: newStatusGroup
        });

        if(type === "affiliates"){
            const adminHandler = { ...props?.policiesProps?.sessionVars?.envVars?.adminHandler };
            for (let key in adminHandler) {
                if (isNaN(key)) continue;
                adminHandler[key].activeHandler.policies = shouldToggle;
            }
            
            props.policiesProps.sessionVars.envVars?.setAdminHandler(adminHandler);
        }
    }

    function toggleFiltersSubGroup(type, accountType, focus, nested) {
        const newStatusGroup = { ...props.filterHandler[type] };
        let shouldToggle = true;

        Object.keys(newStatusGroup).forEach((key) => {
            if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds"){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object" && newStatusGroup[key].funds[fundKey].active) {
                        shouldToggle = false;
                      }
                });
            }

            if (newStatusGroup[key].active && newStatusGroup[key].accountType === accountType) {
              shouldToggle = false;
            }
        });

        Object.keys(newStatusGroup).forEach((key) => {
            if (JSON.stringify(key) === JSON.stringify(focus)) {
                newStatusGroup[key].active = !shouldToggle;
            }else if(type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds" && focus){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object") {
                        if(parseInt(fundKey) === parseInt(focus)){
                            newStatusGroup[key].funds[fundKey].active = !shouldToggle;
                        }else{
                            newStatusGroup[key].funds[fundKey].active = shouldToggle;
                        }
                    }
                });
            }else{
                if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds"){
                    Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                        if (typeof newStatusGroup[key].funds[fundKey] === "object") {
                            newStatusGroup[key].funds[fundKey].active = shouldToggle;
                        }
                    });
                }

                if (newStatusGroup[key].accountType === accountType) {
                    newStatusGroup[key].active = shouldToggle;
                };
            }
        });

        props.setFilterHandler({
          ...props.filterHandler,
          [type]: newStatusGroup,
        });

        
        if(type === "affiliates"){
            const adminHandler = { ...props?.policiesProps?.sessionVars?.envVars?.adminHandler };
            for (let key in adminHandler) {
                if (isNaN(key)) continue;
                let accountTypes = [];
                if(accountType === "origination"){
                    accountTypes.push("Agent","Broker","Origination", "Marketing");
                }
                if(accountType === "fund"){
                    accountTypes.push("Fund");
                }

                if(accountTypes.includes(adminHandler[key].type)){
                    adminHandler[key].activeHandler.policies = shouldToggle;
                }
            }
            
            props.policiesProps.sessionVars.envVars?.setAdminHandler(adminHandler);
        }
    }

    function printFilterBars(currentColumnID, currentColumnString, start){
        function resetScrollTop() {
            if (policiesBoard.current) {
                policiesBoard.current.scrollTop = 0;
            }
        }

        let arrowDisplay = [];
        let position = " cC";

        if(start === true){
            position = " cL";
        }
        if(start === "last"){
            position = " cR";
        }

        if(props.policyOrderDirection[currentColumnID] === ''){
            arrowDisplay.push(
                <ArrowDropUpIcon key={currentColumnID + "Up"} style={{color: "#E2E2E2"}}/>,
                <ArrowDropDownIcon  key={currentColumnID + "Down"} style={{color: "#E2E2E2"}}/>
            );
        }else{
            if(props.policyOrderDirection[currentColumnID] === "desc"){
                arrowDisplay.push(
                    <ArrowDropUpIcon key={currentColumnID + "Up"} style={{color: "#00BFFF"}}/>,
                    <ArrowDropDownIcon  key={currentColumnID + "Down"} style={{color: "#E2E2E2"}}/>
                );
            }else{
                arrowDisplay.push(
                    <ArrowDropUpIcon  key={currentColumnID + "Up"} style={{color: "#E2E2E2"}}/>,
                    <ArrowDropDownIcon  key={currentColumnID + "Down"} style={{color: "#00BFFF"}}/>
                );
            }
        }

        return (
            <div
                className={ start === true ? "option g f cC gC2 R" : "option g f cC fR" }
                onClick={()=>{resetScrollTop(); props.orderPolicies(currentColumnID)}}
            >
                <div className="arrows f g cL">
                    {arrowDisplay}
                </div>
                <div className={"f s" + position}>
                    {currentColumnString}
                </div>
            </div>
        )
    }

    useEffect(() => {
        props.setMaxVisiblePolicies(32);
    }, [props.filterHandler]);

    
    useEffect(() => {
        props.setSearchValHandler(maintainWhiteSpaceMax(props.searchVal.split(" ")));
    }, [props.searchVal]);

    return (
        <div className="policiesOverview moduleBlock">
            <div className="policiesDetails">
                { props.filterStatus &&
                    <PoliciesFilters
                        toggleFilterStatus={toggleFilterStatus}
                        policies={props.policies}
                        updateFilters={updateFilters}
                        toggleFiltersGroup={toggleFiltersGroup}
                        toggleFiltersSubGroup={toggleFiltersSubGroup}
                        filterHandler={props.filterHandler}
                        emptyDeathBenefit={props.emptyDeathBenefit}
                        updateEmptyDeathBenefitStatus={updateEmptyDeathBenefitStatus}
                        updateDeathBenefitsRange={props.updateDeathBenefitsRange}
                        deathBenefitsRange={props.deathBenefitsRange}
                        deathBenefitsRangeReset={props.deathBenefitsRangeReset}
                        updateDeathBenefitsRangeReset={props.updateDeathBenefitsRangeReset}
                        resetDeathBenefitsRange={resetDeathBenefitsRange}
                        sessionUser={props.sessionUser}
                        policiesProps={props?.policiesProps}
                        session={props?.session}
                    />
                }
                <div className="policiesOverview g cC fC" onClick={()=>{console.log(456, props?.session?.case?.data?.policies)}}>
                    <div className="policiesDetailsBlockTitle g ns f cC fC">
                        <div className="header f g cC fR bold">
                            <div className="gC2" onClick={()=>{console.log(props?.session)}}>
                                All Policies
                            </div>
                            <div className="listAltercationOption cC" onClick={toggleFilterStatus}>
                                <div className="listAltercationOptionString">Filter</div>
                                <FilterListIcon className="filterBtn" />
                            </div>
                        </div>
                        <div className="f cC">
                            <PoliciesSearchBar
                                searchVal={props.searchVal}
                                updateSearchVal={props.updateSearchVal}
                                deathBenefitsRange={props.deathBenefitsRange} 
                                resetDeathBenefitsRange={resetDeathBenefitsRange}
                                filterHandler={props.filterHandler}
                                setFilterHandler={props.setFilterHandler}
                                policiesProps={props.policiesProps}
                                session={props?.session}
                            />
                        </div>
                        <div className="policiesColumnHeader bold g cC f fR">
                            {!props.mobileDevice &&
                                printFilterBars("policyStatus","Status", true)
                            }
                            {!props.mobileDevice &&
                                <div className="vDivider">
                                </div>
                            }
                            {!props.mobileDevice && printFilterBars("policyNumber","Policy Number")}
                            {!props.mobileDevice &&
                                <div className="vDivider">
                                </div>
                            }
                            {props.mobileDevice ? printFilterBars("primaryLastName","Name", props.mobileDevice) :  printFilterBars("primaryLastName","Insured Name", props.mobileDevice)}
                            <div className="vDivider">
                            </div>
                            {props.mobileDevice ? printFilterBars("deathBenefit","Face") : printFilterBars("deathBenefit","Death Benefit")}
                            <div className="vDivider">
                            </div>
                            {props.mobileDevice ? printFilterBars("insuranceCompany","Carrier", "last") : printFilterBars("insuranceCompany","Insurance Co.", "last")}
                        </div>
                    </div>
                    <div className="policiesDetailsBoardWrap fMP tO" ref={policiesBoard} onScroll={(evt)=>{handleScroll(evt)}}>
                        <div className="singleTimeline g b bR t b fC cC">
                            {(!props?.session?.case?.data?.policies?.origination && !props?.session?.case?.data?.policies?.servicing)
                                || props?.visiblePolicies === 0 ?
                                <div className="noPolicyResultsMessage">
                                    {!props?.policies ? 
                                        <CircularProgress color="inherit"/>
                                        :
                                        "No policies meet the current specified filters"
                                    }
                                </div>
                            :
                                props.policyList
                            }
                        </div>
                    </div>
                    <div className="policyResultsData">
                        <div className="policiesAnalytics">
                            {policyResults()}
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default PoliciesOverview;

export function keyMapSearchVal(searchValHandler){
    let returningKeyMap = [];
    let i = 0;
    while( i < searchValHandler.length){
        returningKeyMap.push(i);
        i++;
    }

    return returningKeyMap;
}

export function comparisonStatus(haystack, needle){
    if(haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0){
        return true;
    }

    return false;
}
export function containsNoWhiteSpace(str){
    if (str === ''){
        return false;
    }
    if(str.trim() === ''){
        return false;
    }
    
    return true;
}

export function maintainWhiteSpaceMax(splitStringArray){
    var whiteSpaceCount = 0;
    var indexTracker = [];
    var shiftingZeroPosition = 0;

    for (var i = 0; i < splitStringArray.length; i++){
        if(!containsNoWhiteSpace(splitStringArray[i])){
            whiteSpaceCount++;
            if(whiteSpaceCount > 1 || shiftingZeroPosition === 0 || indexTracker.includes(i - 1)){
                indexTracker.push(i);
                if(i === 0){
                    whiteSpaceCount = 0;
                    shiftingZeroPosition = 0;
                }
            }
        }else{
            whiteSpaceCount = 0;
            shiftingZeroPosition = 0;
        }
    }

    for (var i = indexTracker.length -1; i >= 0; i--){
        splitStringArray.splice(indexTracker[i],1);
    }

    return splitStringArray;
}

/*<div className="listAltercationOption">
                                <div className="listAltercationOptionString">Sort by</div>
                                <SortIcon className="filterBtn" />
                            </div> */
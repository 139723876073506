import React, { useEffect, useState } from 'react';

function SessionUser(props){
    const [data, setData] = useState();

    const updateUserData = (propertyPaths, values) => {
        const paths = Array.isArray(propertyPaths) ? propertyPaths : [propertyPaths];
        const vals = Array.isArray(values) ? values : [values];   

        setData((prev) => {
            const newData = { ...prev };
            for (let i = 0; i < paths.length; i++) {
                const propertyPathParts = paths[i].split('.');
                let currentNested = newData;

                for (let j = 0; j < propertyPathParts.length - 1; j++) {
                    const propertyPart = propertyPathParts[j];
                    currentNested[propertyPart] = currentNested[propertyPart] || {};
                    currentNested = currentNested[propertyPart];
                }

                currentNested[propertyPathParts[propertyPathParts.length - 1]] = vals[i];
            }

            window.localStorage.removeItem("sessionUser");
            window.localStorage.setItem('sessionUser', JSON.stringify(newData));

            return newData;
        });

        window.scrollTo(0, 0);
    };

    const logout = () => {
        window.localStorage.removeItem("sessionUser");
        window.sessionStorage.setItem('userLoggedOut', 'true');

        setData();
    };

    function loadUser(sessionUser){
        window.localStorage.removeItem("sessionUser");
        window.localStorage.setItem('sessionUser', JSON.stringify(sessionUser));
        setData(sessionUser);
    }

    // function verifyAccess(branch, stem) {
    //     if(branch === "home") console.log(321, branch);

    //     const accountData = data?.accountData;
    //     const instances = {
    //         "home": ["home", "Home"],
    //         "policies": ["origination-all", "origination-single", "servicing-all", "servicing-single"],
    //         "upload": ["upload", "Upload"],
    //         "leads": ["leads-all", "leads-single", "Leads"],
    //         "bids": ["bids-all", "bids-single", "tertiary-all", "tertiary-single"],
    //         "profile": ["profile"],
    //         "reporting": ["reporting", "reporting-all"],
    //         "dashboard": ["dashboard", "dashboard-all"],
    //         "tertiary": ["tertiary-all", "tertiary-single"]
    //     };

    //     const pagesArray = instances[branch] || [];

    //     if(branch === "home") console.log(321, pagesArray);

    //     // Check if the user has access to any of the instances
    //     const userHasAccess = pagesArray.some(instance => data?.views?.includes(instance));
    //     if(branch === "home") console.log(321, userHasAccess);

    //     if (!userHasAccess) {
    //         return false;
    //     }

    //     // Check if any account views match the instances
    //     const accountHasAccess = accountID => {
    //         const accountViews = accountData[accountID]?.pages?.[branch]?.views || [];
    //         if(branch === "home") console.log(321,  accountData[accountID], accountID, branch, accountViews);
    //         return pagesArray.some(instance => accountViews.includes(instance));
    //     };

    //     if(branch === "home") console.log(321, accountHasAccess);

    //     const allAccountIDs = (data?.managedAccounts?.split(';') || []).concat(data?.accountID?.toString());
    //     if(branch === "home") console.log(321, allAccountIDs, data?.managedAccounts);
    //     const hasAccess = allAccountIDs.some(accountID => accountHasAccess(accountID));

    //     if(branch === "home") console.log(321, hasAccess);

    //     return hasAccess;
    // }

    const functions = {
        loadUser,
        updateUserData,
        logout,
        // verifyAccess,
    }

    const sessionUser = {
        data,
        functions
    }

    return sessionUser;
};

export default SessionUser;
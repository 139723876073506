import React from 'react';
// import { Link } from 'react-router-dom';

import StatusIcon from '../StatusIcons';
import LowPriorityIcon from '@mui/icons-material/LowPriority';

// import LeadsIcon from './LeadsIcons';

// import CircleIcon from '@mui/icons-material/Circle';
// import HtmlReactParser from 'html-react-parser';
// import xss from 'xss';

import { timeStamp, convertIntToCurrency, dateFormat } from '../../envFunctions.js';
// import { dateFormat } from '../envFunctions.js';

import './TimelinePost.css';

import { useState, useEffect } from 'react';

function DefaultTimelinePost(props){
    let currentStatus = props.status;
    const closingStatements = [
        "Lapsed",
        "Matured",
        "Sold"
    ];

    if(currentStatus === "Application Sent" && props.timelineType === "lead"){
        currentStatus = "Application Sent - Lead";
    }

    useEffect(() => {
        // console.log(props);
    }, []);

    function currentTitle(){
        if(props.type === "Note"){
            if(props.data?.originator === "Customer" && props?.timelineType !== "lead"){
                return "Transferred Note";
            }
            return "Note"
        }

        if(props.type === "Status"){
            if(props.objectType === "servicing"){
                return "Servicing ( Beta )"
            }
            return props.type + " Update";
        }
    }

    if(props.data?.objectType === "servicing" && closingStatements.includes(props.data?.status)){
        return (
            <div className={"closed timelinePostBlock g bR p ns " + props.status} onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
                <div className="header g">
                        <div className="cC">
                            {props.data?.readStatus === false && props.data?.originator === "Internal" &&
                                <div className="cC">
                                    <div className="notificationAlert">
                                    </div>
                                </div>
                            }
                        </div>
                    <div className="cL f bold gC2">
                        Closing Statement
                    </div>
                </div>
                <div className="divider">
                </div>
                <div className="content g">
                    <div className="cC">
                        <StatusIcon currentStatus={props.status} />
                    </div>
                    <div className="cL s">
                        {props.status}
                    </div>
                    <div className="cR f e">
                        <div className="cR">
                            Total Premiums Paid:
                        </div>
                        <div className="s bold">
                            {convertIntToCurrency(props.amountPaid)}
                        </div>
                    </div>
                </div>
                <div className="details g b">
                    <div className="cR e gC2">
                        { props.status === "Lapsed" &&
                            "Surrender Date:"
                        }
                        { props.status === "Matured" &&
                            "Payout Date:"
                        }
                        { props.status === "Sold" &&
                            "Sold Date:"
                        }
                        <div className="s bold">
                            {dateFormat(props.soldDate)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        props?.type !== "Bid" &&
        <div className="default timelinePostBlock g bR p ns" onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
            <div className="header g">
               <div className="cC">
                    {props.data?.readStatus === false && props.data?.originator === "Internal" &&
                        <div className="cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    }
                </div>
                <div className="cL f bold gC2">
                    {currentTitle()} 
                </div>
                <div className="cR f e">
                    {timeStamp(props.timeStamp)}
                </div>
            </div>
            <div className="divider"></div>
            <div className="content g">
                <div className="cC">
                    <StatusIcon
                        currentStatus={currentStatus}
                        type={props.type}
                        originator={props.data?.originator}
                        timelineType={props?.timelineType}
                    />
                </div>
                <div className={props.type === "Note" ? "cL dP e" : "cL s e"}>
                    {props.status === "Lead" && props.timelineType === "origination" ? "Sent to Marketplace" : props.status}
                </div>
            </div>
        </div>
    )
}

export default DefaultTimelinePost;

import React, { useEffect, useState, useRef, useCallback } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import PanToolIcon from '@mui/icons-material/PanTool';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Dropdown from '../Dropdown.js';
import InteractiveBar from '../Reporting/ReportBuilder/InteractiveBar.js';
import PropertiesView from '../Reporting/ReportBuilder/Views/PropertiesView.js';
import DragHandler from '../Reporting/ReportBuilder/Dragging/DragHandler.js';

function CriteriaBoard(props){
    const requirementsBlock = useRef(null);
    const operatorsMenu = useRef(null);
    const listMenu = useRef(null);
    const attrList = useRef(null);

    const [reportingView, setReportingView] = useState(undefined);
    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;

    const reporting = session?.reporting;
    const allReports = reporting?.data?.allReports;
    const selectedReport = reporting?.data?.selectedReport;
    const newReportTemplate = reporting?.data?.reset;
    const isSystemReport = selectedReport?.groupID?.value < 0;

    const existingCriteriaData = selectedReport?.criteria?.existing;
    const groupByCriteriaData = selectedReport?.criteria?.groupBy;
    const existingColumnData = selectedReport?.columns;
    const existingReportType = selectedReport?.stem;
    const attributeData = props?.session?.[reporting?.data?.dataPointer]?.data?.attributeData;
    const [attributeForm, setAttributeForm] = useState(existingCriteriaData?.length > 0 ? existingCriteriaData : []);
    const [groupColumnsForm, setGroupColumnsForm] = useState(groupByCriteriaData?.length > 0 ? groupByCriteriaData : []);
    const [columnForm, setColumnForm] = useState(existingColumnData?.length > 0 ? existingColumnData : []);
    const [attributeFormCopy, setAttributeFormCopy] = useState([]);
    const [criteriaValues, setCriteriaValues] = useState([]);
    const [currentAlteringAttr, setCurrentAlteringAttr] = useState();
    const [selectedOperatorMenu, setSelectedOperatorMenu] = useState();
    const [selectedListMenu, setSelectedListMenu] = useState();
    const [showReadout, setShowReadout] = useState(false);
    const [friendlyTermsArray, setFriendlyTermsArray] = useState(undefined);
    const [showReadoutAlt, setShowReadoutAlt] = useState(false);
    const [queryArray, setQueryArray] = useState();

    const [currentView, setCurrentView] = useState(
        selectedReport?.builder?.currentView ? selectedReport?.builder?.currentView : existingReportType ? "requirements" : "reportType"
    );

    const reportType = reporting?.functions?.reportType(selectedReport?.stem);
    const opportunityTypes = reporting?.functions?.sessionReportBranches(props?.session?.user?.data?.opportunityTypes);
    console.log(opportunityTypes);
    const updateReporting = session?.reporting?.setData;
    const baseModule = "reporting";
    // const attributeReady = selectedReport?.stem === opportunityTypes[0] ? props?.session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.branch]?.[selectedReport?.stem]?.[0]
    // : attributeData;

    // const [attributeReady, setAttributeReady] = useState();
    const [attributeReady, setAttributeReady] = useState(attributeData);
    const [reportCategory, setReportCategory] = useState(selectedReport?.referenceStem ?? selectedReport?.stem ?? opportunityTypes[0]);
    const reportCategoryObj = {reportCategory, setReportCategory}

    const [selectedFolder, setSelectedFolder] = useState({
        name : "Show All",
        showMenu : false
    });

    const [formDropdownHandler, setFormDropdownHandler] = useState({ showMenu : undefined });

    const dragHandler = DragHandler({
        session,
        columnForm : {
            data : columnForm,
            set : setColumnForm
        },
        groupColumnsForm : {
            data : groupColumnsForm,
            set : setGroupColumnsForm
        }
    });

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            // Check if showMenu is already true
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            // If showMenu is not true, return the previous state without changes
            return prev;
        });
    };

    const resetDropdowns = () => {
        if(selectedFolder?.showMenu){
            resetShowMenu(setSelectedFolder);
        }

        if(formDropdownHandler?.showMenu){
            resetShowMenu(setFormDropdownHandler);
        }
    };

    const [reportDetails, setReportDetails] = useState({
        ...selectedReport?.details,
    });

    const operatorData = {
        "match": {
          prompt: "Equals",
          icon: "=",
        },
        "not_match": {
          prompt: "Doesn't Equal",
          icon: "≠",
        },
        "contain": {
          prompt: "Contains",
          icon: "∋",
        },
        "not_contain": {
          prompt: "Doesn't Contain",
          icon: "∌",
        },
        "blank": {
          prompt: "Is Blank",
          icon: "∅",
        },
        "not_blank": {
          prompt: "Is Not Blank",
          icon: "∇",
        },
        "greater_than": {
          prompt: "Greater Than",
          icon: ">",
        },
        "less_than": {
          prompt: "Less Than",
          icon: "<",
        },
        "before": {
          prompt: "Before",
          icon: "⮎",
        },
        "after": {
          prompt: "After",
          icon: "⮏",
        },
        "in_between": {
          prompt: "In Between",
          icon: "⇄",
        },
        "on_or_before": {
            prompt: "On or Before",
            icon: "⇦",
        },
          "on_or_after": {
            prompt: "On or After",
            icon: "⇨",
        },
    };

    const criterionForm = {
        "string" : {criterion : ["match", "not_match", "contain", "not_contain", "blank", "not_blank"]},
        "int" : {criterion : ["match", "not_match", "greater_than", "less_than", "blank", "not_blank"]},
        "float" : {criterion : ["match", "not_match", "greater_than", "less_than", "blank", "not_blank"]},
        "dropdown" : {criterion : ["match", "not_match", "blank", "not_blank"]},
        "date" : {criterion : ["match", "on_or_before", "before", "on_or_after", "after", "in_between", "not_match", "blank", "not_blank"]},
        "generatedList" : {criterion : ["match", "not_match", "contain", "not_contain", "blank", "not_blank"]},
    };

    const isSummaryView = selectedReport?.stem === "summary";
    const hasValidSummary = selectedReport?.query && selectedReport?.columns?.length > 0;

    const groupingViewStatus = isSummaryView && !!selectedReport?.query;
    const propertiesViewStatus = isSummaryView ? hasValidSummary : !!selectedReport?.query;



    // const reportShareOptions = [
    //     { value: ["owner"], label: "Only Me" },
    //     { value: ["team"], label: "My Team" },
    // ];

    // const reportEditOptions = [
    //     { value: ["owner"], label: "Only Me" },
    //     { value: ["team"], label: "My Team" },
    // ];

    // const scrollTypeOptions = [
    //     { value: "pagination", label: "Pagination", icon: <SwitchLeftIcon/> },
    //     { value: "infinite", label: "Infinite", icon: <AllInclusiveIcon/> },
    // ];

    const getStringWidth = useCallback((existingWidth, text, spacing = 0) => {
        const measureElement = document.createElement('div');
        measureElement.className = 'tempStringMeasure';
        measureElement.textContent = text;
        document.body.appendChild(measureElement);
        const textWidth = existingWidth ?? measureElement.offsetWidth;
        document.body.removeChild(measureElement);
        return Math.min(textWidth, 200) + spacing;
    }, []);

    function maintainCriteriaValues(key, criterion, e, reset = false, special) {
        setCriteriaValues((prevCriteriaValues) => {
            const updatedCriteriaValues = { ...prevCriteriaValues };
            if (reset) {
                updatedCriteriaValues[key] = undefined;
            } else {
                if (!updatedCriteriaValues[key]){
                    updatedCriteriaValues[key] = {};
                }

                if(special && special !== true && !updatedCriteriaValues?.[key]?.[criterion]?.[special]){
                    updatedCriteriaValues[key][criterion] = {};
                    updatedCriteriaValues[key][criterion][special] = undefined;
                }

                if (!e?.target?.value) {
                    if(special && special !== true){
                        updatedCriteriaValues[key][criterion][special] = undefined;
                    }else{
                        updatedCriteriaValues[key][criterion] = undefined;
                    }
                } else {
                    if(special && special !== true ){
                        updatedCriteriaValues[key][criterion]["startDate"] = attributeForm?.[key]?.criterion?.[criterion]?.["startDate"];
                        updatedCriteriaValues[key][criterion]["endDate"] = attributeForm?.[key]?.criterion?.[criterion]?.["endDate"];
                        updatedCriteriaValues[key][criterion][special] = e.target.value;
                    }else{
                        updatedCriteriaValues[key][criterion] = e.target.value;
                    }
                }

                if (Object.keys(updatedCriteriaValues[key]).length === 0) {
                    delete updatedCriteriaValues[key];
                }
            }

            return updatedCriteriaValues;
        });
    }

    const updateCriteriaValue = (e, index, key, special) => {
        setAttributeForm((prevValues) => {
            const updatedValues = [...prevValues];
            const updatedCriterion = { [key] : ["blank", "not_blank"].includes(key) ? true : e.target.value }; // Create a copy of the criterion object

            if(special){
                if(special === true){
                    if((!updatedValues?.[index]?.criterion?.[key]?.startDate && !updatedValues?.[index]?.criterion?.[key]?.endDate)){
                        updatedValues[index] = {
                            ...updatedValues[index],
                            criterion: {[key] : {"startDate" : undefined, "endDate" : undefined}},
                        };
                    }
                }else{
                    updatedValues[index] = {
                        ...updatedValues[index],
                        criterion:
                        {
                            ...updatedValues[index].criterion, 
                            [key] : {
                                ...updatedValues[index].criterion[key],
                                [special]: e.target.value
                            }
                        }
                    };
                }
            }else{
                updatedValues[index] = {
                    ...updatedValues[index],
                    criterion: key ? updatedCriterion : {},
                };
            }

            return updatedValues;
        });
    };

    const currentForm = (key) => { 
        return ((isSummaryView && currentView === "grouping") || key === "initialize" ?
            { data : groupColumnsForm, set : setGroupColumnsForm, type : "grouping" }
        :
            { data : attributeForm, set : setAttributeForm, type : "default" })
    }

    const updateColumnFormOrder = (newSelectedAttributes) => {
        const reorderedStandardColumns = newSelectedAttributes?.map(attr => 
            columnForm.find(column => column?.id === attr?.id)
        ).filter(column => column);
    
        const customColumns = columnForm?.filter(column => 
            !newSelectedAttributes.some(attr => attr?.id === column?.id)
        );
    
        return [...reorderedStandardColumns, ...customColumns];
    };

    function reorderColumns(columnForm, groupColumnsForm) {
        // Create an order reference array from the groupColumnsForm
        const orderReference = groupColumnsForm.map(item => item.id);
    
        // Sort the columnForm array
        const sortedColumns = columnForm.sort((a, b) => {
            // Check if either column is custom
            const aIsCustom = a.custom !== undefined;
            const bIsCustom = b.custom !== undefined;
    
            // If both are custom or non-custom, sort by the order reference
            if (aIsCustom === bIsCustom) {
                return orderReference.indexOf(a.id) - orderReference.indexOf(b.id);
            }
    
            // If 'a' is custom and 'b' is not, 'a' should come after 'b'
            return aIsCustom ? 1 : -1;
        });
    
        return sortedColumns;
    }

    const updateAttributeForm = (action, value, key) => {
        const selectedAttributes = currentForm(key);
        setCurrentAlteringAttr();
    
        let tempAttrForm = [...selectedAttributes?.data];
    
        switch (action) {
            case "add":
                selectedAttributes?.set([...tempAttrForm, value]);
                break;
    
            case "alter":
                const updatedArray = tempAttrForm.map((item, index) =>
                    index === key ? { ...item, ...value } : item
                );
    
                if (Number.isInteger(key) && key >= 0 && key < tempAttrForm?.length) {
                    selectedAttributes.set(updatedArray);
                }
    
                setColumnForm(reorderColumns(columnForm, updatedArray, 5));
                break;

            case "remove":
                const itemToRemove = tempAttrForm[value];
                const groupIdToRemove = itemToRemove.groupID;
            
                // Remove the item
                const updatedTempAttrForm = [...tempAttrForm];
                updatedTempAttrForm.splice(value, 1);
            
                // Ensure new parent (anchor) gets relativeKey set to undefined and its children get the correct relativeKey
                if (itemToRemove.relativeKey === undefined) {
                    let newParentIndex = updatedTempAttrForm.findIndex((item, index) => index >= value && item.groupID === groupIdToRemove);
                    if (newParentIndex !== -1) {
                        updatedTempAttrForm[newParentIndex].relativeKey = undefined;
                        updatedTempAttrForm.forEach((item, index) => {
                            if (item.groupID === groupIdToRemove && index !== newParentIndex) {
                                item.relativeKey = newParentIndex;
                            }
                        });
                    }
                } else {
                    updatedTempAttrForm.forEach((item, index) => {
                        if (item.relativeKey === value) {
                            item.relativeKey = itemToRemove.relativeKey;
                        } else if (item.relativeKey > value) {
                            item.relativeKey -= 1;
                        }
                    });
                }
            
                // Reassign groupID if necessary
                updatedTempAttrForm.forEach((item, index) => {
                    if (item.groupID > groupIdToRemove) {
                        item.groupID -= 1;
                    }
                });
            
                // Handle column removal
                if (selectedAttributes?.type === "grouping") {
                    const activeItems = updatedTempAttrForm.filter(item => !item?.inactive);
                    const updatedActiveItems = activeItems.map((item, index) => ({
                        ...item,
                        relativeKey: index
                    }));
            
                    if (updatedActiveItems.length === 0) {
                        updatedTempAttrForm.push({
                            attr: undefined,
                            combineBy: undefined,
                            formType: undefined,
                            friendlyTerm: undefined,
                            groupID: 0,
                            groupOperator: "groupBy",
                            id: generateUniqueId(),
                            inactive: undefined,
                            relativeKey: 0,
                        });
                    } else {
                        updatedTempAttrForm.length = 0; // Clear the array
                        updatedTempAttrForm.push(...updatedActiveItems); // Populate with updated active items
                    }
            
                    if (columnForm.find(column => column.id === itemToRemove?.id)) {
                        addOrRemoveColumns({
                            pointer: "id",
                            objectKey: itemToRemove?.id
                        });
                    }
                }
            
                // Create new criteriaValues based on the updated tempAttrForm
                const newCriteriaValues = {};
                updatedTempAttrForm.forEach((item, index) => {
                    if (item.criterion) {
                        newCriteriaValues[index] = item.criterion; // Use the index as the key
                    }
                });

                selectedAttributes?.set([...updatedTempAttrForm]); // Ensure a new array reference is passed
                setCriteriaValues(newCriteriaValues); // Update criteriaValues with the new object
                break;
            case "reposition":
                if (Number.isInteger(key) && Number.isInteger(value) && key !== value && key < tempAttrForm.length && value < tempAttrForm.length) {
                    const itemToMove = tempAttrForm.splice(key, 1)[0];
                    tempAttrForm.splice(value, 0, itemToMove);
    
                    // Use a callback to handle state update so you work with the most updated data
                    selectedAttributes.set(temp => {
                        const updatedAttributes = [...temp];
                        updatedAttributes.splice(key, 1);
                        updatedAttributes.splice(value, 0, itemToMove);
    
                        // Immediately use the updated state to reorder columnForm
                        const reorderedColumnForm = updateColumnFormOrder(updatedAttributes);
                        setColumnForm(reorderedColumnForm); // Assuming you have a setter for columnForm
                        // updateReporting("selectedReport", "columns", reorderedColumnForm);
                        session?.set(baseModule, "selectedReport.columns", reorderedColumnForm);
                        return updatedAttributes;
                    });
                }
                break;
    
            default:
                console.error("Invalid action provided");
        }
    };
    
    const alterAttributeKeyValue = (index, key, value) => {
        const selectedAttributes = currentForm(key)

        selectedAttributes?.set((prevAttributeForm) => {
        const updatedAttributeForm = [...prevAttributeForm];
            updatedAttributeForm[index] = {
                ...updatedAttributeForm[index],
                [key]: value
            };

            return updatedAttributeForm;
        });
    };

    const alterAttributeCriteria = (index, key, value, special) => {
        const selectedAttributes = currentForm(key)

        selectedAttributes?.set((prevAttributeForm) => {
        const updatedAttributeForm = [...prevAttributeForm];
            updatedAttributeForm[index] = {
                ...updatedAttributeForm[index],
                [key]: special ? { [value] : {"startDate": undefined, "endDate" : undefined} } : { [value]: criteriaValues?.[index]?.[value] },
            };

            return updatedAttributeForm;
        });
    };

    function generateUniqueId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    function addOrRemoveColumns({ column, objectKey, customSet, pointer = "columnName", special, value, existingID }) {
        const isCustom = customSet ?? column?.custom;
        const key = special === "append" ? Math.max(0, ...value.map(obj => obj.customKey || 0)) + 1 : objectKey || column?.[pointer];
        if (special === "defaults") {
            const newColumnItems = value
                .filter(col => !columnForm.some(obj => obj[pointer] === col))
                .map(col => ({
                    id: existingID || generateUniqueId(),
                    [pointer]: col,
                    friendlyTerm: attributeData?.[col]?.friendlyTerm,
                    frozen: false,
                    mobileFriendlyTerm: attributeData?.[col]?.mobileFriendlyTerm,
                    altColor: attributeData?.[col]?.altColor,
                    editable: attributeData?.[col]?.editable,
                    width : getStringWidth(undefined, attributeData?.[col]?.friendlyTerm, 82),
                }));
    
            const updatedColumnForm = [...columnForm, ...newColumnItems];
            setColumnForm(updatedColumnForm);

            session?.set(baseModule, "selectedReport.columns", updatedColumnForm);
        } else if (special === "summaryCount") {
            // Check if a "summaryCount" item already exists in columnForm
            const summaryCountExists = columnForm.some(
                column => column?.custom === "preset" && column?.summarizeBy === "Count"
            );
        
            // Only add a new "summaryCount" item if it doesn't already exist
            if (!summaryCountExists) {
                const updatedColumnForm = [
                    ...columnForm,
                    {
                        custom: "preset",
                        customKey: Math.max(0, ...columnForm.map(column => column?.customKey || 0)) + 1,
                        fieldBy: "recordID",
                        friendlyTerm: "Count",
                        frozen: false,
                        id: generateUniqueId(),
                        summarizeBy: "Count",
                        width : getStringWidth(undefined, "Count", 82),
                    }
                ];
                setColumnForm(updatedColumnForm);
                session?.set(baseModule, "selectedReport.columns", updatedColumnForm);
            }
        } else {
            const exists = columnForm.some(item => item?.[pointer] === key);
            if (exists) {
                const itemToRemove = columnForm.find(item => item?.[pointer] === key);
                const newColumnForm = columnForm.filter(item => item?.[pointer] !== key);

                setColumnForm(newColumnForm);
            
                session?.set(baseModule, "selectedReport.columns", newColumnForm);
                
                if(isSummaryView){
                    setGroupColumnsForm(current => {
                        const updatedForm = current.filter(item => item?.id !== itemToRemove?.id);
                        return updatedForm.every(attr => attr?.inactive) ? [{
                          ...updatedForm[0], 
                          id : generateUniqueId(),
                          attr: undefined, 
                          combineBy: undefined, 
                          formType: undefined, 
                          friendlyTerm: undefined, 
                          groupID: 0, 
                          groupOperator: "groupBy", 
                          inactive: undefined, 
                          relativeKey: 0
                        }] : updatedForm;
                    });
                }
            } else {
                const newItem = {
                    id: existingID || generateUniqueId(),
                    [isCustom ? "customKey" : "columnName"]: key,
                    friendlyTerm: isCustom ? undefined : attributeData?.[key]?.friendlyTerm,
                    custom: isCustom,
                    frozen: false,
                    width : getStringWidth(undefined, isCustom ? undefined : attributeData?.[key]?.friendlyTerm, 82),
                    ...(isCustom && { fieldBy: undefined, summarizeBy: undefined }),
                };

                const updatedColumnForm = isCustom
                    ? [...columnForm, newItem]
                    : [...columnForm.filter(item => !item.custom), newItem, ...columnForm.filter(item => item.custom)];
            
                // Update the state and reporting with the modified columnForm
                setColumnForm(isSummaryView ? reorderColumns(updatedColumnForm, groupColumnsForm, 2) : updatedColumnForm);
                // updateReporting("selectedReport", "columns", updatedColumnForm);
                session?.set(baseModule, "selectedReport.columns", updatedColumnForm);
            }
        }
    }

    function selectAttribute(attr, relativeKey, alternation) {
        // updateReporting("selectedReport.criteria.current", "columnName", attr);
        session?.set(baseModule, "selectedReport.criteria.current.columnName", attr);

        const newGroupID = attributeForm
        .filter((attribute) => attribute?.relativeKey === undefined && !attribute?.inactive)
        .reduce((maxID, attribute) => Math.max(maxID, attribute.groupID), 0) + 1;      

        if(alternation){
            const existingKey = Object.keys(alternation)[0];
            alterAttributeKeyValue(existingKey, "attr", attr);
            alterAttributeKeyValue(existingKey, "formType", attributeData?.[attr]?.formType);
            alterAttributeCriteria(
                existingKey,
                "criterion",
                undefined
            );
            maintainCriteriaValues(existingKey, null, null, true);
            updateCriteriaValue(
                {
                    target: {
                        value: undefined,
                    },
                },
                existingKey,
                undefined
            );
            setCurrentAlteringAttr();
        }else{
            if(selectedReport?.selectAll && currentView === "requirements"){
                // updateReporting("selectedReport", "selectAll", false);
                session?.set(baseModule, "selectedReport.selectAll", false);
                setAttributeForm([{
                    "id" : generateUniqueId(),
                    "formType": attributeData?.[attr]?.formType,
                    "criterion": {},
                    "relativeKey": relativeKey ?? undefined,
                    "relationType": undefined,
                    "groupID" : relativeKey !== null ? attributeForm[relativeKey].groupID : newGroupID,
                    "groupOperator": "AND",
                    "inlineOperator": "AND",
                    "attr": attr,
                }]);
            }else{
                updateAttributeForm("add", {
                    "id" : generateUniqueId(),
                    "formType": attributeData?.[attr]?.formType,
                    "criterion": {},
                    "relativeKey": relativeKey ?? undefined,
                    "relationType": undefined,
                    "groupID" : relativeKey !== null ? attributeForm?.[relativeKey]?.groupID : newGroupID,
                    "groupOperator": "AND",
                    "inlineOperator": "AND",
                    "attr": attr,
                });
            }
        }
    }

    function addPlaceholderReqBlock(selectedAttributes){
        const newGroupID = selectedAttributes
        .filter((attribute) => attribute?.relativeKey === undefined && !attribute?.inactive)
        .reduce((maxID, attribute) => Math?.max(maxID, attribute?.groupID), 0) + 1;      

        updateAttributeForm("add", {
            "formType": undefined,
            "criterion": {},
            "relativeKey": undefined,
            "relationType": undefined,
            "groupID" : newGroupID,
            "groupOperator": "AND",
            "inlineOperator": "AND",
            "attr": undefined
        });
    }

    function groupFormStatus() {
        const hasAnyAttribute = obj => obj?.attr || obj?.combineBy;
        const hasBothAttributes = obj => obj?.attr && obj?.combineBy;
      
        if (!groupColumnsForm || groupColumnsForm.length === 0) {
          return null;
        }

        const anchor = groupColumnsForm[0];
        const siblings = groupColumnsForm.slice(1);
        const anchorHasAny = hasAnyAttribute(anchor);
        const anchorHasBoth = hasBothAttributes(anchor);

        if (!anchorHasAny && siblings.length === 0) {
          return null;
        } else if (!anchorHasAny && siblings.length > 0) {
          return "fail";
        } else if (anchorHasAny && !anchorHasBoth && siblings?.length > 0) {
          return "error";
        }

        return "complete";
    }

    function alterCustomColumn(id, attr, value) {
        const updateArray = (array) =>
            array.map(customCol => {
                if (customCol.id === id) {
                    if (attr) {
                        return { ...customCol, [attr]: value };
                    } else {
                        return { ...customCol, ...value };
                    }
                }
                return customCol; // Return unaltered columns as is
            }
        );

        const updatedColumnForm = updateArray(columnForm);
        setColumnForm(reorderColumns(updatedColumnForm, groupColumnsForm, 1));
    }

    const printColumnForm = (showCustomColumns = false, prompt) => {
        const type = showCustomColumns ? "custom" : "default";
        const dragging = dragHandler?.data[type];
        const nonCustomLength = columnForm?.filter(column => !column?.custom).length;
        const offset = showCustomColumns ? nonCustomLength : 0;
        const filteredColumns = columnForm?.filter(column => (showCustomColumns ? column?.custom : !column?.custom)) || [];

        const groupBySection = isSummaryView ? (
            <div key={type + "Title"} className="categoryTitle f cC bold">{prompt}</div>
        ) : null;

        // If there are no filtered columns, return a message
        if (filteredColumns.length === 0) {
            return [
                groupBySection,
                <div key={type + "Empty"} className="f cC">
                    No Existing Columns
                </div>
            ];
        }

        return [
            groupBySection,
            ...filteredColumns.map((column, index) => {
                const adjustedIndex = index + offset;  // Adjust index for custom columns
                const isFrozen = (selectedReport?.columns?.find(col => col?.id === column?.id)?.frozen) || false;
                const isAnchor = dragging?.draggingColumnID === column?.id && dragging?.draggingColumnID !== undefined;
    
                if (column?.placeholder && dragging?.draggingIndex !== undefined) {
                    return (
                        <div 
                            key={`placeholder-${adjustedIndex}`}
                            className="placeholder bR dP bold cC"
                            onDragOver={(e) => dragHandler?.functions?.handleDragOver(e, adjustedIndex, type)}
                            onDragLeave={(e) => dragHandler?.functions?.handleDragLeave(e, adjustedIndex, type)}
                            onDragEnd={(e) => dragHandler?.functions?.handleDragEnd(e, adjustedIndex, type)}
                            onDrop={(e) => dragHandler?.functions?.handleDrop(e, adjustedIndex, type)}
                            onDragEnter={(e) => dragHandler?.functions?.handleDragEnter(e, adjustedIndex, type)}
                        >
                            <div className="f cC">
                                Moving Here
                            </div>
                        </div>
                    );
                }

                return (
                    <div
                        key={column?.id}
                        draggable
                        className={`option g f cC bold nS bR fR dP${isAnchor  ? " moving" : ''}${isFrozen ? " frozen" : ''}`}
                        onDragStart={(e) => dragHandler?.functions?.handleDragStart(e, adjustedIndex, type, column?.id, column)}
                        onDragOver={(e) => dragHandler?.functions?.handleDragOver(e, adjustedIndex, type)}
                        onDrop={(e) => dragHandler?.functions?.handleDrop(e, adjustedIndex, type)}
                        onDragEnd={(e) => dragHandler?.functions?.handleDragEnd(e, adjustedIndex, type)}
                        onDragEnter={(e) => dragHandler?.functions?.handleDragEnter(e, adjustedIndex, type)}
                        onMouseOver={(e) => dragHandler?.functions?.handleColumnHoverStart(e, adjustedIndex, type)}
                        onMouseLeave={(e) => dragHandler?.functions?.handleColumnHoverLeave(e, adjustedIndex, type)}
                    >
                        {((dragging?.draggingIndex === undefined && dragging?.currentColumnHover === adjustedIndex) || isFrozen) &&
                            <div
                                className="setFrozen f cC p"
                                onClick={() => {
                                    session?.set(baseModule, `selectedReport.columns.${adjustedIndex}.frozen`, !isFrozen);
                                    setColumnForm(prev => {
                                        return prev.map((column, index) => {
                                            if (index === adjustedIndex) {
                                                return {
                                                    ...column,
                                                    frozen: !column?.frozen
                                                };
                                            }
                                            return column;
                                        });
                                    });
                                }}
                                onMouseOver={(e) => {e.stopPropagation(); e.currentTarget.parentElement.classList.add("freezing")}}
                                onMouseLeave={(e) => e.currentTarget.parentElement.classList.remove("freezing")}
                            >
                                <AcUnitIcon />
                            </div>
                        }
                        <div className="f cC gC2 gCW">
                            {column?.friendlyTerm || "*Unnamed Column"}
                        </div>
                        {isAnchor &&
                            <PanToolIcon />
                        }
                        {dragging?.draggingIndex === undefined && dragging?.currentColumnHover === adjustedIndex &&
                            <div
                                className="moreOptions f cC p gC3"
                                onClick={() => {
                                    addOrRemoveColumns({
                                        column: column,
                                        pointer: column?.custom ? "customKey" : selectedReport?.branch === "summary" ? "id" : undefined,
                                        ...(column?.customKey ? { objectKey: column?.customKey } : {})
                                    })
                                }}
                                onMouseOver={(e) => {e.stopPropagation(); e.currentTarget.parentElement.classList.add("removing")}}
                                onMouseLeave={(e) => e.currentTarget.parentElement.classList.remove("removing")}
                            >
                                <DeleteForeverIcon />
                            </div>
                        }
                    </div>
                );
            })
        ];
    };

    function printReportCategories() {
        const reportData = reporting?.data?.reportDataHandler;
        const opportunityTypeCount = opportunityTypes?.length + 1;

        return (
            <div
                className="reportablePages g f cC fR"
                style={{gridTemplateColumns : `repeat(${opportunityTypeCount}, 1fr)`}}
            >
                {opportunityTypes?.map((reportType, index) => {
                    const reportTypeInfo = reportData?.[reportType];
                    return (
                        <div
                            key={index}
                            className={`reportablePage g p cC dP bR f fC bold${
                                selectedReport?.stem === reportType ? " active" : ''}`}
                            onClick={() => {
                                const newReport = { ...newReportTemplate };
                                newReport.branch = reportTypeInfo?.branchType;
                                newReport.stem = reportTypeInfo?.stem;

                                session?.set(baseModule, "selectedReport", newReport);
                                setCurrentView("requirements");

                                setAttributeForm([]);
                                setColumnForm([]);
                            }}
                        >
                            {reportTypeInfo?.icon}
                            <div className="f cC">
                                {reportTypeInfo?.prompt}
                            </div>
                        </div>
                    );
                })}
                <div
                    key={"summary"}
                    className={`reportablePage g p cC dP bR f fC bold${isSummaryView ? " active" : ''}`}
                    onClick={() => {
                        const newReport = { ...newReportTemplate };
                        newReport.branch = "summary";
                        newReport.stem = "summary";
                        setAttributeForm([]);
                        setColumnForm([]);
                        // updateReporting("selectedReport", null, newReport);
                        session?.set(baseModule, "selectedReport", newReport);
                        setCurrentView("requirements");
                    }}
                >
                    {reportData?.["summary"].icon}
                    <div className="f cC">
                        {"Summary"}
                    </div>
                </div>
            </div> 
        );
    }

    function printGroupByForm(){
        const attributeFormWithOriginalKeys = groupColumnsForm.map((attribute, index) => ({
            originalIndex: index,
            ...attribute,
        }));

        return (
            <div
                key={"GroupByBlock"}
                className={`requirementGroup container g bR dP fC${groupFormStatus() ? ' ' + groupFormStatus() : ''}`}
            >
            {attributeFormWithOriginalKeys
                .filter(attribute => !attribute?.inactive)  // Filter out attributes where "inactive" is true
                .map((attribute) => {
                    const index = attributeFormWithOriginalKeys?.findIndex(origAttribute => origAttribute?.id === attribute?.id);
                    const firstActiveIndex = attributeFormWithOriginalKeys?.findIndex(attr => !attr?.inactive);
                    const lastActiveIndex = attributeFormWithOriginalKeys?.findLastIndex(attr => !attr?.inactive);
                    const isAnchor = index === firstActiveIndex;

                    const currentStatus = (directIndex) => {
                        const anchor = groupColumnsForm?.[firstActiveIndex];
                        let current;
                        if (typeof directIndex !== 'undefined' && groupColumnsForm?.[directIndex]) {
                            current = groupColumnsForm[directIndex];
                        } else {
                            current = groupColumnsForm?.find(column => column.id === attribute?.id);
                        }
                        // const current = groupColumnsForm?.[directIndex ?? index];
                        const isAnchor = (directIndex ?? index) === firstActiveIndex;
                        const blankAnchor = !(anchor?.attr && anchor?.combineBy);
                        const completedCurrent = current?.attr && current?.combineBy;
                        let status = '';
                        let icon = '';
                    
                        // Consolidate anchor and current status determination
                        if (isAnchor) {
                            status = blankAnchor ? "error" : "complete";
                            icon = blankAnchor ? "pending" : "check";
                            status += " parent";
                        } else {
                            // For non-anchor cases, determine status based on anchor and current conditions
                            if (blankAnchor || !completedCurrent) {
                                status = "error";
                                icon = blankAnchor && completedCurrent ? "alert" : "pending";
                            } else {
                                status = "complete";
                                icon = "check";
                            }
                    
                            // Append "blank" if current is missing attributes, regardless of other conditions
                            if (!current?.attr || !current?.combineBy) {
                                status += " blank";
                            }
                    
                            status += " child";
                        }
                    
                        return { status, icon };
                    };

                    return (
                        <React.Fragment key={`${attribute?.id} Parent`}>
                            <div
                                key={`${attribute?.id}`}
                                className={`requirementBlock g bR dP ${currentStatus()?.status}`}
                            >
                                <div className={`attrTopBar grouping cL g bold dG`}>
                                    <div className="statusIcon f cC">
                                        {currentStatus()?.icon === "alert" && <ErrorOutlinedIcon />}
                                        {currentStatus()?.icon === "check" && <CheckCircleIcon />}
                                        {currentStatus()?.icon === "pending" && <PendingActionsIcon />}
                                    </div>
                                    <div className="gC2 f g dG">
                                        <div className="f cR">
                                            {`${isAnchor ? "Group" : "Then"} by`}
                                        </div>
                                        <Dropdown
                                            key={`Group-${attribute?.id}-Attr-Dropdown-GroupBy`}
                                            setShowMenu={(input) => {
                                                const newState = input ?? (formDropdownHandler?.showMenu ? undefined : `Group-${index}-Attr`);
                                                setFormDropdownHandler({
                                                    ...formDropdownHandler,
                                                    showMenu: newState
                                                });
                                            }}
                                            showMenu={formDropdownHandler?.showMenu === `Group-${index}-Attr`}
                                            prompt={!groupColumnsForm?.[index]?.friendlyTerm ? "Select a Field" : undefined}
                                            default={groupColumnsForm?.[index]?.friendlyTerm ?? undefined }
                                            list={friendlyTermsArray}
                                            onClick={(value) => {
                                                const attrKey = Object.keys(attributeData).find(key =>
                                                    attributeData?.[key]?.friendlyTerm === value
                                                );

                                                const id = attributeFormWithOriginalKeys?.[index]?.id ?? generateUniqueId();
                                                const attrData = attrKey ? attributeData?.[attrKey] : undefined;
                                                const action = groupColumnsForm?.[index] ? "alter" : "add";
                                                const columnObj = {
                                                    "formType": attrData?.formType,
                                                    "groupOperator": "groupBy",
                                                    "friendlyTerm": value,
                                                    "attr": attrKey,
                                                    "inactive": undefined,
                                                    "groupID" : 0,
                                                    "relativeKey" : 0,
                                                    ...(action === "add" ? { "id": id } : {})
                                                }

                                                updateAttributeForm(action, columnObj, index);
   
                                                if(!columnForm.some(column => column?.id === id)){
                                                    addOrRemoveColumns({
                                                        column : columnObj,
                                                        existingID : id,
                                                        pointer : "attr",
                                                    });
                                                }else{
                                                    alterCustomColumn(id, undefined, {
                                                        columnName: attrKey,
                                                        friendlyTerm: value,
                                                    });
                                                }
                                            }}
                                            flex={true}
                                            reset={props?.resetDropdowns}
                                            className={["inForm", groupFormStatus() === "fail" && !isAnchor ?
                                                !(groupColumnsForm?.[index]?.attr && groupColumnsForm?.[index]?.combineBy) ?
                                                    currentStatus()?.status
                                                :
                                                    "fail"
                                            :
                                                currentStatus()?.status]}
                                        />
                                    </div>
                                    <div className="gC3 f g dG">
                                        <div className="f cR">
                                            Combine by
                                        </div>
                                        <Dropdown
                                            key={`Group-${attribute?.id}-Attr-Dropdown-CombineBy`}
                                            setShowMenu={(input) => {
                                                const newState = input ?? (formDropdownHandler?.showMenu ? undefined : `Group-${index}-Combine`);
                                                setFormDropdownHandler({
                                                    ...formDropdownHandler,
                                                    showMenu: newState
                                                })
                                            }}
                                            showMenu={formDropdownHandler?.showMenu === `Group-${index}-Combine`}
                                            prompt={!groupColumnsForm?.[index]?.combineBy ? "Select Combine Type" : undefined}
                                            default={groupColumnsForm?.[index]?.combineBy ?? undefined}
                                            inactive={!groupColumnsForm?.[index]?.attr}
                                            list={reporting?.data?.groupBySummaries?.[attributeData?.[groupColumnsForm?.[index]?.attr]?.formType]}
                                            onClick={(value) => {
                                                const attrKey = Object.keys(attributeData).find(key => 
                                                    attributeData?.[key]?.friendlyTerm === value
                                                );

                                                const attrData = attrKey ? attributeData?.[attrKey] : undefined;
                                                const action = groupColumnsForm?.[index] ? "alter" : "add";
                                                updateAttributeForm(action, {
                                                    "combineBy" : value
                                                },
                                                index);
                                            }}
                                            chainHandler={
                                                {
                                                    chain : [groupColumnsForm?.[index]?.attr],
                                                    sync : ()=> { updateAttributeForm("alter", {
                                                        "combineBy" : undefined
                                                    }, index)},
                                                }
                                            }
                                            test={reporting?.data?.groupBySummaries}
                                            flex={true}
                                            reset={props?.resetDropdowns}
                                            className={["inForm", groupFormStatus() === "fail" && !isAnchor && groupColumnsForm?.[index]?.combineBy ? "fail" : currentStatus()?.status]}
                                        />
                                    </div>
                                    <div className="positionHandler f g">
                                        <div
                                            className={`option f cC bR${index === 0 ? " inactive" : " p"}`}
                                            onClick={() => {
                                                if (index !== 0) {
                                                    updateAttributeForm("reposition", index - 1, index);
                                                }
                                            }}
                                        >
                                            <ArrowDropUpIcon/>
                                        </div>
                                        <div
                                            className={`option f cC bR${index === groupColumnsForm?.length - 1 ? " inactive" : " p"}`}
                                            onClick={() => {
                                                if (index !== groupColumnsForm?.length - 1) {
                                                    updateAttributeForm("reposition", index + 1, index);
                                                }
                                            }}
                                        >
                                            <ArrowDropDownIcon/>
                                        </div>
                                    </div>
                                </div>
                                <div className="deleteBtn f cC p bR" onClick={()=>{updateAttributeForm("remove", index, groupColumnsForm?.[index]?.id)}}>
                                    <DeleteForeverIcon/>
                                </div>
                            </div>
                            {index === lastActiveIndex &&
                                <div key={index + "AddOperator"} className="addOperator g">
                                    <div className="f g bR p" onClick={()=>{selectAttribute(attribute?.columnName, 0)}}>
                                        <div className="f cC">
                                            <AddIcon/>
                                        </div>
                                        <div className="f cL bold e lH">
                                            Additional Grouping
                                        </div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    );
                })}
            </div>
        )
    }
    
    function printAttributeForm() {
        const AddRequirementBlock = (props) => {
            return (
                <div
                    key={props?.index + "Separator"}
                    className="separator g cC"
                >
                    <div className="addRequirementBlock bR g bold dP gC2 p" onClick={()=>{addPlaceholderReqBlock(selectedAttributes)}}>
                        <div className="f cC">
                            <AddCircleIcon/>
                        </div>
                        <div className="f cC lH">
                            Add Requirement Block
                        </div>
                    </div>
                </div>
            )
        }

        function selectedCriteria(index, attr, type){
        
            if (attributeForm?.[index]?.criterion && attr && attr.criterion && type in attr.criterion) {
                return true;
            }
        
            return false;
        }

        function printOperatorMenuOptions(key, attribute) {
            if (criterionForm.hasOwnProperty(attribute?.formType)) {
                const criteria = criterionForm?.[attribute?.formType]?.criterion;

                const options = criteria.map((criterion) => (
                    <div
                        key={criterion}
                        className={`option alt cC bR dP p g bold actionReady`}
                        onClick={(e) => {
                            e.stopPropagation();
                            alterAttributeCriteria(
                                key,
                                "criterion",
                                criteriaValues?.[key]?.[criterion],
                                criterion === "in_between" ? true : undefined
                            );
                            updateCriteriaValue(
                                {
                                    target: {
                                        value: criteriaValues?.[key]?.[criterion],
                                    },
                                },
                                key,
                                criterion,
                                criterion === "in_between" ? true : undefined
                            );
                            setSelectedOperatorMenu();
                        }}
                    >
                        <div className="f cL gCW">
                            {operatorData?.[criterion]?.prompt}
                        </div>
                        <div className="f cC">
                            {operatorData?.[criterion]?.icon}
                        </div>
                    </div>
                ));

                return options;
            }

            return [];
        }

        function printOperatorHandler(index, inline){
            return (
              <div
                key={index + "OperatorBlock"}
                className={`separator fR cC g${inline ? " inline" : ""}`}
              >
                {attributeForm?.[index]?.[inline ? "inlineOperator" : "groupOperator"] === "AND" &&
                    <div className="andConnector">
                    </div>
                }
                <div className="operatorBlock g bR bold gC2 nS">
                  <div
                    className={`f cC${attributeForm?.[index]?.[inline ? "inlineOperator" : "groupOperator"] === "AND" ? " selected" : " p"}`}
                    onClick={() => {
                      alterAttributeKeyValue(index, inline ? "inlineOperator" : "groupOperator", "AND");
                    }}
                  >
                    AND
                  </div>
                  <div
                    className={`f cC${attributeForm?.[index]?.[inline ? "inlineOperator" : "groupOperator"] === "OR" ? " selected" : " p"}`}
                    onClick={() => {
                      alterAttributeKeyValue(index, inline ? "inlineOperator" : "groupOperator", "OR");
                    }}
                  >
                    OR
                  </div>
                </div>
              </div>
            );
        }

        function printListMenuOptions(key, attribute, criterion) {
            if (attributeData.hasOwnProperty(attribute?.attr)) {
                const list = attributeData[attribute?.attr].list;

                const options = list.map((listItem) => (
                    <div
                        key={listItem}
                        className={`option cC bR dP p g bold actionReady`}
                        onClick={(e) => {
                            alterAttributeCriteria(key, "criterion", criterion);
                            updateCriteriaValue({target : {value : listItem}}, key, criterion);
                            setSelectedListMenu();
                        }}
                    >
                        <div className="f cL gCW">
                            {listItem}
                        </div>
                    </div>
                ));

                return options;
            }

            return [];
        }

        function printGeneratedListMenuOptions(key, attribute, criterion) {
            console.log(key, attribute, criterion);
            if (attributeData?.hasOwnProperty(attribute?.attr)) {
              const list = attributeData?.[attribute?.attr]?.list?.[selectedReport?.referenceStem ?? selectedReport.stem] || {};
              console.log(attributeData, attribute, selectedReport);
              console.log(list);
              let keys = Object.keys(list).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
          
              if (keys.length === 0) {
                keys = ["N/A"];
              }
          
              const options = keys.map((value) => {
                return (
                  <div
                    key={key + value}
                    className={`option cC bR dP p g bold actionReady`}
                    onClick={(e) => {
                      if (value !== "N/A") {
                        alterAttributeCriteria(key, "criterion", criterion);
                        updateCriteriaValue({ target: { value: value } }, key, criterion);
                      }
                      setSelectedListMenu();
                    }}
                  >
                    <div className="f cL gCW">
                      {value === "N/A" ? "N/A" : (value === "" ? "Empty" : value)}
                    </div>
                  </div>
                );
              });
          
              return options;
            }
          
            return [
              <div
                key={key + "N/A"}
                className={`option cC bR dP p g bold actionReady`}
              >
                <div className="f cL gCW">N/A</div>
              </div>
            ];
          }

        const selectedAttributes = attributeForm;
        const attributeFormWithOriginalKeys = selectedAttributes.map((attribute, index) => ({
            originalIndex: index,
            ...attribute,
        }));

        attributeFormWithOriginalKeys.sort((a, b) => a.groupID - b.groupID);

        return attributeFormWithOriginalKeys.map((attribute) => {
            const index = attribute.originalIndex;

            if (attribute?.relativeKey !== undefined || attribute?.inactive) {
                return null;
            }

            const isCurrentlyAltering = currentAlteringAttr && Object?.keys(currentAlteringAttr)?.[0] === String(index);

            const parentKeys = attributeFormWithOriginalKeys.filter((attribute) => attribute?.relativeKey === undefined && !attribute?.inactive);
            const firstParentKey = attribute === parentKeys[0];
            const isLastIndex = attribute === parentKeys.pop();

            const parentCriteriaBlock = selectedAttributes?.[index];
            const parentCriterion = Object?.keys(parentCriteriaBlock?.criterion)?.[0];
            const parentCriterionValue = parentCriteriaBlock?.criterion?.[parentCriterion];

            function printAttrForm(index, attribute){
                const isCurrentlyAltering = currentAlteringAttr && Object.keys(currentAlteringAttr)[0] === String(index);
                const iteratingCriterion = Object.keys(attribute?.criterion)[0];

                const iteratingCriterionValue = attribute?.criterion?.[iteratingCriterion];
                const currentValue = criteriaValues?.[index]?.[iteratingCriterion];

                const currentValueStartDate = criteriaValues?.[index]?.[iteratingCriterion]?.["startDate"];
                const currentValueEndDate = criteriaValues?.[index]?.[iteratingCriterion]?.["endDate"];

                function criterionInclusionStatus(returnType, value){
                    if(
                        parentCriteriaBlock?.attr &&
                        parentCriterion &&
                        (attributeData?.[parentCriteriaBlock?.attr]?.formType === "generatedList" ?
                            parentCriterionValue !== undefined
                        :
                        ((parentCriterionValue !== undefined && parentCriterionValue !== '') && (parentCriterion === "in_between" ?
                            (parentCriterionValue?.startDate && parentCriterionValue?.endDate) : true))) &&
                        attribute?.attr &&
                        iteratingCriterion &&
                        (attributeData?.[attribute?.attr]?.formType === "generatedList" ?
                            iteratingCriterionValue !== undefined
                        :
                        ((iteratingCriterionValue !== undefined && iteratingCriterionValue !== '') && (iteratingCriterion === "in_between" ?
                            (iteratingCriterionValue?.startDate && iteratingCriterionValue?.endDate) : true))
                        )                       
                    ){
                        if(returnType === "calendar"){
                            return <CalendarMonthIcon sx={{ color: "#2196f3" }} />
                        }

                        if(returnType === "status"){
                            return <CheckCircleIcon sx={{ color: "#4caf50" }} />;
                        }
                    }

                    if (
                        (!parentCriteriaBlock?.attr ||
                        !parentCriterion ||
                        !parentCriterionValue !== undefined) &&
                        (attribute?.attr &&
                        iteratingCriterion &&
                        (attributeData?.[attribute?.attr]?.formType === "generatedList" ?
                            iteratingCriterionValue !== undefined
                        :
                        ((iteratingCriterionValue !== undefined && iteratingCriterionValue !== '') && (iteratingCriterion === "in_between" ?
                            (iteratingCriterionValue?.startDate && iteratingCriterionValue?.endDate) : true))
                        )) &&
                        (attribute?.relativeKey !== undefined)
                    ) {
                        if(returnType === "calendar"){
                            return <CalendarMonthIcon sx={{ color: "#ffc107" }} />
                        }

                        if(returnType === "class"){
                            return " alert";
                        }

                        if(returnType === "status"){
                            return <ErrorOutlinedIcon sx={{ color: "#ffc107" }} />;
                        }
                    }

                    if (!(
                            parentCriteriaBlock?.attr &&
                            (attributeData?.[parentCriteriaBlock?.attr]?.formType === "generatedList" ?
                                parentCriterionValue !== undefined
                            :
                                ((parentCriterionValue !== undefined && parentCriterionValue !== '') && (parentCriterion === "in_between" ?
                                (parentCriterionValue?.startDate && parentCriterionValue?.endDate) : true))
                            ) &&
                            attribute?.attr &&
                            iteratingCriterion &&
                            (attributeData?.[attribute?.attr]?.formType === "generatedList" ?
                                iteratingCriterionValue !== undefined
                            :
                            ((iteratingCriterionValue !== undefined && iteratingCriterionValue !== '') && (iteratingCriterion === "in_between" ?
                                (iteratingCriterionValue?.startDate && iteratingCriterionValue?.endDate) : true))
                            )
                        )
                    ) {
                        if(returnType === "calendar"){
                            return <CalendarMonthIcon sx={{ color: value ? "#42a5f5" : "gray" }} />
                        }

                        if(returnType === "status"){
                            return <PendingActionsIcon sx={{ color: "gray" }} />;
                        }
                    }

                    return '';
                }

                const onClick = (e, setter) => {
                    e.stopPropagation();
                    setCurrentAlteringAttr();
                    setSelectedListMenu();
                    setter(selectedListMenu === index ? undefined : index);
                };

                return (
                    <div className={`attrTopBar cL g bold dG${criterionInclusionStatus("class")}`}>
                        <div className="icon f cC">
                            {criterionInclusionStatus("status")}
                        </div>
                        <div
                            className={`dropdown btn s bR cC g p lH flex${
                                (isCurrentlyAltering) ? " actionReady" : ''
                            }`}
                            onClick={()=>{setCurrentAlteringAttr({ [index]: attribute })}}
                        >
                            <div className="f cC nS gCW">
                                {isCurrentlyAltering ?
                                    `Select Attribute to ${!attribute?.attr ? "Add" : "Swap"}`
                                : !attribute?.attr ?
                                        "Click to Define"
                                    :
                                        attributeData?.[attribute?.attr]?.friendlyTerm
                                }
                            </div>
                            <div className="f cC">
                                {isCurrentlyAltering ?
                                    <SwapHorizIcon/>
                                :
                                    <ArrowDropDownIcon/>
                                }
                            </div>
                        </div>
                        {isCurrentlyAltering &&
                            <div className="cancelBtn f cC p" onClick={()=>{setCurrentAlteringAttr()}}>
                                Cancel
                            </div>
                        }
                        <div
                            className={`dropdown btn s bR cC g nS lH flex${(attribute?.attr && !isCurrentlyAltering) ? " p" : " inactive"}${
                                (selectedOperatorMenu === index) ? " actionReady" : ''
                            }`}
                            onClick={(e) =>
                                (attribute?.attr && !isCurrentlyAltering) && (
                                onClick(e, setSelectedOperatorMenu)
                            )}
                        >
                            <div className="f cC gCW">
                                {attribute?.attr ?
                                    operatorData?.[iteratingCriterion]?.prompt ?? "Select Condition"
                                : "Select Attribute"
                                }
                            </div>
                            <ArrowDropDownIcon/>
                            {selectedOperatorMenu === index &&
                                <div
                                    ref={operatorsMenu}
                                    className={
                                        `operatorsMenu menu nS g bR${printOperatorMenuOptions(index, attribute)?.length > 5 ? " s t b" : " dP"} oA tO actionReady`
                                    }
                                >
                                    {printOperatorMenuOptions(index, attribute)}
                                </div>
                            }
                        </div>
                        {!["blank", "not_blank"].includes(iteratingCriterion) && iteratingCriterion !== undefined && attribute && (
                            <div className={`f cC${selectedCriteria(index, attribute, iteratingCriterion) ? " active" : ''}`}>
                                {attribute.formType === "string" && (
                                    <input
                                        className="inputMatch"
                                        value={currentValue ?? ''}
                                        onChange={(e) => {
                                            updateCriteriaValue(e, index, iteratingCriterion);
                                            maintainCriteriaValues(index, iteratingCriterion, e);
                                        }}
                                    />
                                )}
                                {(attribute.formType === "int" || attribute.formType === "float") && (
                                    <input
                                        className="inputMatch"
                                        type="number"
                                        value={currentValue ?? ''}
                                        onChange={(e) => {
                                            updateCriteriaValue(e, index, iteratingCriterion);
                                            maintainCriteriaValues(index, iteratingCriterion, e);
                                        }}
                                    />
                                )}
                                {attribute.formType === "date" && (
                                    <>
                                        {["in_between"].includes(iteratingCriterion) ?
                                            <>
                                                <div className="inputMatchContainer">
                                                    <input
                                                        className="inputMatch"
                                                        type="date"
                                                        value={currentValueStartDate ?? ''}
                                                        onChange={(e) => {
                                                            updateCriteriaValue(e, index, iteratingCriterion, "startDate");
                                                            maintainCriteriaValues(index, iteratingCriterion, e, undefined, "startDate");
                                                        }}
                                                    />
                                                    {criterionInclusionStatus("calendar", currentValueStartDate)}
                                                </div>
                                                <div className="dateSeparator">
                                                    <RemoveIcon/>
                                                </div>
                                                <div className="inputMatchContainer">
                                                    <input
                                                        className="inputMatch"
                                                        type="date"
                                                        value={currentValueEndDate ?? ''}
                                                        onChange={(e) => {
                                                            updateCriteriaValue(e, index, iteratingCriterion, "endDate");
                                                            maintainCriteriaValues(index, iteratingCriterion, e, undefined, "endDate");
                                                        }}
                                                    />
                                                    {criterionInclusionStatus("calendar", currentValueEndDate)}
                                                </div>
                                            </>
                                        :
                                            <div className="inputMatchContainer">
                                                <input
                                                    key={iteratingCriterion}
                                                    className="inputMatch"
                                                    type="date"
                                                    value={currentValue ?? ''}
                                                    onChange={(e) => {
                                                        updateCriteriaValue(e, index, iteratingCriterion);
                                                        maintainCriteriaValues(index, iteratingCriterion, e);
                                                    }}
                                                />
                                                {criterionInclusionStatus("calendar", currentValue)}
                                            </div>
                                        }
                                    </>
                                )}
                                {attribute.formType === "dropdown" && (
                                    <div
                                        className={`dropdown btn s bR cC g nS lH flex${attribute?.attr ? " p" : " inactive"}${
                                            (selectedListMenu === index) ? " actionReady" : ''
                                        }`}
                                        onClick={(e) => {
                                            onClick(e, setSelectedListMenu);
                                        }}
                                    >
                                        <div className="f cC gCW">
                                            {attribute?.attr ?
                                                iteratingCriterionValue ?? "Select Value"
                                            : "Select Operator"
                                            }
                                        </div>
                                        <ArrowDropDownIcon/>
                                        {selectedListMenu === index &&
                                            <div
                                                ref={listMenu}
                                                className={
                                                    `operatorsMenu menu nS g bR${attributeData[attribute?.attr]?.list?.length > 5 ? " s t b" : " dP"} oA tO actionReady`
                                                }
                                            >
                                                {printListMenuOptions(index, attribute, iteratingCriterion)}
                                            </div>
                                        }
                                    </div>
                                )}
                                {attribute.formType === "generatedList" && (
                                    <div
                                        className={`dropdown btn s bR cC g nS lH flex${attribute?.attr ? " p" : " inactive"}${
                                            (selectedListMenu === index) ? " actionReady" : ''
                                        }`}
                                        onClick={(e) => {
                                            onClick(e, setSelectedListMenu);
                                        }}
                                    >
                                        <div className="f cC gCW">
                                            {attribute?.attr ?
                                                iteratingCriterionValue === '' ? "Empty" : iteratingCriterionValue ?? "Select Value"
                                            : "Select Operator"
                                            }
                                        </div>
                                        <ArrowDropDownIcon/>
                                        {selectedListMenu === index &&
                                            <div
                                                ref={listMenu}
                                                className={
                                                    `operatorsMenu menu nS g bR${attributeData?.[attribute?.attr]?.list?.length > 5 ? " s t b" : " dP"} oA tO actionReady`
                                                }
                                            >
                                                {printGeneratedListMenuOptions(index, attribute, iteratingCriterion)}
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )
            }

            const renderChildAttributes = () => {
                const childAttributes = selectedAttributes.filter((childAttribute) => childAttribute.relativeKey === index && childAttribute?.inactive !== true);

                if (childAttributes.length === 0) {
                    return null; // No child attributes, return null
                }

                return selectedAttributes.map((childAttribute, childIndex) => {
                    if(selectedAttributes?.[childIndex]?.inactive){
                        return null;
                    }

                    const isCurrentlyAltering = currentAlteringAttr && Object.keys(currentAlteringAttr)[0] === String(childIndex);

                    if (childAttribute.relativeKey === index) {
                        const childCriterion = Object.keys(childAttribute?.criterion)[0];
                        const childCriterionValue = childAttribute?.criterion?.[childCriterion];

                        return (
                            <React.Fragment key={index+childIndex}>
                                <div
                                    key={index + childIndex + "OperatorBlock"}
                                    className="f cC"
                                >
                                    {printOperatorHandler(index, true)}
                                </div>
                                <div
                                    key={index + childIndex + childCriterion}
                                    className={`requirementBlock child g bR dP${isCurrentlyAltering ? " actionReady" : ''}${
                                        (!parentCriteriaBlock?.attr ||
                                        (attributeData[parentCriteriaBlock?.attr]?.formType === "generatedList" ?
                                            parentCriterionValue === undefined
                                        :
                                            ((parentCriterionValue === undefined || parentCriterionValue === '') || (parentCriterion === "in_between" ?
                                            (!parentCriterionValue?.startDate || !parentCriterionValue?.endDate) : false))
                                        )
                                    ) ? " error" : ''}${
                                        (attributeData[childAttribute?.attr]?.formType === "generatedList" ?
                                        childCriterionValue === undefined
                                        :
                                            ((childCriterionValue === undefined || childCriterionValue === '') || (childCriterion === "in_between" ?
                                            (!childCriterionValue?.startDate || !childCriterionValue?.endDate) : false))
                                        ) ? " blank" : ''
                                    }`}
                                >
                                    {printAttrForm(childIndex, childAttribute)}
                                    <div className="deleteBtn f cC p bR" onClick={()=>{updateAttributeForm("remove", childIndex)}}>
                                        <DeleteForeverIcon/>
                                    </div>
                                </div>                        
                            </React.Fragment>
                        )
                    }

                    return false;
                });
            };

            const requirementGroupState = (isParent) => {
                let errorCount = 0;
                if(!renderChildAttributes()){
                    return '';
                }

                if (!attribute?.attr) errorCount++;
                    
                if(attributeData[parentCriteriaBlock?.attr]?.formType === "generatedList" ?
                    parentCriterionValue === undefined
                    :
                    ((parentCriterionValue === undefined || parentCriterionValue === '') || (parentCriterion === "in_between" ?
                    (!parentCriterionValue?.startDate || !parentCriterionValue?.endDate) : false))
                ){
                    errorCount++;
                }

                if(isParent){
                    return errorCount === 1 ? " error" : errorCount === 2 ? " fail" : "";
                }else{
                    return '';
                }
            };

            renderChildAttributes()

            return (
                <React.Fragment key={index}>
                    {!firstParentKey && printOperatorHandler(index)}
                    <div
                        key={index + "Group" + attribute?.attr}
                        className={`requirementGroup${renderChildAttributes() ? " container" : ''}${requirementGroupState(renderChildAttributes())} g bR dP fC`}
                    >
                        <div
                            key={index + "Item" + attribute?.attr}
                            className={`requirementBlock g bR dP${isCurrentlyAltering ? " actionReady" : ''}${!parentCriterionValue && renderChildAttributes() ? " error" : ''}`}
                        >
                            {printAttrForm(index, attribute)}
                            <div className="deleteBtn f cC p bR" onClick={()=>{updateAttributeForm("remove", index)}}>
                                <DeleteForeverIcon/>
                            </div>
                        </div>
                        {renderChildAttributes()}
                        <div key={index + "AddOperator"} className="addOperator g">
                            <div className="f g bR p" onClick={()=>{selectAttribute(attribute?.attr, index)}}>
                                <div className="f cC">
                                    <AddIcon/>
                                </div>
                                <div className="f cL bold e lH">
                                    Add Requirement
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLastIndex &&
                        <AddRequirementBlock
                            selectedAttributes={selectedAttributes}
                            index={index}
                        />
                    }
                </React.Fragment>
            );
        });
    }

    function buildQuery() {
        let query = '';
        let openBracket = false;

        if(!attributeForm || !attributeForm?.length){
            return;
        }

        const attributeFormWithOriginalKeys = attributeForm?.map((attribute, index) => ({
            originalIndex: index, // Add the original index to each element
            ...attribute, // Copy the rest of the attributes
        }));

        attributeFormWithOriginalKeys.sort((a, b) => a.groupID - b.groupID);
        attributeFormWithOriginalKeys.map((attribute) => {
            const parentKeys = attributeFormWithOriginalKeys.filter((attr) => {
                const currAttr = attr;
                const currAttrCriterion = Object?.keys(currAttr?.criterion)?.[0]
                const currAttrCriterionValue = currAttr?.criterion[currAttrCriterion];
                const isAttrInBetween = currAttrCriterion === "in_between";
                const inBetweenCatch = isAttrInBetween && (currAttrCriterionValue?.startDate && currAttrCriterionValue?.endDate);

                return currAttr?.relativeKey === undefined &&
                !currAttr?.inactive &&
                (currAttr?.formType === "generatedList" ?
                    currAttrCriterionValue !== undefined
                :
                (currAttrCriterionValue !== undefined && currAttrCriterionValue !== '') && (isAttrInBetween ? inBetweenCatch : true));
            });

            const firstParentKey = attribute === parentKeys?.[0];

            const index = attribute.originalIndex;

            const currentIndex = parentKeys.findIndex(item => item.originalIndex === index);
            const nextGroup = parentKeys[currentIndex + 1];

            const baseCriteria = attributeForm?.[index];
            const criterion = Object.keys(baseCriteria.criterion)[0];
            const criterionValue = baseCriteria?.criterion?.[criterion];

            if (baseCriteria?.relativeKey !== undefined || !baseCriteria?.attr ||
                baseCriteria?.inactive === true || !criterion || (baseCriteria?.formType === "generatedList" && criterionValue === undefined ) ||
                (baseCriteria?.formType !== "generatedList" && (criterionValue === undefined || criterionValue === '')) ||
                (criterion === "in_between" && (!criterionValue?.startDate || !criterionValue?.endDate)))
            {
                return null;
            }else{
                if(nextGroup?.groupOperator === "AND" && !openBracket && currentIndex === 0){
                    query += "(";
                    openBracket = true;
                }

                query += firstParentKey ? "(" : ` ${baseCriteria?.groupOperator} (`;
                if(nextGroup?.groupOperator === "AND" && !openBracket && currentIndex > 0){
                    query += "(";
                    openBracket = true;
                }

                if(criterion === "in_between"){
                    query += baseCriteria?.attr + " " + criterion + " '" + criterionValue?.startDate + "' to '" + criterionValue?.endDate + "'";
                }else{
                    query += baseCriteria?.attr + " " + criterion + " '" + criterionValue + "'";
                }
            }

            const childAttributes = attributeForm.filter(
              (childAttribute) => childAttribute.relativeKey === index
            );

            if (childAttributes.length > 0) {

                attributeForm.map((childAttribute, childIndex) => {
                    const siblingCriteria = attributeForm?.[childIndex];
                    const siblingCriterion = Object.keys(siblingCriteria?.criterion)[0];
                    const siblingCriterionValue = siblingCriteria?.criterion?.[siblingCriterion];

                    if(!siblingCriteria || !siblingCriteria?.attr ||siblingCriteria?.inactive || (siblingCriteria?.formType === "generatedList" && siblingCriterionValue === undefined) ||
                        (siblingCriteria?.formType !== "generatedList" && (siblingCriterionValue === undefined || siblingCriterionValue === '')) ||
                        siblingCriterion === "in_between" && (!siblingCriterionValue?.startDate || !siblingCriterionValue?.endDate))
                    {
                        return null;
                    }

                    if (childAttribute?.relativeKey === index) {
                        query += " " + baseCriteria?.inlineOperator + " ";
                        if(siblingCriterion === "in_between"){
                            query += siblingCriteria?.attr + " " + siblingCriterion + " '" + siblingCriterionValue.startDate + "' to '" + siblingCriterionValue.endDate + "'";
                        }else{
                            query += siblingCriteria?.attr + " " + siblingCriterion + " '" + siblingCriterionValue + "'";
                        }
                    }
                }
            )}

            query += ")";
            if(nextGroup?.groupOperator !== "AND" && openBracket || !nextGroup?.groupOperator && openBracket){
                query += ")";
                openBracket = false;
            }
        });

        if(openBracket){
            query += ")";
            openBracket = false; 
        }

        return query;
    }


    const saveEligibility = () => {
        if (!selectedReport) return false;
        if (selectedReport?.recordID) {
            const index = allReports.findIndex(
                (report) => report?.recordID === selectedReport?.recordID
            );

            return index !== -1 && !reporting?.functions?.isEqualWithoutAttributes(
                allReports[index], selectedReport, [
                    "list",
                    "totalFound",
                    "lastRecordID",
                    "lastPageIndex",
                    "criteria.current",
                    "criteria.subExisting",
                    "columns.*.frozen",
                    "columns.*.width",
                    "columns.*.join",
                    "subReport",
                    "sortedListResults",
                    "renderedList",
                    "loaded",
                    "derivedList",
                    "search",
                    "sorting",
                    "filteredList",
                ]);
        }

        return selectedReport?.query && selectedReport?.columns?.length > 0 && selectedReport?.details?.name;
    };

    function queryTopBar(){
        return (
            <div className="subHeader bold g f cC fC">
                <div className="f cC g fR">
                    <div className="gC2">
                        Query
                    </div>
                    <div className="f cR g">
                        <div
                            className={`btnWIcon bR f cC nS s${buildQuery(attributeForm) ? " p" : " inactive"}`}
                            onClick={() => buildQuery(attributeForm) && setShowReadout(!showReadout)}
                        >
                            {showReadout && buildQuery(attributeForm) ? "Hide" : "Show"} Readout
                            {showReadout ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                    </div>
                </div>
                {buildQuery(attributeForm) && showReadout && ["requirements", "grouping"].includes(currentView) &&
                    <div className="queryStatement f gR2 bR dP oA tO">
                        {friendlyQuery(queryArray)}
                    </div>
                }
            </div>
        )
    }
    function friendlyQuery(queryArray) {
        if (!queryArray) {
            return '';
        }
    
        const operationsMap = {
            'match': 'is',
            'not_match': 'is not',
            'contain': 'contains',
            'not_contain': 'does not contain',
            'blank': 'is blank',
            'not_blank': 'is not blank',
            'greater_than': 'is greater than',
            'less_than': 'is less than',
            'before': 'is before',
            'after': 'is after',
            'in_between': 'is between',
            'on_or_before': 'is on or before',
            'on_or_after': 'is on or after'
        };

        function camelCaseToWords(str) {
            return str.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/^./, function (str) {
                return str?.toUpperCase();
            });
        }

        function processQuery(query, parentOperation = '') {
            const operation = Object.keys(query)[0];
            const value = query[operation];

            if (Array.isArray(value)) {
                // Process logical operators (AND/OR) that group sub-queries
                const subResults = value.map(subQuery => processQuery(subQuery, operation));
                const joinedSubResults = subResults.join(` ${operation} `);
                if (parentOperation && parentOperation !== operation) {
                    return `(${joinedSubResults})`;
                }
                return joinedSubResults;
            } else {
                // Process other operations (e.g., match, not_match)
                return Object.entries(value).map(([field, fieldValue]) => {
                    let formattedValue = fieldValue;

                    if (operation !== 'blank' && operation !== 'not_blank' && isNaN(Number(formattedValue))) {
                        if(attributeData[field]?.returnType === "currency"){
                            formattedValue = props?.session?.env?.functions?.convertIntToCurrency(formattedValue);
                        }else if(attributeData[field]?.returnType === "date"){
                            if(operationsMap[operation] === "is between"){
                                const [start, end] = formattedValue.split(" to ");
                                formattedValue = props?.session?.env?.functions?.reformatDate(start);
                                formattedValue += " to ";
                                formattedValue += props?.session?.env?.functions?.reformatDate(end);
                            }else{
                                formattedValue = props?.session?.env?.functions?.reformatDate(formattedValue);
                            }
                        }else{
                            if(formattedValue === ''){
                                formattedValue = `empty`;
                            }else{
                                formattedValue = `"${formattedValue}"`;
                            }
                        }
                    } else if (!isNaN(Number(formattedValue))) {
                        if(attributeData[field]?.returnType === "currency"){
                            formattedValue = props?.session?.env?.functions?.convertIntToCurrency(formattedValue);
                        }else if(attributeData[field]?.returnType === "date"){
                            if(operationsMap[operation] === "is between"){
                                const [start, end] = formattedValue.split(" to ");
                                formattedValue = props?.session?.env?.functions?.reformatDate(start);
                                formattedValue += " to ";
                                formattedValue += props?.session?.env?.functions?.reformatDate(end);
                            }else{
                                formattedValue = props?.session?.env?.functions?.reformatDate(formattedValue);
                            }
                        }else{
                            if(formattedValue === ''){
                                formattedValue = `empty`;
                            }else{
                                formattedValue = `"${formattedValue}"`;
                            }
                        }
                    }else{
                        return `${camelCaseToWords(attributeData?.[field]?.friendlyTerm)} ${operationsMap[operation]}`;
                    }
    
                    return `${camelCaseToWords(attributeData?.[field]?.friendlyTerm)} ${operationsMap[operation]} ${formattedValue}`;

                }).join(` ${operation} `);
            }
        }

        let returningQuery = processQuery(queryArray);

        return returningQuery === "Record ID is not blank" ? "Select All" : returningQuery;
    }

    async function createReport(uploadingReport){
        const paramVals = {
            branch: uploadingReport?.branch,
            columns: uploadingReport?.columns,
            criteria: uploadingReport?.criteria?.existing,
            description: uploadingReport?.details?.description,
            endDate: uploadingReport?.endDate,
            frequency: uploadingReport?.frequency,
            groupID: uploadingReport?.groupID,
            name: uploadingReport?.details?.name,
            query: uploadingReport?.query,
            recordID: isSystemReport ? undefined : uploadingReport?.recordID,
            selectAll: uploadingReport?.selectAll,
            startDate: uploadingReport?.startDate,
            stem: uploadingReport?.stem,
            system: uploadingReport?.system,
            userEditList: reportDetails?.editAccessList,
            userViewList: reportDetails?.viewAccessList,
            ...(isSystemReport && { displayType: reportDetails?.displayType }),
            ...(uploadingReport?.stem === "summary" && {
                referenceRecordID: uploadingReport?.referenceRecordID,
                referenceBranch: uploadingReport?.referenceBranch,
                referenceStem: uploadingReport?.referenceStem,
                displayType: uploadingReport?.details?.displayType,
                groupBy: uploadingReport?.criteria?.groupBy,
            })
        };
        
        try {
            const response = await props?.session?.env?.functions?.buildFetchRequest("user/report", paramVals);
            const resData = await response.json();

            if (response.status === 200) {
                const updatedReport = {...uploadingReport};
                updatedReport.recordID = resData?.report?.recordID;
                // updateReporting("selectedReport", updatedReport);
                session?.set(baseModule, "selectedReport", updatedReport);
                session?.env?.setOverlay();
                return resData?.report?.recordID;
            }
          } catch (error) {
                console.error("An error occurred:", error);
          }

        return null;
    }

    useEffect(() => {
        if(attrList?.current){
            attrList.current.scrollIntoView();
        }
    }, [currentView]);

    useEffect(() => {
        const filteredAndSortedKeys = Object.keys(attributeData)
        .filter(key => {
            const attribute = attributeData?.[key];

            if (!attribute || !(attribute?.stems || []).includes(selectedReport?.referenceStem ?? selectedReport?.stem)) {
                return false;
            }

            const isEmployeeOnly = attribute?.employeeOnly;
            const isEmployee = session?.user?.data?.accountData?.[props?.sessionUser?.accountID]?.teamList?.some(teamMember => teamMember.userID === session?.user?.data?.userID && teamMember?.isAbacusEmployee);

            return !isEmployeeOnly || (isEmployeeOnly && isEmployee);
        })
        .sort((a, b) => {
            const termA = attributeData?.[a]?.friendlyTerm?.toUpperCase() ?? '';
            const termB = attributeData?.[b]?.friendlyTerm?.toUpperCase() ?? '';
            return termA.localeCompare(termB);
        });

        setFriendlyTermsArray(filteredAndSortedKeys.map(key => {
            const attribute = attributeData?.[key];
            return attribute ? attribute?.friendlyTerm : key;
        }));

    }, [selectedReport?.stem, selectedReport?.referenceStem]);

    useEffect(() => {
        if(existingCriteriaData?.length > 0){
            let tempCriteriaValues = {};

            existingCriteriaData.forEach((item, index) => {
                for (const key in item.criterion) {
                    if (item.criterion.hasOwnProperty(key)) {
                        const criterionValue = item.criterion[key];
                        tempCriteriaValues[index] = {[key] : criterionValue}
                    }
                }
            });

            setCriteriaValues(tempCriteriaValues);
        }
    }, []);

    useEffect(() => {
        // updateReporting("selectedReport", "details", reportDetails);
        session?.set(baseModule, "selectedReport.details", reportDetails);
    }, [reportDetails]);

    useEffect(() =>{
        // updateReporting("selectedReport", "columns", columnForm);
        session?.set(baseModule, "selectedReport.columns", columnForm);
    }, [columnForm]);

    // function useDeepCompareEffect(callback, dependencies) {
    //     const currentDependenciesRef = useRef();

    //     if (!isEqual(currentDependenciesRef.current, dependencies)) {
    //         currentDependenciesRef.current = dependencies;
    //     }

    //     useEffect(callback, [currentDependenciesRef.current]);
    // }

    useEffect(() => {
        const lastElement = attributeForm[attributeForm?.length - 1];
        if ((lastElement && lastElement?.relativeKey === undefined) && (attributeForm?.length > attributeFormCopy?.length) && (requirementsBlock?.current)){
            const requirements = requirementsBlock?.current;
            requirements.scrollTop = requirements?.scrollHeight;
        }

        setAttributeFormCopy(attributeForm);
        // updateReporting("selectedReport.criteria", "existing", attributeForm);
        session?.set(baseModule, "selectedReport.criteria.existing", attributeForm);
        const query = buildQuery(attributeForm) ?? selectedReport?.query;
        // updateReporting("selectedReport", "query", query);

        if(query){
            session?.set(baseModule, "selectedReport.query", query);

            const items = props?.session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem];
            const filteredResults = reporting?.functions?.filterItemsWithQuery(query, items);

            if(reportType !== "generated"){
                // updateReporting("selectedReport", "list", filteredResults);
                session?.set(baseModule, "selectedReport.list", filteredResults);
            }

            setQueryArray(reporting?.functions?.parse(query));
        }
    }, [attributeForm, selectedReport?.selectAll]);

    useEffect(() => {
        if(selectedReport?.referenceRecordID !== undefined){
            const existingCriteria = selectedReport?.criteria?.existing;
            let tempCriteriaValues = {};

            existingCriteria.forEach((item, index) => {
                for (const key in item.criterion) {
                    if (item.criterion.hasOwnProperty(key)) {
                        const criterionValue = item.criterion[key];
                        tempCriteriaValues[index] = {[key] : criterionValue}
                    }
                }
            });

            setAttributeForm(existingCriteria);
            setCriteriaValues(tempCriteriaValues);

            if(!groupColumnsForm?.length){
                updateAttributeForm("add", {
                    "formType": undefined,
                    "groupOperator": "groupBy",
                    "friendlyTerm": undefined,
                    "attr": undefined,
                    "inactive": undefined,
                    "groupID" : 0,
                    "relativeKey" : 0,
                    "id" : generateUniqueId()
                }, "initialize");
            }
        }else{
            setGroupColumnsForm([]);
        }
    }, [selectedReport?.referenceRecordID]);

    useEffect(() => {
        if(isSummaryView){
            // updateReporting("selectedReport.criteria", "groupBy", groupColumnsForm);
            session?.set(baseModule, "selectedReport.criteria.groupBy", groupColumnsForm);
        }
    }, [groupColumnsForm]);

    useEffect(() => {
        // console.log(columnForm);
    }, [columnForm]); // Ensure columnForm is included in the dependency array

    useEffect(() => {
        // console.log(selectedReport?.criteria);
    }, [selectedReport?.criteria]); // Ensure columnForm is included in the dependency array

    return (
        <React.Fragment key={"reportBuilder"}>
            <div className="tabBar f cL s e">
                <div className="tabs g cC fR f nS fC">
                    <div className="f cC fR g tabCount5">
                        {(!selectedReport?.recordID || !existingReportType) &&
                            <div
                                onClick={()=>{setCurrentView("reportType");}}
                                className={`tab g f cC fC p${currentView === "reportType" ? " active" : ''}`}
                            >
                                <div className="prompt g f cC pR fR gR2">
                                    <div className="s f cC g oH">
                                        <div className="f cC gCW">
                                            Report Type
                                        </div>
                                    </div>
                                    <div className="f cC icon">
                                        <SummarizeTwoToneIcon/>
                                    </div>
                                </div>
                                <div className="status f gR3">
                                </div>
                            </div>
                        }
                        <div
                            onClick={()=>{existingReportType && setCurrentView("requirements")}}
                            className={`tab g f cC fC${existingReportType ? " p" : " inactive"}${currentView === "requirements" ? " active" : ''}`}
                        >
                            <div className="prompt g f cC pR fR gR2">
                                <div className="s f cC g oH">
                                    <div className="f cC gCW">
                                        Filters
                                    </div>
                                </div>
                                <div className="f cC icon">
                                    <FactCheckTwoToneIcon/>
                                </div>
                            </div>
                            <div className="status f gR3">
                            </div>
                        </div>
                        {isSummaryView &&
                            <div
                                onClick={()=>{(selectedReport?.query || selectedReport?.recordID) && setCurrentView("grouping")}}
                                className={`tab g f cC fC${
                                        (existingReportType && selectedReport?.query) ? " p" : " inactive"}${
                                            currentView === "grouping" ? " active" : ''}`
                                }
                            >
                                <div className="prompt g f cC pR fR gR2">
                                    <div className="s f cC g oH">
                                        <div className="f cC gCW">
                                            Grouping
                                        </div>
                                    </div>
                                    <div className="f cC icon">
                                        <WorkspacesTwoToneIcon/>
                                    </div>
                                </div>
                                <div className="status f gR3">
                                </div>
                            </div>
                        }
                        <div
                            onClick={()=>{(isSummaryView ?
                                    selectedReport?.query && selectedReport?.columns?.length && (groupColumnsForm[0]?.attr && groupColumnsForm[0]?.combineBy)
                                :
                                    selectedReport?.query) && setCurrentView("properties")}
                            }
                            className={`tab g f cC fC${(isSummaryView ?
                                selectedReport?.query && selectedReport?.columns?.length && (groupColumnsForm[0]?.attr && groupColumnsForm[0]?.combineBy)
                            :
                                selectedReport?.query) ? " p" : " inactive"}${
                                    currentView === "properties" ? " active" : ''}`
                            }
                        >
                            <div className="prompt g f cC pR fR gR2">
                                <div className="s f cC g oH">
                                    <div className="f cC gCW">
                                        Columns & Properties
                                    </div>
                                </div>
                                <div className="f cC icon">
                                    <SettingsApplicationsIcon/>
                                </div>
                            </div>
                            <div className="status f gR3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`criteriaBoard g f fR oH s e${currentAlteringAttr ? " activeAlteration" : ''} ${currentView}${attributeReady ? '' : " inactive"}`}
                onClick={()=>{
                    setSelectedOperatorMenu();
                    setSelectedListMenu();
                }}
            >
                {currentView === "reportType" ?
                    <div className="reportTypeSelect g cC fC">
                        <div className="f bC">
                            <div className="subHeader bold">
                                Select a Report Type
                            </div>
                        </div>
                        <div className="tC">
                            {printReportCategories()}
                        </div>
                    </div>
                :
                    attributeReady ?
                        <React.Fragment key={"columnsAndProperties"}>
                            {currentView !== "grouping" &&
                                <div className="leftPanel g">
                                    <div className="listBoard f oH g">
                                        <div className="columnLabels g f cC bold dP fR">
                                            {
                                                currentView === "properties" ?
                                                    "Available Columns" :
                                                    `${reportCategory.charAt(0).toUpperCase() + reportCategory.slice(1)} Attributes`
                                                }
                                        </div>
                                        <div className={`body f oH bR${currentAlteringAttr ? " actionReady" : ''}`}>
                                            <div className="content wrap f tO alt">
                                                <InteractiveBar
                                                    session={session}
                                                    attrList={attrList}
                                                    columnForm={{data : columnForm, set : setColumnForm}}
                                                    attributeReady={attributeReady}
                                                    attributeForm={{data : attributeForm, set : setAttributeForm}}
                                                    currentAlteringAttr={currentAlteringAttr}
                                                    generateUniqueId={generateUniqueId}
                                                    attributeData={attributeData}
                                                    groupColumnsForm={{data : groupColumnsForm, set : setGroupColumnsForm}}
                                                    currentView={currentView}
                                                    formDropdownHandler={{data : formDropdownHandler, set : setFormDropdownHandler}}
                                                    friendlyTermsArray={friendlyTermsArray}
                                                    selectAttribute={selectAttribute}
                                                    reportCategoryObj={reportCategoryObj}
                                                    selectedFolder={{data : selectedFolder, set : setSelectedFolder}}
                                                    resetDropdowns={resetDropdowns}
                                                    reportingView={{data : reportingView, set : setReportingView }}
                                                    addOrRemoveColumns={addOrRemoveColumns}
                                                    alterCustomColumn={alterCustomColumn}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {currentView === "properties" &&
                                        <div className="columns listBoard bR oA g">
                                            <div className="f cC bold">
                                                Selected Columns
                                            </div>
                                            {columnForm?.length === 0 ? 
                                                <div className="loading empty f bR cC g">
                                                    <div className="f cC e">
                                                        {isSummaryView ?
                                                            <div className="f cC">
                                                                Missing Group By and Custom Columns
                                                            </div>
                                                        :
                                                            <div className="f cC">
                                                                <ChevronLeftIcon/>
                                                                Click to Add Columns
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <div className="body f oH bR">
                                                    <div className={`content wrap f tO${isSummaryView ? " summary dP" : ''}`}>
                                                        <div key="defaultColumns" className={`options g fC dP tO${
                                                            !columnForm?.filter(column => (!column?.custom))?.length ? " emptyList" : ''}`}
                                                        >
                                                            {printColumnForm(false, "Grouped Columns")}
                                                        </div>
                                                        {isSummaryView &&
                                                            <React.Fragment key="summaryColumns">
                                                                <div className="dividerBlock cC">
                                                                    <div className="vDivider">
                                                                    </div>
                                                                </div>
                                                                <div className={`options g fC dP tO${
                                                                    !columnForm?.filter(column => (column?.custom))?.length ? " emptyList" : ''}`}
                                                                >
                                                                    {printColumnForm(true, "Summary Columns")}
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            <div className={`criteriaBuilder g oH zTPC${showReadout && ["requirements", "grouping"].includes(currentView) ? " dG" : ''}`}>
                                {currentView === "requirements" &&
                                    <>
                                        {queryTopBar()}
                                        {(attributeForm.some(obj => !obj.inactive) && !selectedReport?.selectAll) ?
                                            (
                                                <div
                                                    key="requirementsBlock"
                                                    ref={requirementsBlock}
                                                    onScroll={null}
                                                    className="requirements bR oA"
                                                >
                                                    {/* {!Object?.keys(criteriaValues)?.length && selectedReport?.recordID && reportType !== "generated" ? */}
                                                    {!Object?.keys(attributeForm)?.length && selectedReport?.recordID && reportType !== "generated" ?
                                                        <div className="loading f bR cC g">
                                                            <div className="f cC" onClick={()=>{console.log(criteriaValues)}}>
                                                                <CircularProgress color="inherit"/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div key="attrForm" className="g fC">
                                                            {printAttributeForm()}
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        :
                                            selectedReport?.selectAll ?
                                                <div key="showingAllScreen" className="empty f bR cC g">
                                                    <div className="f cC">
                                                        <ManageSearchIcon/>
                                                        <div className="f cC">
                                                            Showing all existing rows
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <div key="loadingScreen" className="loading empty f bR cC g">
                                                    <div className="f cC">
                                                        <ChevronLeftIcon/>
                                                        <div className="f cC">
                                                        {isSummaryView && selectedReport?.referenceRecordID === undefined ?
                                                            "Select existing report or create your own set of filters"
                                                        :
                                                            "Click to start adding filters and requirements"
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                                {currentView === "grouping" &&
                                    <>
                                        {queryTopBar()}
                                        <div
                                            key="requirementsGroupBlock"
                                            ref={requirementsBlock}
                                            onScroll={null}
                                            className={`requirements bR oA`}
                                        >
                                            {printGroupByForm()}
                                        </div>
                                    </>
                                }
                                {currentView === "properties" &&
                                    <PropertiesView
                                        session={session}
                                        buildQuery={buildQuery}
                                        setShowReadoutAlt={setShowReadoutAlt}
                                        showReadoutAlt={showReadoutAlt}
                                        attributeForm={attributeForm}
                                        friendlyQuery={friendlyQuery}
                                        reportDetails={{data : reportDetails, set : setReportDetails}}
                                        queryArray={queryArray}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    :
                        (
                            (currentView === "requirements" || currentView === "properties") &&
                            <div className="loading f bR cC g">
                                <div className="f cC">
                                    <CircularProgress color="inherit"/>
                                </div>
                            </div>
                        )
                    }
            </div>  
            <div className="actionBar f g cC fR dP">
                {
                    <div className="f cL">
                        {isSystemReport &&
                            <div className="systemInfo g cL dG s">
                                <WysiwygIcon/>
                                <div className="cL">
                                    System Report
                                </div>
                            </div>
                        // :
                        //     <div className="reportDelete g cL dG p bR dP"
                        //         onClick={()=>{

                        //         }}
                        //     >
                        //         <DeleteForeverIcon/>
                        //         <div className="cL">
                        //             Delete Report
                        //         </div>
                        //     </div>
                        }
                    </div>
                }
                <div className="f cC gC2">
                    {buildQuery(attributeForm) && (selectedReport?.list?.length === 1 || selectedReport?.totalFound === 1) && selectedReport?.query ?
                        "1 record matched"
                    :
                        (selectedReport?.list?.length || selectedReport?.totalFound) && selectedReport?.query ?
                            (selectedReport?.totalFound?.toLocaleString() ?? selectedReport?.list?.length?.toLocaleString()) + " records matched"
                        :
                            "Pending criteria"
                    }
                </div>
                <div
                    className={`btnWIcon g cC f bR s e p oH gC3${saveEligibility() ? '' : " inactive"}`}
                    onClick={() => {
                        if (saveEligibility()) {
                            const handleCreateReport = async () => {
                                const tempNewReport = { ...selectedReport };
                                const name = tempNewReport?.details?.name;
                                const count = reporting?.data?.allReports.reduce((c, r) => 
                                    c + (r.details?.name && r.details.name.startsWith(name) && r.recordID !== tempNewReport?.recordID ? 1 : 0), 0
                                );                        
                                tempNewReport.details.name = count > 0 ? `${name}${isSystemReport ? " Copy" : ''} (${count})` : name;
                        
                                tempNewReport.groupID = isSystemReport ? 0 : tempNewReport?.groupID;
                                tempNewReport.selectAll = friendlyQuery(queryArray) === "Select All" ? true : tempNewReport?.selectAll;
                        
                                if (!isSystemReport && tempNewReport?.recordID) {
                                    createReport(tempNewReport);
                                } else {
                                    tempNewReport.recordID = await createReport(tempNewReport);
                                }

                                const index = reporting?.data?.allReports.findIndex(report => report.recordID === tempNewReport.recordID);
                                const currIndex = index !== -1 ? index : props?.session?.reporting?.data?.allReports?.length;

                                // updateReporting("allReports", currIndex, tempNewReport);
                                session?.set(baseModule, `allReports.${currIndex}`, tempNewReport);
                            };

                            // Execute the async function to handle report creation
                            handleCreateReport();
                        }
                    }}
                >
                    <div className="f cC bold">
                        {isSystemReport ? "Save As" : "Save"}
                    </div>
                    <div className="f cC"> {
                        isSystemReport ?
                            <SaveAsIcon/>
                        :
                            <SaveRoundedIcon/>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default CriteriaBoard;
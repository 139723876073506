import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import { CircularProgress } from '@mui/material';

import Dropdown from '../Dropdown.js';

function ReportActionBar(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const selectedReport = props?.selectedReport;

    const importedList = props?.importedList;
    const requestingBatch = props?.requestingBatch;
    // const currentStructure = props.importedList ? importedList : selectedReport;
    const viewableReport = props?.importedList ? true : (selectedReport?.query && selectedReport?.columns?.length > 0);
    const floater = props?.floater?.data;
    // const pagination = props?.pagination?.data;
    // const setPagination = props?.pagination?.set;
    // const search = props?.search?.data;
    const paginationRef = useRef(null);

    const currentStorage = JSON.parse(localStorage.getItem("storage")) || {};

    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);

    const paginationFriendly = selectedReport?.stem !== "summary" || selectedReport?.subReport?.list;

    const paginationClick = (value) => {
        if (currentStorage?.reporting?.rowMax !== value) {
            currentStorage.reporting = {
                ...currentStorage.reporting,
                rowMax: value,
            };

            props?.dropdowns?.set("pagination", {"rowMax" : value});
            // setPagination((prev) => ({
            //     ...prev,
            //     rowMax: value,
            // }));

            localStorage.setItem("storage", JSON.stringify(currentStorage));

            if (reportType === "generated") {
                reporting?.functions?.requestResultBlock(
                    {
                        newLimit: value,
                        newOffset: 1,
                    },
                    props?.requestObject
                );
            }
        }
    };

    function paginationHandler() {
        const totalFound = selectedReport?.totalFound;

        const totalRecords =
            (reportType !== "generated" && selectedReport?.search) ?
            selectedReport?.sortedListResults?.length :
            selectedReport?.subReport?.list?.length ?? selectedReport?.totalFound ?? selectedReport?.list?.length;

        if(totalFound === 0 || !totalFound || totalFound < props?.dropdowns?.data?.pagination?.rowMax || !selectedReport?.query){
            return;
        }

        const totalPages = Math.ceil(totalFound / props?.dropdowns?.data?.pagination?.rowMax);

        const handlePageChange = (newOffset) => {
            session?.set(props?.baseModule, `${props?.remotePath ?? ''}selectedReport.lastPageIndex`, newOffset)
            if (newOffset >= 1 && newOffset <= totalPages) {
                // setPagination((prev) => ({
                //     ...prev,
                //     index: newOffset,
                // }));
                props?.dropdowns?.set("pagination", {"index" : newOffset});
            }

            if(reportType === "generated"){
                reporting?.functions?.requestResultBlock({newOffset : newOffset}, props?.requestObject);
            }
        };

        const condensed = paginationRef?.current?.offsetWidth < 250;
        const maxVisiblePages = condensed ? 3 : 5;
        const halfMaxVisiblePages = Math?.floor(maxVisiblePages / 2);
        const firstPage = Math?.max(1, props?.dropdowns?.data?.pagination?.index - halfMaxVisiblePages);
        const lastPage = Math?.min(totalPages, firstPage + maxVisiblePages - 1);

        const showFirstPageButton = props?.dropdowns?.data?.pagination?.index > halfMaxVisiblePages + 1;
        const showLastPageButton = totalPages > maxVisiblePages && lastPage < totalPages;

        const numColumns = maxVisiblePages;
        const paginationCols = { gridTemplateColumns: `repeat(${numColumns + 6}, 1fr)` };        

        return (
            <div
                ref={paginationRef}
                className={`pagination f g cR nS lH fR bold${requestingBatch ? " inactive" : ''}`}
                style={paginationCols}
            >
                {showFirstPageButton ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(1)}
                    >
                        <FirstPageIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {props?.dropdowns?.data?.pagination?.index !== 1 ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(props?.dropdowns?.data?.pagination.index - 1)}
                    >
                        <NavigateBeforeIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {showFirstPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => (
                    <div
                        key={firstPage + index}
                        onClick={() => handlePageChange(firstPage + index)}
                        className={`btn f cC bR p${props?.dropdowns?.data?.pagination?.index === firstPage + index ? ' active' : ''}`}
                    >
                        {firstPage + index}
                    </div>
                ))}

                {showLastPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {totalPages !== props?.dropdowns?.data?.pagination?.index &&
                    <div
                        className={`btn f cC bR p gC${condensed ? '8' : '10'}`}
                        onClick={() => handlePageChange(props?.dropdowns?.data?.pagination?.index + 1)}
                    >
                        <NavigateNextIcon/>
                    </div>
                }

                {showLastPageButton && (
                    <div
                    className={`btn f cC bR p gC${condensed ? '9' : '11'}`}
                        onClick={() => handlePageChange(totalPages)}
                    >
                        <LastPageIcon/>
                    </div>
                )}
            </div>
        );
    }

    function recordsMatched() {
        const list = selectedReport?.derivedList ?? (reportType !== "generated" && selectedReport?.search) ? selectedReport?.sortedListResults : selectedReport?.subReport?.list ?? selectedReport?.list;
        const totalFound = selectedReport?.totalFound || selectedReport?.list?.length;
        if (totalFound === 1 && selectedReport?.query){
            return viewType === "full" ? "1 record matched" : "1 record";
        } else if ((totalFound || totalFound) && selectedReport?.query) {
            return `${totalFound?.toLocaleString() ?? totalFound?.toLocaleString()} records ${viewType === "full" ? "matched" : ""}`;
        } else {
            if(!selectedReport?.recordID){
                return ''
            }else{
                return selectedReport?.loaded && !selectedReport?.list?.length > 0 ? "No records to show" : selectedReport?.recordID ? "Loading records..." : '';
            }
        }
    }

    return (
        <>
          {viewType === "mobile" && floater?.bottom && paginationFriendly ? (
            <div className="floatingDetailBar bR g s e cC dG">
                {selectedReport?.details?.scrollType === "pagination" && (
                    <>
                        <Dropdown
                            setShowMenu={(input) => {
                                // setPagination((prev) => ({
                                //     ...prev,
                                //     showMenu: input ?? !prev.showMenu,
                                // }));
                                props?.dropdowns?.set("pagination", {"showMenu" : input ?? !props?.dropdowns?.data?.pagination?.showMenu});
                            }}
                            // showMenu={pagination?.showMenu}
                            showMenu={props?.dropdowns?.data?.pagination?.showMenu}
                            default={currentStorage?.reporting?.rowMax ?? 100}
                            list={[100, 250, 500, 1000]}
                            onClick={(value) => paginationClick(value)}
                            inactive={!viewableReport || !selectedReport?.list?.length}
                            leadingPrompt={"Show"}
                            reset={props?.resetDropdowns}
                        />
                        {paginationHandler()}
                    </>
                )}
            </div>
          ) : (
            <div className={`detailBar bR g fR dG`} style={{ zIndex: selectedReport?.columns?.length || 3 }}>
                {selectedReport?.details?.scrollType === "pagination" && paginationFriendly && (
                    <div className="section left g dG">
                        <Dropdown
                            setShowMenu={(input) => {
                                // setPagination((prev) => ({
                                //     ...prev,
                                //     showMenu: input ?? !prev.showMenu,
                                // }));
                                props?.dropdowns?.set("pagination", {"showMenu" : input ?? !props?.dropdowns?.data?.pagination?.showMenu});
                            }}
                            // showMenu={pagination?.showMenu}
                            showMenu={props?.dropdowns?.data?.pagination?.showMenu}
                            default={currentStorage?.reporting?.rowMax ?? 100}
                            list={[100, 250, 500, 1000]}
                            onClick={(value) => paginationClick(value)}
                            inactive={!viewableReport || !selectedReport?.list?.length}
                            leadingPrompt={"Show"}
                            reset={props?.resetDropdowns}
                        />
                    </div>
                )}
                {viewType !== "mobile" && (
                    <div className="f cC lH gC2">
                        {recordsMatched()}
                    </div>
                )}
                {selectedReport?.details?.scrollType === "pagination" && paginationFriendly && paginationHandler()}
            </div>
          )}
        </>
    );
}

export default ReportActionBar;
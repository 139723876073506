import React from 'react';
import { useState, useEffect, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function StringInput(props) {
    let value = props?.value;

    if (props?.textData?.formType === "int" && props?.textData?.returnType === "currency") {
        const formatCurrencyValue = value => !isNaN(parseFloat(value)) ? `$${parseFloat(value).toLocaleString()}` : '';
        value = formatCurrencyValue(value);
    }

    if (props?.textData?.formType === "date") {
        const formatDateValue = value => {
            if (!value) return '';
            const [year, month, day] = value.split('-');
            return `${parseInt(month)}/${parseInt(day)}/${year}`;
        };
        value = formatDateValue(value);
    }

    const onClickHandler = () => {
        if (typeof props?.onClick === 'function') {
            props.onClick(); // Invoke the passed onClick function if it exists
        }
    };

    return (
        <div
            key={props?.name}
            className="stringInput g cL f fC"
        >
            {props?.value && props?.placeholder && (
                <div className="placeholder">
                    {props?.placeholder}
                </div>
            )}
            <input 
                className="input gR2"
                placeholder={props?.placeholder}
                name={props.name}
                readOnly={props?.readOnly ?? true}
                value={value}
                type={props?.type}
                autoComplete={props?.autoComplete ?? "off"}
                onClick={onClickHandler}
                title={value === '' ? props?.placeholder : value}
                onChange={props?.onChange}
            />
        </div>
    );
}

export default StringInput;
import React, { useEffect, useState } from 'react';

function AnalyticVars(props){

  const [data, setData] = useState({
    selectedReport : {
      branch : undefined,
      columns : undefined,
      stem : undefined,
      columns : [],
      name : undefined,
      ID : undefined,
      criteria : {
        current : {
          attr : undefined
        },
        existing : []
      },
      details : {
        name : undefined,
        description : undefined,
        shareType : undefined,
        shareList : undefined, 
        reoccurType  : false,
        scrollType : "pagination",
        editAccessList : ["owner"],
        viewAccessList : ["owner"],
      },
      query : "",
      showAll : false,
    },
    allReports : undefined,
    reset : {
      branch : undefined,
      columns : undefined,
      stem : undefined,
      columns : [],
      name : undefined,
      ID : undefined,
      criteria : {
        current : {
          attr : undefined
        },
        existing : []
      },
      details : {
        name : undefined,
        description : undefined,
        shareType : undefined,
        shareList : undefined, 
        reoccurType  : false,
        scrollType : "pagination",
        editAccessList : ["owner"],
        viewAccessList : ["owner"],
      },
      query : "",
      showAll : false,
    },
    sorting : []
  });

  const ops = {
    "match": (a, b) => a && b && String(a).toLowerCase() === String(b).toLowerCase(),
    "not_match": (a, b) => a && b && String(a).toLowerCase() !== String(b).toLowerCase(),
    "contain": (a, b) => a && b && String(a).toLowerCase().includes(String(b).toLowerCase()),
    "not_contain": (a, b) => a && b && !String(a).toLowerCase().includes(String(b).toLowerCase()),
    "blank": (a, b) => (b === 'true' ? !a : !!a),
    "not_blank": (a, b) => (b === 'true' ? !!a : !a),
    "greater_than": (a, b) => Number(a) > Number(b),
    "less_than": (a, b) => Number(a) < Number(b),
    "before": (a, b) => new Date(a) < new Date(b),
    "after": (a, b) => new Date(a) > new Date(b),
    "in_between": (a, b) => {
      const [start, end] = b.split(" to ");
      const dateA = new Date(a);
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0); // Set to start of the day
      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999); // Set to end of the day
  
      return dateA >= startDate && dateA <= endDate;
  },
    "on_or_before": (a, b) => new Date(a) <= new Date(b),
    "on_or_after": (a, b) => new Date(a) >= new Date(b),
  };

  const updateReport = (path, attr, value) => {
    setData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy
      const pathSegments = path.split('.');
      let current = newState;

      for (const segment of pathSegments) {

        if (segment.includes('[')) {
          // Handle array access within the path
          const [key, indexStr] = segment.split('[');
          const index = parseInt(indexStr.replace(']', ''), 10);

          if (!current[key]) {
            // Initialize an array if it doesn't exist
            current[key] = [];
          }

          if (!current[key][index]) {
            // Initialize an object within the array if it doesn't exist
            current[key][index] = {};
          }

          current = current[key][index];
        } else {
          // Handle regular object properties within the path
          if (!current[segment]) {
            // Initialize an object if it doesn't exist
            current[segment] = {};
          }

          current = current[segment];
        }
      }

      if (attr === null) {
        // marketValue, assignedUsersList, issueDate, accountManager, caseProcessor
        // Update the entire path's value to the new value
        newState[pathSegments[0]] = value;
      } else if (typeof attr === 'object' && !Array.isArray(attr)) {
        Object.assign(current, attr);
      } else {
        if(Array.isArray(attr)){
          newState[pathSegments[0]] = attr;
        }else{
          current[attr] = value;
        }
        // Update only the specified attribute
      }

      return newState;
    });
  };

  const updateSelectedReport = (attr, value) => {
    setData((prevState) => ({
      ...prevState,
      selectedReport: {
        ...prevState.selectedReport,
        [attr]: value,
      },
    }));
  }

  function downloadReport(selectedReport, sortedListResults){
    const columnConfig = selectedReport?.columns;
    const visibleColumns = columnConfig.map((column) => column.columnName);

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += visibleColumns.map((columnName) => {
        const columnConfigItem = columnConfig.find((config) => config.columnName === columnName);
        return columnConfigItem ? columnConfigItem.friendlyTerm : columnName;
    }).join(",") + "\n";

    sortedListResults.forEach((row) => {
        const values = visibleColumns.map((columnName) => row[columnName] || '');
        csvContent += values.join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", selectedReport?.details?.name);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  function evaluateQuery(query, item) {
    // Handle logical OR operator
    if (query.OR) {
        return query.OR.some(subQuery => evaluateQuery(subQuery, item));
    }
    
    // Handle logical AND operator
    if (query.AND) {
        return query.AND.every(subQuery => evaluateQuery(subQuery, item));
    }

    // Handle comparison operators
    for (let operator in query) {
        if (ops[operator]) {
            for (let field in query[operator]) {
                const queryValue = query[operator][field];
                const itemValue = item[field];

                // Handle the case where the query value is an empty string
                if (queryValue === '') {
                    if (operator === 'match' && itemValue !== '') {
                        return false; // If 'match' and not empty, return false.
                    }
                    if (operator === 'not_match' && itemValue === '') {
                        return false; // If 'not_match' and empty, return false.
                    }
                } else {
                    // Handle other comparison operators
                    if (!ops[operator](itemValue, queryValue)) {
                        return false; // If one comparison fails, return false for the current operator.
                    }
                }
            }
        }
    }

    // If we reach this point, then all checks passed for the current query/operator.
    return true;
  }

  function filterItemsWithQuery(query, items) {
    query = parse(query);
    return items?.filter(item => evaluateQuery(query, item));
  }


  function parse(queryStr) {

    let idx = 0;
    const tokens = [];

    function getNextToken(queryStr) {
      const match = /^(\s*(\()|(\))|(AND)|(OR)|(\w+ (match|not_match|contain|not_contain|blank|not_blank|greater_than|less_than|before|after|in_between|on_or_before|on_or_after) '.*?'( to '.*?')?)\s*)/.exec(queryStr);
      if (match) {
        return match[0].trim();
      } else {
        return null;
      }
    }

    function tokenize(queryStr) {
        while (queryStr.length > 0) {
            const token = getNextToken(queryStr);
            if (token) {
                tokens.push(token);
                queryStr = queryStr.substring(token.length).trim();
            } else {
                throw new Error("Invalid token encountered");
            }
        }
    }
    
    function parseExpression() {
        const termResult = parseTerm();
        if (tokens[idx] === "OR") {
            idx++;
            return { "OR": [termResult, parseExpression()] };
        }
        return termResult;
    }
    
    function parseTerm() {
        const factorResult = parseFactor();
        if (tokens[idx] === "AND") {
            idx++;
            return { "AND": [factorResult, parseTerm()] };
        }
        return factorResult;
    }
    
    function parseFactor() {
        if (tokens[idx] === "(") {
            idx++;
            const expr = parseExpression();
            if (tokens[idx] !== ")") {
                throw new Error("Expected closing parenthesis");
            }
            idx++;
            return expr;
        }
        return parseCondition();
    }
  
    function parseCondition() {
        const [field, operator, ...valueParts] = tokens[idx]?.split(" ");
        idx++;
        const value = valueParts.join(" ").replace(/'/g, "");
        return { [operator]: { [field]: value } };
    }

    tokenize(queryStr);
    const result = parseExpression();
    if (idx !== tokens.length) {
        throw new Error("Unexpected tokens at the end");
    }
    return result;
  }

  const functions = {
    filterItemsWithQuery,
    parse,
    updateSelectedReport,
    downloadReport
  }

  const analyticsVars = {
    data,
    setData : updateReport,
    functions,
  }

  return analyticsVars;
};

export default AnalyticVars;
import { useState, useEffect, useRef, useCallback } from 'react';

import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import GavelIcon from '@mui/icons-material/Gavel';

function TertiaryReportToggles(props){
    const session = props?.session;
    const baseData = props?.baseData;
    const baseModule = props?.baseModule;
    const reporting = session?.reporting;
    const reportingView = props?.reportingView;
    // const selectedReport = props?.selectedReport
    const selectedReport = props?.session?.bids?.data?.tertiary?.selectedReport;
    const viewType = session?.env?.viewport?.viewType;
    const active = props?.active;

    const shippingStatusData = {
        new : {
            color : "#1b5e20",
            backgroundColor : "#e8f5e9",
            border : "solid 2px #1b5e20",
            statusQuote : ["Shipped", "Initial Assessment"],
        },
        underReview : {
            color : "#01579b",
            backgroundColor : "#e3f2fd",
            border : "solid 2px #01579b",
            statusQuote : ["Under Review", "Phase II", "Requested Info", "Request LEs / Datatape"],
        },
        bidding : {
            color : "#6a1b9a",
            backgroundColor : "#f3e5f5",
            border : "solid 2px #6a1b9a",
            statusQuote : ["Bidding"],
        },
        won : {
            color : "#004d40",
            backgroundColor : "#e0f2f1",
            border : "solid 2px #004d40",
            statusQuote : ["Closed Won"],
        },
        inContracts : {
            color : "#ff9800",
            backgroundColor : "#fff3e0",
            border : "solid 2px #ff9800",
            statusQuote : ["Closing"],
        },
        closed : {
            color : "#ef5350",
            backgroundColor : "#ffebee",
            border : "solid 2px #ef5350",
            statusQuote : ["Declined", "No Bid", "Does Not Fit Parameters", "In Conflict", "Lost with bid"],
        },
    }

    const queryBlock = (status) => {
        const values = shippingStatusData?.[status]?.statusQuote;
        const operator = props?.compareAttribute(shippingStatusData?.[status]?.statusQuote, "shippingStatus", "match") ? "not_match" : "match";

        return {
            values,
            formType: "generatedList",
            criterion: operator,
            attribute: "shippingStatus", // Passing attributes array to match against
            groupID: operator === "match" ? 1 : 2,
            groupOperator: "AND",
            inlineOperator: operator === "match" ? "OR" : "AND",
        };
    };

    const calculateAttribute = (attr, operation, conditions) =>{
        return (
            session?.env?.functions?.calculateAttribute({
                data: session?.case?.data?.bids?.tertiary,
                attr,
                operation,
                conditions,
            })
        );
    }

    const convertCriteriaToConditions = (criteria) => {
        if (criteria && Array.isArray(criteria)) {
            return criteria.map(criterionObj => {
                if (!criterionObj || typeof criterionObj !== 'object') return null;
                
                const { attr, criterion, groupID, inlineOperator } = criterionObj;
        
                if (!criterion || !attr) return null;
        
                const [operator, value] = Object.entries(criterion)[0];
        
                const conditionFn = operator === "match"
                    ? (obj) => obj[attr] === value
                    : (obj) => obj[attr] !== value;
        
                return { groupID, inlineOperator, conditionFn };
            }).filter(condition => condition !== null);
        }
        return [];
    };

    const getDynamicStyle = (status) => {
        const statusData = shippingStatusData?.[status];
    
        if (!active || !statusData) {
            return null;
        }
    
        if (props?.compareAttribute(statusData?.statusQuote, "shippingStatus", "match")) {
            return {
                backgroundColor: statusData?.backgroundColor,
                color: statusData?.color,
                border: statusData?.border,
            };
        }
    
        return null;
    };

    return (
        <div className={`queryBar f cC g bR dG dP fC${!active ? " loading inactive" : ''}`} onClick={()=>{console.log(321, session?.bids?.data?.tertiary)}}>
            <div className="toggles g f dG cC">
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    style={getDynamicStyle("new")}
                    title="New"
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("new")))}}}
                >
                    <InboxIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC text">
                            New
                        </div>
                    }
                </div>
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    style={getDynamicStyle("underReview")}
                    title="Under Review"
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("underReview")))}}}
                >
                    <FlagCircleIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC text">
                            Under Review
                        </div>
                    }
                </div>
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    title="Bidding"
                    style={getDynamicStyle("bidding")}
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("bidding")))}}}
                >
                    <CampaignIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC">
                            Bidding
                        </div>
                    }
                </div>
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    title="In Contracts"
                    style={getDynamicStyle("inContracts")}
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("inContracts")))}}}
                >
                    <GavelIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC text">
                            In Contracts
                        </div>
                    }
                </div>
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    title="Won"
                    style={getDynamicStyle("won")}
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("won")))}}}
                >
                    <CelebrationIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC text">
                            Won
                        </div>
                    }
                </div>
                <div
                    className={`queryToggle bold g cC dG nS f${viewType === "mobile" ? " alt" : " bR e"}${active ? " p" : ''}`}
                    title="Closed"
                    style={getDynamicStyle("closed")}
                    onClick={()=>{if (active) {session?.set('bids', 'tertiary.selectedReport', props?.updateCriteria(queryBlock("closed")))}}}
                >
                    <DoDisturbOnIcon/>
                    {viewType !== "mobile" &&
                        <div className="gCW f cC text">
                            Closed
                        </div>
                    }
                </div>
            </div>
            <div className="summaryData f g">
                {active ?
                    <>
                        <div className="dataPoint g f cC gCW gC2">
                            <span className="bold">
                                {selectedReport?.totalFound?.toLocaleString()
                                    &&
                                    selectedReport?.totalFound?.toLocaleString() + " / "
                                }
                                {calculateAttribute("recordID", "count", convertCriteriaToConditions([selectedReport?.criteria?.existing?.[0], selectedReport?.criteria?.existing?.[2]]))?.toLocaleString() + " "}
                            </span>
                            Policies
                        </div>
                        <div className="dataPoint g f cR gCW gC3">
                            Face Value
                            <span className="bold">
                                {` ${session?.env?.functions?.convertIntToCurrency(calculateAttribute(
                                    "faceValue",
                                    "sum",
                                    convertCriteriaToConditions(selectedReport?.criteria?.existing))
                                )}`}
                            </span>
                        </div>
                    </>
                :
                    <div className="f cC gC2">
                        No portfolio selected
                    </div>
                }
            </div>
        </div>
    )
};

export default TertiaryReportToggles;
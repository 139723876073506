import React from 'react';
import { useState, useEffect, useRef } from 'react';

import './Upload.css';

import InputField from '../Components/InputField.js';
import UploadFormScan from './UploadFormScan.js';
import ImportLeads from './ImportLeads.js';
import SuccessMessage from './SuccessMessage.js';

import PolicyIcon from '@mui/icons-material/Policy';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';

function Upload(props){
    const [policyData, setPolicyData] = useState({
        primaryFirstName : '',
        primaryLastName : '',
        primaryGender : '',
        primaryBirthDate : '',
        policyStatus : '',
        policyNumber : '',
        policyDeathBenefit : '',
        policyFaceValue : '',
        policyInsuranceCompany : '',
        policyInsuranceCompanyID : '',
        policySourceName : '',
        policySourceID : '',
        policyIssueDate : '',
        secondaryFirstName : '',
        secondaryLastName : '',
        secondaryGender : '',
        secondaryBirthDate : '',
        ownerType : '',
        ownerName : '',
        situsState : '',
        leadSource : ''
    });

    const [errorHandler, setErrorHandler] = useState({
        primaryFirstNameError : false,
        primaryLastNameError : false,
        primaryGenderError : false,
        primaryBirthDateError : false,
        policyStatusError : false,
        policyNumberError : false,
        policyDeathBenefitError : false,
        policyFaceValueError : false,
        policyInsuranceCompanyError : false,
        policyInsuranceCompanyIDError : false,
        policySourceNameError : false,
        policySourceIDError : false,
        policyIssueDateError : false,
        secondaryFirstNameError : false,
        secondaryLastNameError : false,
        secondaryGenderError : false,
        secondaryBirthDateError : false,
        ownerTypeError : false,
        ownerNameError : false,
        situsStateError : false,
        leadSourceError : false
    });

    const [abacusPaysLead, setAbacusPaysLead] = useState({});
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(false);
    const [fieldErrorState, setFieldErrorState] = useState({ policyNumber : false });
    const [secondaryInsured, setSecondaryInsured] = useState(false);
    const [primaryOwned, setPrimaryOwned] = useState(true);
    const [policySaved, setPolicySaved] = useState(false);
    const [forceStatus, setForceStatus] = useState(null);
    const [uploadActivityView, setUploadActivityView] = useState("policy");
    const [shareLink, setShareLink] = useState();
    const [leadSourceStatus, setLeadSourceStatus] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showImportLeads, setShowImportLeads] = useState(false);
    const [saveLoadingStatus, setSaveLoadingStatus] = useState(false);
    const [saveBtnStatus, setSaveBtnStatus] = useState('saveUploadData');
    const ownerTypeList = [
        'Trust', 'Individual', 'Corporation', 'President', 'Ownership Type - Info',
        'Organization', 'Multiple Owners', 'Corporate', 'Group'];
    const statesList = [
        'Alabama','Alaska','Arizona','Arkansas','California','Colorado',
        'Connecticut','Delaware', 'Florida','Georgia','Hawaii','Idaho','Illinois',
        'Indiana','Iowa','Kansas', 'Kentucky','Louisiana','Maine','Maryland','Massachusetts',
        'Michigan', 'Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire',
        'New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon',
        'Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah',
        'Vermont','Virgin Island','Virginia','Washington', 'West Virginia',
        'Wisconsin','Wyoming'];

    function updateErrorHandler(error, status){
        setErrorHandler( errorHandler =>  { return { ...errorHandler, [error] : status }});
    }

    function updateForceStatus(e, key){
        e.stopPropagation();
        setForceStatus(key);
    }

    function loadPolicyHolderData(){
        if(props.mobileDevice){
            return (
                <div className="newPolicyDetails" onClick={ (e) => { updateForceStatus(e, "default")}}>
                    <div className="policyDetailsHeader bold">Policy Upload</div>
                    { printLeadSourceForm() }
                    <div className="policyDetailsPolicy">
                        <div className="policyDetailsHeader">Policy Details</div>
                        <div className="formCanvas">
                            <div className="singleFormFieldRow">
                                <InputField
                                    key={"policyNumber"}
                                    onClick={updateForceStatus}
                                    functionPointer={updateTempPolicyData}
                                    value={policyData.policyNumber}
                                    placeholder={"Policy Number*"}
                                    name={"policyNumber"}
                                    readOnly={policySaved}
                                    forceTracker={forceStatus}
                                    autoComplete={"off"}
                                    error={fieldErrorState.policyNumber}
                                    returnDataKey={"policyNumberError"}
                                    formSubmitted={formSubmissionStatus}
                                />
                                <InputField
                                    key={"policyInsuranceCompany"}
                                    onClick={updateForceStatus}
                                    type="dropdown"
                                    functionPointer={updateTempPolicyData}
                                    value={policyData.policyInsuranceCompany}
                                    placeholder={"Insurance Company*"}
                                    name={"policyInsuranceCompany"}
                                    readOnly={policySaved}
                                    list={props.insuranceCompanyList}
                                    forceTracker={forceStatus}
                                    autoComplete={"off"}
                                    returnDataKey={"insuranceCompanyError"}
                                    returnDataStatus={errorHandler.insuranceCompanyError}
                                    returnDataFunction={updateErrorHandler}
                                    formSubmitted={formSubmissionStatus}
                                />
                                <InputField
                                    key={"policyIssueDate"}
                                    onClick={updateForceStatus}
                                    type="date"
                                    functionPointer={updateTempPolicyData}
                                    value={policyData.policyIssueDate}
                                    placeholder={"Issue Date*"}
                                    name={"policyIssueDate"}
                                    readOnly={policySaved}
                                    forceTracker={forceStatus}
                                    autoComplete={"off"}
                                    returnDataKey={"policyIssueDateError"}
                                    formSubmitted={formSubmissionStatus}
                                />
                                <InputField
                                    key={"policyDeathBenefit"}
                                    onClick={updateForceStatus}
                                    functionPointer={updateTempPolicyData}
                                    value={policyData.policyDeathBenefit}
                                    placeholder={"Death Benefit*"}
                                    name={"policyDeathBenefit"}
                                    readOnly={policySaved}
                                    forceTracker={forceStatus}
                                    autoComplete={"off"}
                                    preface="$"
                                    type="number"
                                    returnDataKey={"policyDeathBenefitError"}
                                    formSubmitted={formSubmissionStatus}
                                />
                            </div>
                            <div className="quadFieldRow">
                                { radioButtons() }
                            </div>
                        </div>
                    </div>
                    <div className="policyDetailsHeader">Insured</div>
                    { secondaryInsured ?
                        <div className="policyDetailsSplitHeader">
                            <div className="primaryTitle">
                                Primary
                            </div>
                            { props.mobileDevice ? null :
                                <div className="secondaryTitle">
                                    Secondary
                                </div>                            
                            }
                        </div>
                        :
                        <div className="policyDetailsSplitHeader">
                            <div className="primaryTitle">
                                Primary
                            </div>
                        </div>
                    }
                    { printInsuredForm() }
                    { printHolderForm() }
                    <div className={saveBtnStatus} onClick={ () => { createPolicy();  }}>
                        {saveLoadingStatus ?
                            <div className="progress cC">
                                <CircularProgress/>
                            </div>
                        :
                            !policySaved ? "Save Policy" : "Saved"
                        }
                    </div>
                </div> 
            )
        }

        return (
            <div className="newPolicyDetails" onClick={ (e) => { updateForceStatus(e, "default")}}>
                <div className="policyDetailsHeader bold">Policy Upload</div>
                { printLeadSourceForm() }
                <div className="policyDetailsPolicy">
                    <div className="policyDetailsHeader">Policy Details</div>
                    <div className="formCanvas">
                        <div className="quadFormFieldRow">
                            <InputField
                                key={"policyNumber"}
                                onClick={updateForceStatus}
                                functionPointer={updateTempPolicyData}
                                value={policyData.policyNumber}
                                placeholder={"Policy Number*"}
                                name={"policyNumber"}
                                readOnly={policySaved}
                                forceTracker={forceStatus}
                                autoComplete={"off"}
                                error={fieldErrorState.policyNumber}
                                returnDataKey={"policyNumberError"}
                                formSubmitted={formSubmissionStatus}
                            />
                            <InputField
                                key={"policyInsuranceCompany"}
                                onClick={updateForceStatus}
                                type="dropdown"
                                functionPointer={updateTempPolicyData}
                                value={policyData.policyInsuranceCompany}
                                placeholder={"Insurance Company*"}
                                name={"policyInsuranceCompany"}
                                readOnly={policySaved}
                                list={props.insuranceCompanyList}
                                forceTracker={forceStatus}
                                autoComplete={"off"}
                                returnDataKey={"insuranceCompanyError"}
                                returnDataStatus={errorHandler.insuranceCompanyError}
                                returnDataFunction={updateErrorHandler}
                                formSubmitted={formSubmissionStatus}
                            />
                            <InputField
                                key={"policyIssueDate"}
                                onClick={updateForceStatus}
                                type="date"
                                functionPointer={updateTempPolicyData}
                                value={policyData.policyIssueDate}
                                placeholder={"Issue Date*"}
                                name={"policyIssueDate"}
                                readOnly={policySaved}
                                forceTracker={forceStatus}
                                autoComplete={"off"}
                                returnDataKey={"policyIssueDateError"}
                                formSubmitted={formSubmissionStatus}
                            />
                            <InputField
                                key={"policyDeathBenefit"}
                                onClick={updateForceStatus}
                                functionPointer={updateTempPolicyData}
                                value={policyData.policyDeathBenefit}
                                placeholder={"Death Benefit*"}
                                name={"policyDeathBenefit"}
                                readOnly={policySaved}
                                forceTracker={forceStatus}
                                autoComplete={"off"}
                                preface="$"
                                type="number"
                                returnDataKey={"policyDeathBenefitError"}
                                formSubmitted={formSubmissionStatus}
                            />
                        </div>
                        <div className="quadFieldRow">
                            { radioButtons() }
                        </div>
                    </div>
                </div>
                <div className="policyDetailsHeader">Insured</div>
                { secondaryInsured ?
                    <div className="policyDetailsSplitHeader">
                        <div className="primaryTitle">
                            Primary
                        </div>
                        <div className="secondaryTitle">
                            Secondary
                        </div>
                    </div>
                    :
                    <div className="policyDetailsSplitHeader">
                        <div className="primaryTitle">
                            Primary
                        </div>
                    </div>
                }
                { printInsuredForm() }
                { printHolderForm() }
                <div className={saveBtnStatus} onClick={ () => { createPolicy() }}>
                    {saveLoadingStatus ?
                        <div className="progress cC">
                            <CircularProgress/>
                        </div>
                    :
                        !policySaved ? "Save Policy" : "Saved"
                    }
                </div>
            </div>
       )
    }

    function printInsuredForm(){
        let returningForm = [];
        var formCanvasType = "formCanvasHalf";
        var formRowType = "doubleFormFieldRow";
        var secondaryName = [];
        var secondaryAdditionalInfo = [];

        if(props.mobileDevice){
            formCanvasType = "formCanvas";
            formRowType = "singleFormFieldRow";
        }

        if(secondaryInsured){
            formCanvasType = "formCanvas";
            formRowType = "quadFormFieldRow";

            if(props.mobileDevice){
                formCanvasType = "formCanvas";
                formRowType = "singleFormFieldRow";
            }

            secondaryName.push(
                <InputField
                    key={0}
                    onClick={updateForceStatus}
                    functionPointer={updateTempPolicyData}
                    value={policyData.secondaryFirstName}
                    placeholder={"First Name*"}
                    name={"secondaryFirstName"}
                    readOnly={policySaved}
                    forceTracker={forceStatus}
                    autoComplete={"off"}
                    returnDataKey={"secondaryFirstNameError"}
                    formSubmitted={formSubmissionStatus}
                />,
                <InputField
                    key={1}
                    onClick={updateForceStatus}
                    functionPointer={updateTempPolicyData}
                    value={policyData.secondaryLastName}
                    placeholder={"Last Name*"}
                    name={"secondaryLastName"}
                    readOnly={policySaved}
                    forceTracker={forceStatus}
                    autoComplete={"off"}
                    returnDataKey={"secondaryLastNameError"}
                    formSubmitted={formSubmissionStatus}
                />);

            secondaryAdditionalInfo.push(
                <InputField key={0}
                    onClick={updateForceStatus}
                    functionPointer={updateTempPolicyData}
                    value={policyData.secondaryGender}
                    placeholder={"Gender*"}
                    name={"secondaryGender"}
                    readOnly={policySaved}
                    type={"dropdown"}
                    forceTracker={forceStatus}
                    autoComplete={"off"}
                    list={['Male', 'Female']}
                    returnDataKey={"secondaryGenderError"}
                    returnDataStatus={errorHandler.secondaryGenderError}
                    returnDataFunction={updateErrorHandler}
                    formSubmitted={formSubmissionStatus}
                />,
                <InputField
                    key={1}
                    onClick={updateForceStatus}
                    functionPointer={updateTempPolicyData}
                    value={policyData.secondaryBirthDate}
                    placeholder={"Birth Date*"}
                    name={"secondaryBirthDate"}
                    readOnly={policySaved}
                    type={"dateWithAge"}
                    forceTracker={forceStatus}
                    autoComplete={"off"}
                    returnDataKey={"secondaryBirthDateError"}
                    formSubmitted={formSubmissionStatus}
                />
            )
        }
        return (
            <div className={formCanvasType}>
                <div className={formRowType}>
                    <InputField
                        onClick={updateForceStatus}
                        functionPointer={updateTempPolicyData}
                        value={policyData.primaryFirstName}
                        placeholder={"First Name*"}
                        name={"primaryFirstName"}
                        readOnly={policySaved}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        returnDataKey={"primaryFirstNameError"}
                        formSubmitted={formSubmissionStatus}
                    />
                    <InputField
                        onClick={updateForceStatus}
                        functionPointer={updateTempPolicyData}
                        value={policyData.primaryLastName}
                        placeholder={"Last Name*"}
                        name={"primaryLastName"}
                        readOnly={policySaved}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        returnDataKey={"primaryLastNameError"}
                        formSubmitted={formSubmissionStatus}
                    />
                    {props.mobileDevice ? null : secondaryName}
                </div>
                <div className={formRowType}>
                    <InputField
                        onClick={updateForceStatus}
                        functionPointer={updateTempPolicyData}
                        value={policyData.primaryGender}
                        placeholder={"Gender*"}
                        name={"primaryGender"}
                        readOnly={policySaved}
                        type={"dropdown"}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        list={['Male', 'Female']}
                        returnDataKey={"primaryGenderError"}
                        returnDataStatus={errorHandler.primaryGenderError}
                        returnDataFunction={updateErrorHandler}
                        formSubmitted={formSubmissionStatus}
                    />
                    <InputField
                        onClick={updateForceStatus}
                        functionPointer={updateTempPolicyData}
                        value={policyData.primaryBirthDate}
                        placeholder={"Birth Date*"}
                        name={"primaryBirthDate"}
                        readOnly={policySaved}
                        type={"dateWithAge"}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        returnDataKey={"primaryBirthDateError"}
                        formSubmitted={formSubmissionStatus}
                    />
                    {props.mobileDevice && secondaryInsured ?
                        <div>
                            <div className="policyDetailsSplitHeader">
                                <div className="secondaryTitle">
                                    Secondary
                                </div>
                            </div>
                            {secondaryName}
                            {secondaryAdditionalInfo}
                        </div>
                    :
                    secondaryAdditionalInfo }
                </div>
            </div>
        )
    }

    function printHolderForm(){
            var formCanvasType = "formCanvasHalf";
            var formRowType = "doubleFormFieldRow";
            var ownerName = [];

            if(!primaryOwned){
                formCanvasType = "formCanvas";
                formRowType = "quadFormFieldRow";
                ownerName.push(
                    <InputField
                        key={0}
                        functionPointer={updateTempPolicyData}
                        value={policyData.ownerName}
                        placeholder={"Name*"}
                        name={"ownerName"}
                        readOnly={policySaved}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        returnDataStatus={errorHandler.ownerName}
                        returnDataKey={"ownerNameError"}
                        formSubmitted={formSubmissionStatus}
                    />
                );
            }

            if(props.mobileDevice){
                formCanvasType = "formCanvas";
                formRowType = "formFieldRow";
            }

            return (
                <div className={formCanvasType}>
                    <div className="policyDetailsHeader">Owner</div>
                    {checkboxButton()}
                    <div className={formRowType}>
                        <InputField
                            key={"ownerType"}
                            onClick={updateForceStatus}
                            type="dropdownInvert"
                            functionPointer={updateTempPolicyData}
                            value={policyData.ownerType}
                            placeholder={"Owner Type*"}
                            name={"ownerType"}
                            readOnly={policySaved}
                            list={ownerTypeList}
                            forceTracker={forceStatus}
                            autoComplete={"off"}
                            returnDataKey={"ownerTypeError"}
                            returnDataStatus={errorHandler.ownerType}
                            returnDataFunction={updateErrorHandler}
                            formSubmitted={formSubmissionStatus}
                        />
                        <InputField
                            key={"situsState"}
                            onClick={updateForceStatus}
                            type="dropdownInvert"
                            functionPointer={updateTempPolicyData}
                            value={policyData.situsState}
                            placeholder={"Situs State*"}
                            name={"situsState"}
                            readOnly={policySaved}
                            list={statesList}
                            forceTracker={forceStatus}
                            autoComplete={"off"}
                            returnDataKey={"situsStateError"}
                            returnDataStatus={errorHandler.situsStateError}
                            returnDataFunction={updateErrorHandler}
                            formSubmitted={formSubmissionStatus}
                        />
                        {ownerName}
                    </div>
                </div>
            )
    }

    function abacusPaysBtnErrorCheck(){
        if(policyData.leadSource === "Abacus Life" && Object.keys(abacusPaysLead).length == 0 && formSubmissionStatus){
            return " error";
        }

        return '';
    }

    function printLeadSourceForm(){
        var formCanvasType = "formCanvasHalf";
        var formRowType = "doubleFormFieldRow";

        if(props.mobileDevice){
            formCanvasType = "formCanvas";
            formRowType = "formFieldRow";
        }

        return (
            <div className={formCanvasType}>
                <div className="policyDetailsHeader">Lead Origin</div>
                <div className="doubleFormFieldRow">
                    <InputField
                        key={0}
                        onClick={updateForceStatus}
                        functionPointer={updateTempPolicyData}
                        value={policyData.leadSource}
                        placeholder={"Lead Source*"}
                        name={"leadSource"}
                        readOnly={leadSourceStatus}
                        type={"dropdownInvert"}
                        forceTracker={forceStatus}
                        autoComplete={"off"}
                        list={[props.sessionUser.companyName, "Abacus Life"]}
                        returnDataKey={"leadSourceError"}
                        returnDataStatus={errorHandler.leadSourceError}
                        returnDataFunction={updateErrorHandler}
                        formSubmitted={formSubmissionStatus}
                    />
                    {
                        policyData.leadSource === "Abacus Life" &&
                            <div className={"formBtn ns" + abacusPaysBtnErrorCheck()} onClick={()=>{setShowImportLeads(true)}}>
                                { Object.keys(abacusPaysLead).length !== 0 ?
                                    abacusPaysLead.leadFirstName + " " + abacusPaysLead.leadLastName
                                 : "Click to Import Lead"
                                }
                                { Object.keys(abacusPaysLead).length !== 0 ?
                                    <CheckCircleIcon/>
                                : 
                                    <SyncIcon/>
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }

    function updateErrorState(errorType, errorMessage){
        setFieldErrorState(fieldErrorState => ({...fieldErrorState, [errorType]: errorMessage }));
    }

    function updateTempPolicyData(profileData, direct){
        if(direct){
            setPolicyData(policyData => ({...policyData, [direct.name]: direct.value }));
            if(direct["siblingName"] !== undefined){
                setPolicyData(policyData => ({...policyData, [direct.siblingName]: direct.siblingValue }));
            }
        }else{
            setPolicyData(policyData => ({...policyData, [profileData.target.name]: profileData.target.value }));
            setFieldErrorState(fieldErrorState => ({...fieldErrorState, [profileData.target.name]: false }));
        }
    }

    function createPolicy(){
        console.log(policyData);
        console.log(props.sessionUser);
        if(saveBtnStatus === "saveUploadData"){
            setFormSubmissionStatus(true);
            return;
        }

        if(policyData.policyNumber === '' || policySaved){
            return;
        }

        setSaveLoadingStatus(true);
        policyData.accountID = props.sessionUser.accountID;
        policyData.leadID = abacusPaysLead.leadID;
        var createPolicyBody = {...policyData}

        if(createPolicyBody.leadSource === props.sessionUser.companyName){
            createPolicyBody.leadSource = "Marketplace";
        }else{
            createPolicyBody.leadSource = abacusPaysLead.leadOrigin;
            if(createPolicyBody?.originalClientDirectID){
                createPolicyBody.originalClientDirectID = abacusPaysLead.originalClientDirectID;
            }
        }

        props.session?.env?.functions?.buildFetchRequest("createPolicy", createPolicyBody)
            .then(response => response.json())
            .then(resData => {
                console.log(resData);
                if(resData.status === 200){
                    setSuccess(true);
                    manageFolderCreation(resData.relatedPolicyID);
                    props.loadPolicyOverview();
                    props.loadNotifications();
                    if(createPolicyBody.leadSource !== "Marketplace"){
                        sendToAbacus(resData.relatedCases);
                    }
                }

                if(resData.status === 502){
                    updateErrorState(resData.errorType, resData.errorMessage);
                    console.log("Policy Number already exists.");
                }

                if(resData.status === 500){
                    console.log("Unable to create policy.");
                }
                setSaveLoadingStatus(false);
        });
    }

    function manageFolderCreation(relatedPolicyID){
        if(policyData.policyNumber === '' || policySaved){
            return;
        }

        const paramVals = {
            "brokerName" : props.sessionUser.companyName,
            "policyNumber" : policyData.policyNumber,
        };

        props.session?.env?.functions?.buildFetchRequest("createPolicyFolder", paramVals)
            .then(response => response.json())
            .then(resData => {
                console.log(resData);
                if(resData.status === 200){
                    setShareLink(resData.shareLink);
                    setPolicySaved(true);
                    updateActivityView("documents", true);
                }else{
                    console.log("No account found with that matching password.");
                }

                if(resData.status === 500){
                    console.log("No account found.");
                }
        });
    }

    function fieldEligibility(str, x){
        return (!str || str.length === 0);
    }

    function checkSaveBtnStatus(){
        var currentClass = "saveUploadData active";
        var formCopy = policyData;
        var errorHandlerCopy = errorHandler;

        if(policySaved){
            currentClass = "saveUploadData";
        }
        if(fieldEligibility(formCopy.policyNumber)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.policyInsuranceCompany)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.policyDeathBenefit)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.policyIssueDate)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.primaryFirstName)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.primaryLastName)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.primaryGender)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.primaryBirthDate)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.situsState)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.ownerType)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.ownerName)){
            currentClass =  "saveUploadData";
        }
        if(fieldEligibility(formCopy.leadSource)){
            currentClass =  "saveUploadData";
        }
        if(formCopy.leadSource === "Abacus Life" && Object.keys(abacusPaysLead).length === 0){
            currentClass =  "saveUploadData";
        }

        if(secondaryInsured){
            if(fieldEligibility(formCopy.secondaryFirstName)){
                currentClass =  "saveUploadData";
            }
            if(fieldEligibility(formCopy.secondaryLastName)){
                currentClass =  "saveUploadData";
            }
            if(fieldEligibility(formCopy.secondaryGender)){
                currentClass =  "saveUploadData";
            }
            if(fieldEligibility(formCopy.secondaryBirthDate)){
                currentClass =  "saveUploadData";
            }
        }

        if(errorHandlerCopy.insuranceCompanyError || errorHandlerCopy.primaryGenderError || errorHandlerCopy.secondaryGenderError || errorHandlerCopy.ownerTypeError || errorHandlerCopy.situsStateError || errorHandlerCopy.leadSourceError){
            currentClass =  "saveUploadData";
        }

        if(saveBtnStatus !== currentClass){
            setSaveBtnStatus(currentClass);
        }
        //return "saveUploadData active";
    }

    
    function updateActivityView(activityView, initiate){
        if(activityView === "documents" && !policySaved && !initiate){
            return;
        }

        setUploadActivityView(activityView);
    }

    function currentActivity(activityView){
        if(activityView === "documents" && !policySaved){
            return "switchViewBtn inactive";
        }
        if(uploadActivityView === activityView){
            return "switchViewBtn selected";
        }
        return "switchViewBtn";
    }

    function showCurrentActivity(){
        if(uploadActivityView === "policy"){
            return (
                <div className="uploadWrapper">
                    {loadPolicyHolderData()}
                </div>
            )
        }

        if(uploadActivityView === "documents" && policySaved){
            return (
                <div className="documentsUploadBlock">
                        <iframe src={shareLink}></iframe>
                </div>
            )
        }
    }

    function radioButtons(){
        if(!secondaryInsured){
            policyData.secondaryFirstName = '';
            policyData.secondaryLastName = '';
            policyData.secondaryGender = '';
            policyData.secondaryAge = '';
            policyData.secondaryBirthDate = '';
        }

        return (
            <div className="radioOptionField">
                <div className="fieldName">
                    Number of Insured
                </div>
                <div className="radioOption">
                    <div className="radioOptionBtn" onClick={ () => {setSecondaryInsured(false)}}>
                        {secondaryInsured ? null : <div className="radioSelection"></div> }
                    </div>
                    <div className="radioOptionName">
                        <PersonIcon/>
                    </div>
                </div>
                <div className="radioOption">
                    <div className="radioOptionBtn" onClick={ () => {setSecondaryInsured(true)}}>
                        {secondaryInsured ? <div className="radioSelection"></div> : null }
                    </div>
                    <div className="radioOptionName">
                        <GroupIcon/>
                    </div>
                </div>
            </div>
        )
    }

    function checkboxButton(){
        var selectionStatus = "checkboxToggle";
        if(primaryOwned){
            selectionStatus += " selected";
        }
        return (
            <div className="checkboxField">
                <div className={selectionStatus} onClick={ () => {togglePrimaryOwned()}}>
                    {primaryOwned ? <CheckIcon/> : null }
                </div>
                <div className="fieldName">
                    Primary Insured is Owner
                </div>
            </div>
        )
    }

    function togglePrimaryOwned(){
        if(primaryOwned){
            setPrimaryOwned(false);
        }else{
            setPrimaryOwned(true);
        }
    }

    function sendToAbacus(policyNumber){
        var sendingLeadBlock = {...abacusPaysLead};

        delete sendingLeadBlock.currentPosition;
        delete sendingLeadBlock.dateCreated;
        sendingLeadBlock.leadStatus = "Sent to Abacus";
        sendingLeadBlock.relatedCases = policyNumber + ";" + sendingLeadBlock.relatedCases;

        var paramVals = {
            "leadBlock" : sendingLeadBlock,
            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName
        };

        props.session?.env?.functions?.buildFetchRequest("updateLead", paramVals)
            .then(response => response.json())
            .then(resData => {
                console.log(resData);
                if(resData.status === 200){
                    props.setLeadsList(prevList => {
                        const updatedList = [...prevList];
                        const index = updatedList.findIndex(item => item.leadID === abacusPaysLead.leadID);
                        updatedList[index].leadStatus = "Sent to Abacus";
                        updatedList[index].currentPosition = "closed";
                        return updatedList;
                    });
                }else{
                    console.log("error updating status to 'Sent to Abacus'");
                }
        });
    }

    function handleClose(evt){
        if(evt.target.className === "uploadModuleBoard"){
            setShowImportLeads(false);
        }
    }

    useEffect(() => {
        if(props.sessionUser?.accountData?.[props.sessionUser.accountID]?.type){
            setPolicyData(policyData =>{
                return {
                    ...policyData,
                    policySourceName: props.sessionUser?.accountData?.[props.sessionUser.accountID].name,
                    policySourceID: props.sessionUser?.accountData?.[props.sessionUser.accountID].sourceID,        
                    accountExecutive: props.sessionUser?.accountData?.[props.sessionUser.accountID].accountExecutive,        
                    accountManager: props.sessionUser?.accountData?.[props.sessionUser.accountID].accountManager,
                    caseOwner: props.sessionUser?.accountData?.[props.sessionUser.accountID].caseOwner,        
                    caseProcessor: props.sessionUser?.accountData?.[props.sessionUser.accountID].caseProcessor
                };
            });
        }

    }, [props.sessionUser?.accountData?.[props.sessionUser.accountID]]);

    useEffect(() => {
        checkSaveBtnStatus();
        if(primaryOwned && policyData.ownerName !== policyData.primaryFirstName + " " + policyData.primaryLastName){
                setPolicyData(policyData => ({...policyData, "ownerName": policyData.primaryFirstName + " " + policyData.primaryLastName }));
        }
    }, [errorHandler, policyData, abacusPaysLead, secondaryInsured]);

    useEffect(() => {
        if(!primaryOwned){
            setPolicyData(policyData => ({...policyData, "ownerName": ''}));
        }else{
            setPolicyData(policyData => ({...policyData, "ownerName": policyData.primaryFirstName + " " + policyData.primaryLastName }));
        }
    }, [primaryOwned]);

    useEffect(() => {
        if(Object.keys(props.importedUploadPolicy).length > 0){
            setPolicyData(policyData => ({...policyData,
                "primaryFirstName": props.importedUploadPolicy.leadFirstName,
                "primaryLastName": props.importedUploadPolicy.leadLastName,
                "primaryGender": props.importedUploadPolicy.gender,
                "policyDeathBenefit": props.importedUploadPolicy.leadDeathBenefit,
            }));
            setLeadSourceStatus(true);
            setPolicyData(policyData => ({...policyData, leadSource: "Abacus Life" }));
            setAbacusPaysLead(props.importedUploadPolicy);
            props.setImportedUploadPolicy({});
        }
    }, [props.importedUploadPolicy]);

    return (
        <div className="uploadPage">
            {success && <SuccessMessage message="Policy Successfully Created. Loading Documents Drive..." timeout={2000} />}
            <UploadFormScan
                policyData={policyData}
                secondaryInsured={secondaryInsured}
                abacusPaysLead={abacusPaysLead}
                errorHandler={errorHandler}
                setErrorHandler={setErrorHandler}
                session={props?.session}
            />
            <div className="uploadOverview moduleBlock">
                { showImportLeads ?
                    <ImportLeads
                        handleClose={handleClose}
                        leadsList={props.leadsList}
                        setShowImportLeads={setShowImportLeads}
                        setImportedUploadPolicy={props.setImportedUploadPolicy}
                        session={props?.session}
                    />
                    : null
                }
                <div className="singlePolicyBlockOptionBar g f">
                    <div className={currentActivity("policy") + " f g cC s e"} onClick={() => {updateActivityView("policy")}}>
                        <div className="switchViewBtnOption">
                            Policy Details
                        </div>
                        <div className="inlineBtnIcon"><PolicyIcon/></div>
                    </div>
                    <div className={currentActivity("documents") + " f g cC s e"} onClick={() => {updateActivityView("documents")}}>
                        <div className="switchViewBtnOption">
                            Documents
                        </div>
                        <div className="inlineBtnIcon"><DriveFolderUploadIcon/></div>
                    </div>
                </div>
                <div className="uploadModule">
                    {showCurrentActivity()}
                </div>
            </div>
        </div>
    )
}

export default Upload;
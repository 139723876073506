import { useState, useEffect } from 'react';
import { timeStamp } from '../envFunctions.js';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { convertIntToCurrency } from '../envFunctions.js';

function TimelineRequest(props){
    const [checkStatusClass, setCheckStatusClass] = useState("open");

    function updateCheckStatus(toggle){
        if(props.objectData?.checkStatus === "Completed"){
            setCheckStatusClass("completed");
                return;
        }

        if(toggle){
            if(checkStatusClass === "open"){
                setCheckStatusClass("submitted");
                toggleCheckStatus("Submitted");
                props.updateRequest(props.policyKey, "Submitted");
                if(props.closingTab === undefined){
                    props.setOpenRequestCount(props.openRequestCount - 1);
                }
            }else{
                setCheckStatusClass("open");
                toggleCheckStatus("Open");
                props.updateRequest(props.policyKey, "Open");
                if(props.closingTab === undefined){
                    props.setOpenRequestCount(props.openRequestCount + 1);
                }
            }
            return;
        }

        if(props.objectData?.checkStatus === "Submitted"){
            setCheckStatusClass("submitted");  
        }
        if(props.objectData?.checkStatus === "Open"){
            setCheckStatusClass("open");
        }
    }

    function toggleCheckStatus(toggle){
        const paramVals = {
            "relatedActivityLogID" : props.objectData?.relatedActivityLogID,
            "checkStatus" : toggle
        };

        props.session?.env?.functions?.buildFetchRequest("toggleCheckStatus", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){

                }else{
                    console.log("Error on check status toggle.");
                    console.log(resData);
                }
        });
    }

    var internal = '';
    if(props.objectData?.assignedTo === "Internal"){
        internal = " internal";
    }

    useEffect(() => {
        updateCheckStatus();
    }, []);

    if(props.objectType === "servicing"){
        let checkStatus = props.objectData?.taskStatus.toLowerCase();
        let statusColor;
        let statementPrompt;
        let currentPrompt = props.objectData?.carrierTask;

        if(currentPrompt === "VOC" || currentPrompt == "Trading VOC"){
            statementPrompt = "VOC";
        }
        if(currentPrompt === "Request Docs"){
            statementPrompt = "Document Request";
            return;
        }
        if(currentPrompt === "_other"){
            statementPrompt = "Other";
            return;
        }
        if(currentPrompt === "Conversion"){
            statementPrompt = "Conversion Process";
            return;
        }
        if(currentPrompt === "EFT"){
            statementPrompt = "EFT Setup";
            return;
        }
        if(currentPrompt === "Reallocation and illustration"){
            statementPrompt = "Document Request";
        }
        if(currentPrompt === "Follow Up"){
            statementPrompt = "Follow Up";
            return;
        }

        if(checkStatus === "closed"){
            checkStatus = "completed";
            statusColor = "#29b6f6";
        }
        if(checkStatus === "new"){
            checkStatus = "open";
            statusColor = "#ef5350";
        }
        if(checkStatus === "progress"){
            checkStatus = "submitted";
            statusColor = "#fbc02d";
        }
        if(checkStatus === "problem"){
            checkStatus = "issue";
            statusColor = "#00c853";
        }

        return (
            <div className={"timelineRequest VOC " + checkStatus}>
                <div className="timelinePostTop alt">
                    <div className="timelineRequestIcon cL s">
                        {props.objectData.type === "VOC" && <VerifiedUserIcon style={{color : statusColor}}/>}
                    </div>
                    <div className="timelineRequestStatement cL s">
                        {props.objectData.carrierTask}
                    </div>
                    <div className="timelineRequestInnerWrap servicing">
                        {checkStatus === "open" && <div className="requestInternalPrompt cR e"><span>New</span></div>}
                        {checkStatus === "submitted" && <div className="requestInternalPrompt cR e"><span>Pending</span></div>}
                        {checkStatus === "completed" && <div className="requestInternalPrompt cR e"><span>Done</span></div>}
                    </div>
                    <div className="timelineRequestCheckbox cR e">
                        <div className={"timelineRequestCheckboxStatus internal " + checkStatus}>
                            <CheckCircleIcon/>
                        </div>
                    </div>
                </div>
                <div className="timeinePostSeparator">
                </div>
                <div className="timelinePostBottom alt">
                    <div className="cL s">
                        <span>
                            COI: 
                        </span>
                        <span>
                            {convertIntToCurrency(props.objectData?.currentCOI, props.mobileDevice, true)}
                        </span>
                    </div>
                    <div className="cC">
                        <span>
                            AV: 
                        </span>
                        <span>
                            {convertIntToCurrency(props.objectData?.accountValue, props.mobileDevice, true)}
                        </span>
                    </div>
                    <div className="cC">
                        <span>
                            CSV: 
                        </span>
                        <span>
                            {convertIntToCurrency(props.objectData?.cashValue, props.mobileDevice, true)}
                        </span>
                    </div>
                    <div className="timelineRequestTimestamp cR e">
                        <div></div>
                        <div>
                            {props.objectData?.completedDate ? timeStamp(props.objectData?.completedDate, props.mobileDevice): timeStamp(props.objectData?.dateCreated, props.mobileDevice)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"timelineRequest " + checkStatusClass}>
            {props.closingTab === undefined && <div className="timelineRequestTreeline"></div>}
            <div className="timelineRequestIcon cC">
                <HistoryEduIcon/>
            </div>
            <div className="timelineRequestInnerWrap">
                <div className="timelineRequestStatement cL">
                    {props.objectData?.status}
                </div>
                {props.objectData?.directLink !== '' &&
                    <div className="requestLink cC" onClick={checkStatusClass !== "completed" ? ()=>{props.navigateToDocument(props.objectData?.directLink)} : null}>
                        { checkStatusClass === "completed" ? null : checkStatusClass === "open" ? "Upload" : "View File" }
                    </div>
                }
                {internal !== '' && checkStatusClass !== "completed" && <div className="requestInternalPrompt cC"><span>Pending</span></div>}
            </div>

            <div className="timelineRequestCheckbox cC">
                <div className={"timelineRequestCheckboxStatus " + checkStatusClass + internal}
                        onClick={props.objectData?.assignedTo === "Internal" ? null : ()=>{ updateCheckStatus(true) } }>
                    {checkStatusClass === "open" ?
                        props.objectData?.assignedTo === "Internal" ?
                        <PendingActionsIcon/> :
                        <RadioButtonUncheckedIcon/>
                        : null
                    }
                    {checkStatusClass === "completed" && <CheckCircleIcon/> }
                    {checkStatusClass === "submitted" ?
                        props.objectData?.assignedTo === "Internal" ?
                         <PendingActionsIcon/> :
                         <CheckCircleOutlineIcon/>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default TimelineRequest;


import React from 'react';
import { useState, useEffect } from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PaymentsIcon from '@mui/icons-material/Payments';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';

function BidsReport(props){
    let bidsReport = [];
    let bidsTableData = [];

    bidsReport.push(
        <div key="actionBar" className="bidsActionBar cC fR g">
            {props?.downloadStatus ?
                <div
                    key="downloadBtn"
                    className="btn loading g cC fMP bR p gC3"
                >
                    <CircularProgress/>
                </div>
            :
                <div
                    key="downloadBtn" onClick={()=>{props.downloadBidReport()}}
                    className="btn default g cC fMP bR p gC3">
                    <span>Download</span>
                    <DownloadIcon/>
                </div>  
            }

        </div>
);

    bidsReport.push(
        <div key="bidPanelHeader" className="bidPanelHeader">
            <div className="bidPanelTableRow">
                <div className="bidPanelDetail">
                    <div className="bidPanelDetailValue">
                        <div>Date</div>
                        <div><CalendarMonthIcon style={{color: "#607d8b"}}/></div>
                    </div>
                </div>
                <div className="bidPanelDetail">
                    <div className="bidPanelDetailValue">
                        <div>Bid Cash</div>
                        <div><MonetizationOnIcon style={{color: "#4caf50"}}/></div>
                    </div>
                </div>
                <div className="bidPanelDetail">
                    <div className="bidPanelDetailValue">
                        <div>Bid RDB</div>
                        <div><PaymentsIcon style={{color: "#ba68c8"}}/></div>
                    </div>
                </div>
                <div className="bidPanelDetail">
                    <div className="bidPanelDetailValue">
                        <div>Note</div>
                        <div><ShortTextIcon style={{color: "#e65100"}}/></div>
                    </div>
                </div>
            </div>
        </div>);

    if(props.bidsLoadingStatus){
        bidsReport.push(
            <div key="1" className="bidPanelTable bR f">
                <div className="singlePolicyTimelineInner loading loadingDisplay">
                    <div>
                        <CircularProgress color="inherit"/>
                    </div>
                </div>
            </div>
        )

        return ( bidsReport );
    }

    if(Object.keys(props.bidData).length === 0){
        bidsTableData.push(
            <div key="noBids" className="empty cC f">
                There are no bids at this time
            </div>);
    }else{
        for (var key of Object.keys(props.bidData)){
            bidsTableData.push(
                <div key={key} className="bidPanelTableRow">
                    <div className="bidPanelDetail">
                        <div className="bidPanelDetailValue date">
                            {props.bidData[key].bidDate}
                        </div>
                    </div>
                    <div className="bidPanelDetail">
                        <div className="bidPanelDetailValue bold">
                            { props.convertIntToCurrency(props.bidData[key].bidAmount, props.mobileDevice) === "N/A" ? "$0" : props.convertIntToCurrency(props.bidData[key].bidAmount, props.mobileDevice) }
                        </div>
                    </div>
                    <div className="bidPanelDetail">
                        <div className="bidPanelDetailValue bold">
                            { props.convertIntToCurrency(props.bidData[key].bidRDB, props.mobileDevice) === "N/A" ? "$0" : props.convertIntToCurrency(props.bidData[key].bidRDB, props.mobileDevice) }
                        </div>
                    </div>
                    <div className="bidPanelDetail">
                        <div className="bidPanelDetailValue s">
                            {props.bidData[key].bidNotes}
                        </div>
                    </div>
                </div>
            );
        }
    }

    bidsReport.push(
        <div key="bidPanelTable" className="bidPanelTable bR f">
            {bidsTableData}
        </div>
    )

    return bidsReport

}

export default BidsReport;
import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';

import InputField from '../../Components/InputField.js';
import UpdateLeadPanel from '../UpdateLeadPanel';
import CreateLeadNote from '../CreateLeadNote';
import TimelineFollowUp from '../../Components/TimelineFollowUp.js';
import AssignPanel from '../../Components/AssignPanel.js';
import SendAppPanel from '../SendAppPanel.js';
import TimelinePostDetails from '../../Components/Timeline/TimelinePostDetails.js';
import DefaultTimelinePost from '../../Components/Timeline/DefaultTimelinePost';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimelineIcon from '@mui/icons-material/Timeline';
import CircularProgress from '@mui/material/CircularProgress';
import MessageIcon from '@mui/icons-material/Message';
import UpdateIcon from '@mui/icons-material/Update';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';

function SingleLead(props){
    const params = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [readOnlyStatus, setReadOnlyStatus] = useState(true);
    const [showLeadNotesPanel, setShowLeadNotesPanel] = useState(false);
    const [showUpdateStatusPanel, setShowUpdateStatusPanel] = useState(false);
    const [leadActivityTimeline, setLeadActivityTimeline] = useState([]);
    const [showStatusDetail, setShowStatusDetail] = useState(false);
    const [currentTimelinePost, setCurrentTimelinePost] = useState();
    const [showAssignPanel, setShowAssignPanel] = useState(false);
    const [showSendAppPanel, setShowSendAppPanel] = useState(false);
    const [currentStatusKey, setCurrentStatusKey] = useState(0);
    const [timelineKeyTracker, setTimelineKeyTracker] = useState(0);

    const [currentLeadInfo, setCurrentLeadInfo] = useState(props.leadsList.find(lead=>{
                        return lead.leadID == params.leadID }) || {});
    const [tempCurrentLeadInfo, setTempCurrentLeadInfo] = useState([]);
    const formatter = new Intl.NumberFormat('en-US', {  style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });

    function convertIntToCurrency(value){
        let currencyValue = "N/A";
    
        if(value !== null){
            currencyValue = formatter.format(value);
        }
    
        return currencyValue;
    }

    function toggleFormEdit(){
        if(readOnlyStatus){
            setReadOnlyStatus(false);
        }else{
            setReadOnlyStatus(true);
        }
    }

    function sendToAbacus(){
        props.setImportedUploadPolicy(currentLeadInfo);
        props.changeActivityView("dashboard");
    }

    function confirmBtnEligibility(){
        if (Object.keys(currentLeadInfo).length === 0) {
            return true;
        }

        /*if(currentLeadInfo.currentPosition === "closed"){
            return true;
        }*/

        return false;
    }

    function printTimeline(){
        return (
            <div className="singlePolicyTimelineTracker lead g f">
                <div className="leadDetailsCardTitle">
                    <span>Lead Timeline</span>
                    <div className="groupIcon">
                        <TimelineIcon/>
                    </div>
                    <div className="activityTimelineHeader cC">
                        <div className="leadTimelineBtn" onClick={()=>{setShowSendAppPanel(true)}}>
                            <SendIcon/>
                            Send App
                        </div>
                        <Link to="/upload" className="transferBtn" onClick={()=>{sendToAbacus()}}>
                            <MoveUpIcon/>
                            Send to Abacus
                        </Link>
                    </div>
                </div>

                <div className="leadDetailsCardFace bR tO">
                    <div className="leadInlineTimelineBtnContainer">
                        <div className={ confirmBtnEligibility() ? "leadInlineTimelineBtn inactive ns" : "leadInlineTimelineBtn ns"}
                            onClick={ confirmBtnEligibility() ? null : ()=>{setShowUpdateStatusPanel(true); setShowUpdateStatusPanel("default");}}>
                            <UpdateIcon/> <span>Update Status</span>
                        </div>
                        <div className={ confirmBtnEligibility() ? "leadInlineTimelineBtn inactive ns" : "leadInlineTimelineBtn ns"}
                            onClick={ confirmBtnEligibility() ? null : ()=>{setShowLeadNotesPanel(true)}}>
                            <MessageIcon/> <span>Add Note</span>
                        </div>
                    </div>
                    <div className="leadInlineTimelineSeparator"></div>
                    {loadTimeline()}
                </div>
            </div>
        )
    }

    function loadLeadActivity(){
        const paramVals = {
            "accountID" : props.sessionUser.accountID,
            "leadID" : params.leadID,
        };
      
        props.session?.env?.functions?.buildFetchRequest("pullLeadActivity", paramVals)
          .then(response => response.json())
          .then(resData => {
            var tempActivityList = [];
            var x = 0;
      
            if(resData.status === 200){
                if(resData?.data){
                    Object?.keys(resData?.data)?.forEach(function(key){
                        resData.data[key].key = key;
                        resData.data[key].objectType = "Lead";
                        tempActivityList.push(resData.data[key]);
                        x++;
                    });
                }
            }
      
            if(resData.status === 500){
              console.log("No activity found.");
            }
      
            setTimelineKeyTracker(x);
            setLeadActivityTimeline(tempActivityList);
            setLoadingStatus(false);
          });
      }

    function loadTimeline(){
        let timelineBlock = [];

        if(Object.keys(leadActivityTimeline).length === 0){
            return (
                loadingStatus ?
                    <div className="singlePolicyEmptyTimeline">
                        <div className="singlePolicyTimelineInner loading loadingDisplay">
                            <div>
                                <CircularProgress color="inherit"/>
                            </div>
                        </div>
                    </div>
                    :

                    <div className="singleTimeline g b bR t b fC cC">
                        <div className="idleStamp">
                            <MoreVertIcon/>
                        </div>
                        <div className="timelineIssueDate">
                            {currentLeadInfo.dateCreated ? "Arrival Date: " + timeConverter(currentLeadInfo.dateCreated) : null }
                        </div>
                    </div>
            )
        }

        let sortedTimeline = [...leadActivityTimeline].sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));

        for (var timelinePost of sortedTimeline){
            let key = `${timelinePost.key}-${timelinePost.type}`;

            if(timelinePost.type === "Follow Up"){
                timelineBlock.push(
                    <TimelineFollowUp
                        key={key}
                        leadKey={timelinePost.key}
                        objectData={timelinePost}
                        changeActivityView={props.changeActivityView}
                        mobileDevice={props.mobileDevice}
                        objectType={"Lead"}
                        timelineType="lead"
                        leadID={params.leadID}
                        setCurrentStatusKey={setCurrentStatusKey}
                        // setShowStatusDetail={setShowStatusDetail}
                        sessionUser={props.sessionUser}
                        updatePage={props.updatePage}
                        timeStamp={timelinePost.timeStamp}
                        type={timelinePost.type}
                        data={timelinePost}
                        updateTimelinePost={updateTimelinePost}
                        setCurrentTimelinePost={setCurrentTimelinePost}
                        setShowDetails={setShowStatusDetail}
                        session={props?.session}
                    />
                );
            }else{
                // timelineBlock.push(
                //     <TimelinePost
                //         key={key}
                //         policyKey={timelinePost.key}
                //         objectData={timelinePost}
                //         changeActivityView={props.changeActivityView}
                //         mobileDevice={props.mobileDevice}
                //         objectType={"Lead"}
                //         leadID={params.leadID}
                //         setCurrentStatusKey={setCurrentStatusKey}
                //         setShowStatusDetail={setShowStatusDetail}
                //     />
                // );

                timelineBlock.push(
                    <DefaultTimelinePost
                        key={key}
                        timeStamp={timelinePost.timeStamp}
                        type={timelinePost.type}
                        data={timelinePost}
                        status={timelinePost.status}
                        timelineType="lead"
                        setCurrentTimelinePost={setCurrentTimelinePost}
                        setShowDetails={setShowStatusDetail}
                        session={props?.session}
                    />
                );
            }
        };

        return (
            <div className="singleTimeline g b bR t b fC cC">
                {timelineBlock}
                <div className="timelineIssueDate">
                    <div className="idleStamp">
                        <MoreVertIcon/>
                    </div>
                    {currentLeadInfo.dateCreated ? "Submission Date: " + timeConverter(currentLeadInfo.dateCreated) : null }
                </div>
            </div>
        )
    }

    function timeConverter(currentTime){
        let timeStamp =parseInt(Date.parse(currentTime) / 1000);
        timeStamp = new Date(timeStamp * 1000);
        timeStamp = (timeStamp.getUTCMonth()+1) + "/" + timeStamp.getUTCDate() + "/" + timeStamp.getUTCFullYear();

        return timeStamp;
   }

    function handleClose(evt){
        if (!evt.target.classList.contains('timelinePostDetails') && !evt.target.closest('.timelinePostDetails')) {
            setShowStatusDetail(false);
        }

        if(evt.target.className === "leadOptionsBoard"){
            setShowUpdateStatusPanel(false);
            setShowLeadNotesPanel(false);
            setShowAssignPanel(false);
            setShowSendAppPanel(false);
        }
    }

    function insertNewTimelinePost(newTimelinePost){
        setLeadActivityTimeline(prevTimeline => {
          const newTimeline = [...prevTimeline];
          newTimeline.push(newTimelinePost);
          setTimelineKeyTracker(prev => prev + 1);
          return newTimeline;
        });
    }

    function updateTimelinePost(key, attr, newVal, followUpDateTime){
        setLeadActivityTimeline(prevTimeline => {
            prevTimeline[key][attr] = newVal;
            return prevTimeline;
        });

        if(attr === "checkStatus" && Date.parse(followUpDateTime) < new Date().getTime()){
            if(newVal === "Completed"){
                props.setLeadsList(prevList => {
                    const updatedList = [...prevList];
                    const index = updatedList.findIndex(item => item.leadID === tempCurrentLeadInfo.leadID);
                    updatedList[index].numMissedFollowUps = updatedList[index].numMissedFollowUps - 1;
                    return updatedList;
                });
            }

            if(newVal === "Open"){
                props.setLeadsList(prevList => {
                    const updatedList = [...prevList];
                    const index = updatedList.findIndex(item => item.leadID === tempCurrentLeadInfo.leadID);
                    updatedList[index].numMissedFollowUps = updatedList[index].numMissedFollowUps + 1;
                    return updatedList;
                });
            }
        }
    }

    function updateTempCurrentLead(evt){
        setTempCurrentLeadInfo(tempCurrentLeadInfo => ({...tempCurrentLeadInfo, [evt.target.name]: evt.target.value }));
    }

    function updateAssignList(newList){
        setCurrentLeadInfo(currentLeadInfo => {
            currentLeadInfo.assignedUsersIDs = newList;
            return currentLeadInfo;
        });
        setTempCurrentLeadInfo(tempCurrentLeadInfo => {
            tempCurrentLeadInfo.assignedUsersIDs = newList;
            return tempCurrentLeadInfo;
        });

        updateLead();
    }

    function currentValue(attr){
        if(readOnlyStatus){
            if(attr === "leadDeathBenefit" || attr === "calculatorEstimatedHeavilyImpaired" || attr === "calculatorEstimatedSlightlyImpaired" || attr === "calculatorEstimatedStandard" || attr === "calculatorEstimatedTerminal"){
                return convertIntToCurrency(currentLeadInfo[attr]);
            }
            return currentLeadInfo[attr];
        }
        return tempCurrentLeadInfo[attr];
    }

    function updateLead(){
        var sendingLeadBlock = {...tempCurrentLeadInfo};
        delete sendingLeadBlock.currentPosition;
        delete sendingLeadBlock.dateCreated;

        const paramVals = {
            "leadBlock" : sendingLeadBlock
        };

        props.session?.env?.functions?.buildFetchRequest("updateLead", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){
                    if(tempCurrentLeadInfo.uploadError !== undefined){
                        props.setCurrentLeadInfo(currentLeadInfo => ({
                            ...currentLeadInfo, uploadError: undefined
                        }));
                    }

                    props.setLeadsList(prevList => {
                        const updatedList = [...prevList];
                        const index = updatedList.findIndex(item => item.leadID === tempCurrentLeadInfo.leadID);
                        updatedList[index] = { ...tempCurrentLeadInfo };
                        return updatedList;
                    });
                }else{
                    var errorLeadBlock = {...tempCurrentLeadInfo};
                    errorLeadBlock.uploadError = true;

                    props.setLeadsList(prevList => {
                        const updatedList = [...prevList];
                        const index = updatedList.findIndex(item => item.leadID === tempCurrentLeadInfo.leadID);
                        updatedList[index] = { ...errorLeadBlock };
                        return updatedList;
                    });
                }
                setReadOnlyStatus(true);
        });
    }

    function saveEligibility(){
        const initialLeadObj = JSON.parse(JSON.stringify(currentLeadInfo));
        const tempLeadObj = JSON.parse(JSON.stringify(tempCurrentLeadInfo));

        delete initialLeadObj["lastUpdateStatusTimeRaw"];
        delete tempLeadObj["lastUpdateStatusTimeRaw"];

        if(JSON.stringify(initialLeadObj) === JSON.stringify(tempLeadObj)){
            return " inactive";
        }

        return false;
    }

    function displayAssignPrompt() {
        if (Object.keys(currentLeadInfo).length === 0) {
          return "Importing Assigned List";
        }
        var assignedUsersIDs = currentLeadInfo.assignedUsersIDs.filter(entry => entry.trim() !== '');
        var totalAssigned = assignedUsersIDs.length;

        if (props.mobileDevice) {
          return "Assigned List";
        }
      
        if (totalAssigned === 0) {
          return "Click to assign";
        }
      
        var returnPrompt = "Assigned to";
        
        if (assignedUsersIDs.includes(props.sessionUser.userID.toString())) {
          returnPrompt += " you";
          totalAssigned--; // Exclude the current user from the count
        }
      
        if (totalAssigned === 1) {
          return returnPrompt + " 1 other";
        }
      
        return returnPrompt + ` ${totalAssigned} others`;
    }

    function currentActivity(){

    }
    
    function updateActivityView(){

    }

    useEffect(() => {
        if(Object.keys(props.leadsList).length > 0){
            let currentLead = props.leadsList.find(lead=>{
                return lead.leadID ==params.leadID }) || {}
            setCurrentLeadInfo(currentLead);
            setTempCurrentLeadInfo(currentLead);
            props.navigation.setCurrentLead(currentLead);
        }
    }, [props.leadsList]);

    useEffect(() => {
        loadLeadActivity();
    },[]);

    return (
        <div className="policiesOverview moduleBlock">
            <div className="policiesDetails">
                <div className="policiesDetailsBlock g">
                    {showAssignPanel && Object.keys(currentLeadInfo).length !== 0 && props.sessionUser?.accountData?.[props.sessionUser?.accountID] ?
                        <AssignPanel
                            sessionUser={props.sessionUser}
                            showAssignPanel={showAssignPanel}
                            setShowAssignPanel={setShowAssignPanel}
                            handleClose={handleClose}
                            assignedList={currentLeadInfo.assignedUsersIDs}
                            leadKey={currentLeadInfo.key}
                            updateAssignList={updateAssignList}
                            session={props?.session}
                            />
                        : null
                    }
                    {/* {showStatusDetail && Object.keys(leadActivityTimeline).length !== 0 && currentStatusKey !== -1 ?
                        <StatusDetailBlock
                            policyStatusData={leadActivityTimeline[currentStatusKey]}
                            setShowStatusDetail={setShowStatusDetail}
                            objectType={"Lead"}
                            />
                        : null
                    } */}
                    {showStatusDetail && Object.keys(leadActivityTimeline).length !== 0 && currentTimelinePost &&
                        <div className="overlay f" onClick={(e)=>{handleClose(e)}}>
                            <TimelinePostDetails
                                data={currentTimelinePost}
                                setShowStatusDetail={setShowStatusDetail}
                                sessionVars={props.sessionVars}
                                session={props?.session}
                            />
                        </div>
                    }

                    { showLeadNotesPanel ?
                        <CreateLeadNote
                            showLeadOptions={showLeadNotesPanel}
                            setShowLeadOptions={setShowLeadNotesPanel}
                            currentLeadInfo={currentLeadInfo}
                            setCurrentLeadInfo={setCurrentLeadInfo}
                            handleClose={handleClose}
                            changeActivityView={props.changeActivityView}
                            leadsList={props.leadsList}
                            setLeadsList={props.setLeadsList}
                            sessionUser={props.sessionUser}
                            updatePage={props.updatePage}
                            setLeadActivityTimeline={setLeadActivityTimeline}
                            insertNewTimelinePost={insertNewTimelinePost}
                            timelineKeyTracker={timelineKeyTracker}
                            sessionVars={props.sessionVars}
                            session={props?.session}
                        />
                    : null
                    }
                    { showUpdateStatusPanel ?
                        <div className="leadUpdatePanelBoard f">
                            <UpdateLeadPanel
                                showLeadOptions={showUpdateStatusPanel}
                                setShowLeadOptions={setShowUpdateStatusPanel}
                                currentLeadInfo={currentLeadInfo}
                                setCurrentLeadInfo={setCurrentLeadInfo}
                                handleClose={handleClose}
                                changeActivityView={props.changeActivityView}
                                leadsList={props.leadsList}
                                setLeadsList={props.setLeadsList}
                                sessionUser={props.sessionUser}
                                updatePage={props.updatePage}
                                insertNewTimelinePost={insertNewTimelinePost}
                                singleLeadView={true}
                                timelineKeyTracker={timelineKeyTracker}
                                sessionVars={props.sessionVars}
                                session={props?.session}
                            />
                        </div>
                    : null }
                    {showSendAppPanel && Object.keys(currentLeadInfo).length !== 0 ?
                        <SendAppPanel
                            setShowSendAppPanel={setShowSendAppPanel}
                            handleClose={handleClose}
                            leadKey={currentLeadInfo.key}
                            sessionUser={props.sessionUser}
                            currentLeadInfo={currentLeadInfo}
                            leadID={params.leadID}
                            updatePage={props.updatePage}
                            sessionVars={props.sessionVars}
                            session={props?.session}
                        />
                        : null
                    }
                    <div className="singleLeadOptionBar g gR1 cC fR">
                        <div className="cL f">
                            <Link
                                to="/leads"
                                className="inlineNav g cC"
                                onClick={ () => {
                                    props.changeActivityView("dashboard");
                                }}
                            >
                                <div className="goBackBtn">
                                    <div className="goBackBtnInner cC g f fR fC dP">
                                        <ArrowBackIosNewIcon/>
                                    </div>
                                </div>
                                <div className="inlineNavDirection">
                                    All Leads
                                </div>
                            </Link>
                        </div>
                        { props.mobileDevice ? 
                            <div className="switchViewBtnContainer g f">
                                <div className={"switchViewBtn f g cC s e " + currentActivity("details")} onClick={() => {updateActivityView("details")}}>
                                    <div className="switchViewBtnOption">
                                        Details
                                    </div>
                                    <div className="inlineBtnIcon"><FolderIcon/></div>
                                </div>
                                <div className={"switchViewBtn f g cC s e " + currentActivity("timeline")} onClick={() => {updateActivityView("timeline")}}>
                                    <div className="switchViewBtnOption">
                                        Timeline
                                    </div>
                                    <div className="inlineBtnIcon"><TimelineIcon/></div>
                                </div>
                            </div>
                        :
                            null
                        }
                        <div className="cR f e">
                            <div className="singleLeadActionBar ns"
                                onClick={!currentLeadInfo?.assignedUsersIDs || !props.sessionUser?.accountData?.[props.sessionUser?.accountID] ? null : ()=>{setShowAssignPanel(true)}}>
                                <div className="assignedIcon">
                                    <AssignmentIcon/>
                                </div>
                                <div className="assignedToHandler">
                                    {displayAssignPrompt()}
                                </div>
                                <div className="assignedToDropdown">
                                    {showAssignPanel ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="singlePolicysDetailsBoardWrap g f">
                        { props.mobileDevice ? null :
                            <div className="policyDetailsCard bR dP">
                                <div className="profileDetails">
                                    <div className="leadDetailsHeader g bold">
                                        <div className="f cC">
                                            Client Lead
                                        </div>
                                        {currentLeadInfo?.qbLink && props?.sessionUser?.accountData[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee &&
                                            <div className="gC2 f cL">
                                                <a className="cR link" target="_blank" rel="noopener noreferrer" href={currentLeadInfo?.qbLink}>
                                                    View on Quickbase
                                                </a>
                                            </div>
                                        }
                                    </div>
                                    <div key={"form1"} className="formCanvas">
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadFirstName")} placeholder={"First Name"} name={"leadFirstName"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadLastName")} placeholder={"Last Name"} name={"leadLastName"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                        <div className="trioFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadHealthStatus")} placeholder={"Health Status"} name={"leadHealthStatus"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("gender")} placeholder={"Gender"} name={"gender"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadBirthDate")} placeholder={"Age"} name={"leadBirthDate"} readOnly={readOnlyStatus}/>
                                        </div>
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadDeathBenefit")} placeholder={"Death Benefit"} name={"leadDeathBenefit"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("policyType")} placeholder={"Policy Type"} name={"policyType"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadStatus")} placeholder={"Current Status"} name={"leadStatus"} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="policyDetailsHeader bold">
                                        Contact Information 
                                    </div>
                                    <div key={"form2"} className="formCanvas">
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadPhoneNumber")} placeholder={"Phone Number"} name={"leadPhoneNumber"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead} phoneNumber={true}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadEmail")} placeholder={"Email"} name={"leadEmail"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("leadTimezone")} placeholder={"Timezone"} name={"leadTimezone"} readOnly={readOnlyStatus} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                    </div>
                                    <div className="policyDetailsHeader bold">
                                        Calculator Results
                                    </div>
                                    <div key={"form3"} className="formCanvas">
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("calculatorEstimatedHeavilyImpaired")} placeholder={"Estimated Heavily Impaired"} name={"calculatorEstimatedHeavilyImpaired"} readOnly={true} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("calculatorEstimatedSlightlyImpaired")} placeholder={"Estimated Slightly Impaired"} name={"calculatorEstimatedSlightlyImpaired"} readOnly={true} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                        <div className="doubleFormFieldRow">
                                            <InputField loadingStatus={loadingStatus} value={currentValue("calculatorEstimatedStandard")} placeholder={"Estimated Standard"} name={"calculatorEstimatedStandard"} readOnly={true} functionPointer={updateTempCurrentLead}/>
                                            <InputField loadingStatus={loadingStatus} value={currentValue("calculatorEstimatedTerminal")} placeholder={"Estimated Terminal"} name={"calculatorEstimatedTerminal"} readOnly={true} functionPointer={updateTempCurrentLead}/>
                                        </div>
                                        <div className="leadFormBtnSection">
                                            <div className="leadTimelineBtn ns" onClick={()=>{toggleFormEdit()}}>
                                                {readOnlyStatus ? "Make Changes" : "Undo Changes" }
                                            </div>
                                            { !readOnlyStatus ?
                                                <div className={"leadTimelineBtn ns" + saveEligibility()} onClick={ saveEligibility() === " inactive" ? null : ()=>{updateLead()}}>
                                                    Save
                                                </div>
                                            : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> }
                        <div className="currentActivity bR dP">
                            { printTimeline() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleLead;

import React from 'react';

import StatusIcon from '../../StatusIcons2.js';
import { getStatusData } from '../../StatusIcons2.js';

function StatusDetails(props){
    const session = props?.session;
    const statusKeyHandler = () => {
        switch (props?.stem) {
            case "bids":
                return "taskShippingStatus";
            case "tertiary":
                return "taskShippingStatus";
            default:
                return undefined;
        }
    };
    const {
        currentCase,
        timelineItem,
    } = props?.overlayProps;
    const status = timelineItem?.[statusKeyHandler()];
    const type = timelineItem?.type;
    const originator = timelineItem?.originator;
    const statusData = getStatusData(status, type);

    const friendlyTerm = () => {
        if(type === "Response - Request") return "Response";

        return type ?? status ?? "Unknown Status"
    }

    const icon = () => {
        if (statusData?.icon && typeof statusData?.icon === 'object' && timelineItem?.checkStatus) {
            const currentIcon = statusData?.icon?.[timelineItem?.checkStatus] || <StatusIcon status={"default"} />
            const currentColor = statusData?.color?.[timelineItem?.checkStatus] || "dimgray"; // Default to black if no color found
    
            return React.cloneElement(currentIcon, {
                sx: { color: currentColor }
            });
        }
    
        return (
            <StatusIcon
                status={status}
                type={type}
            />
        );
    };
    
    const content = () => {
        if(type === "Note") return timelineItem?.content ?? "No note content to display."
        if(type === "Response - Request") return timelineItem?.content ?? "No note content to display."
        if(type === "Request") return timelineItem?.content ?? "No note content to display."
        if(type === "Bid"){
            const bidData = () => {
                // Default to using timelineItem if values exist
                const bidAmount = timelineItem?.bidAmount || timelineItem?.content?.split(",")[0]?.trim();
                const bidRDB = timelineItem?.bidRDB || timelineItem?.content?.split(",")[1]?.trim();
            
                // Extract the message (everything after the second comma)
                const message = timelineItem?.content?.split(",").slice(2).join(",").trim();
            
                return { bidAmount, bidRDB, message };
            };

            return (
                <>
                    <div className="offerData g f dG">
                        <div className="offer f cR g dG">
                            Amount :
                            <div className="dataPoint bR">
                                {session?.env?.functions?.convertIntToCurrency(bidData()?.bidAmount)}
                            </div>
                        </div>
                        {!timelineItem?.bidRDB &&
                            <div className="offer f cR g dG">
                                RDB :
                                <div className="dataPoint bR">
                                    {session?.env?.functions?.convertIntToCurrency(bidData()?.bidRDB)}
                                    </div>
                                </div>
                            }
                    </div>
                    <div className="f tL">
                        {bidData()?.message}
                    </div>
                </>

            )
        }
        return statusArray[timelineItem?.[status]]
    }

    const checkStatus = () => {
        const checkStatus = timelineItem?.checkStatus;
        console.log(checkStatus);
        if(!checkStatus === undefined) return undefined;

        if(checkStatus === "Closed") return "Completed";
        if(checkStatus === "Open") return "Pending";
        if(checkStatus === "Denied") return "Denied";
    }

    const statusArray = {
        "On Hold" : "Awaiting definition.",
        "Pre-Priced" : "Preliminary stage where we internally estimate pricing and life expectancies.",
        "Lead" : "Policy was uploaded to the Marketplace.",
        "InitialLead" : "A lead has been sent to your account to follow up with.",
        "Contact Attempted" : "Client was reached out to. Awaiting response.",
        "Failed to Contact" : "Client was unable to be reached after attempted contact.",
        "Client Not Interested" : "Client decided not to sell or continue working with us.",
        "Unqualified Closed" : "Policy has been deemed as unqualified to continue.",
        "Application Sent" : "An initial application has been sent out and awaiting its return.",
        "Meds/Illustration Ordered" : `Medical records, policy documents and illustrations have been ordered. Subsequent steps 
                                        follow when the requested documents have returned.`,
        "Underwriting (LE's)" : `The process of selecting risks for insurance and classifying them according
                                to their degrees of insurability so that the appropriate rates may be assigned.
                                The process also includes rejection of those risks that do not qualify.`,
        "Pricing / Valuation" : `Pricing is being decided on the basis of estimations, as well as, returned documents.`,
        "Shipped" : "Your policy information has been sent out to funders to find suitable interests.",
        "Bids" : "This is the period of which reviewing of offers and bids take place.",
        "Bidding" : "This case has been moved into the bidding state.",
        "Offer Letter Out / CRF" : "Offer Letter and Contract Request forms have been sent out and awaiting their signed return.",
        "Offer Letter In" : "Offer Letter and Contract Request forms have been returned.",
        "Contracts Out" : "Contracts and other documentation has been shipped out.",
        "Contracts Back (Digital)" : "Available digital contracts and documentation has been signed and returned.",
        "Contracts Back" : "Available physical contracts and documentation has been signed and returned.",
        "Escrow / At Carrier" : "Escrow funded; Waiting for carrier change forms to be processed.",
        "Rescission" : "This is the waiting period before everything gets finalized.",
        "Matured During Rescission" : "Policy has reached its maturity date and the insured party has passed away during a period of rescission.",
        "Invoicing" : "The buyer and seller have agreed on the terms of the sale and the seller has or plans to transfer ownership of the policy to the buyer.",
        "Closed Won" : "Congratulations! Everything has been completed.",
        "Closed Lost" : "Policy dismissed due to some underlying reason.",
        "Closed Lost to Competitor" : "Policy has been closed.",
        "Closed Lost with Bid" : "Policy has been closed.",
        "Closed Lost no bid" : "No bid was provided and/or approved.",
        "Sent to 3rd Party" : "Policy has been sent to a 3rd party.",
        "Client Deceased" : "Client passed away prior to any finalization.",
        "Follow Up" : "A follow up reminder has been created with a designated date and time.",
        "Contact Attempted 1" : "An attempt of making contact to the client has not been successful.",
        "Contact Attempted 2" : "A 2nd attempt of making contact to the client has not been successful.",
        "Contact Attempted 3" : "A 3rd attempt of making contact to the client has not been successful.",
        "Application Received" : "The initial application has been received."
    };

    // function renderDetailBlock(){
    //     var returningTemplate = [];
    //     var currentStatus = props.policyStatusData?.status;
    //     if(props?.objectType === "Lead" && props.policyStatusData?.status === "Lead"){
    //         currentStatus = "InitialLead";
    //     }

    //     if(props.policyStatusData?.type === "Status"){


    //         returningTemplate.push(
    //             <div key={0} className="statusDetailBody">
    //                 <div className="statusBodyIconWrap">
    //                     <div className="statusDefinitionTitle">
    //                         {props.policyStatusData.status === "Lead" && props.objectType !== "Lead" ?
    //                             "Sent to Marketplace" 
    //                         : props.policyStatusData.status
    //                         }
    //                     </div>
    //                     <div className="statusBodyIconInnerWrap">
    //                         <div className="statusBodyIcon">
    //                             <StatusIcon currentStatus={props.policyStatusData.status} type={"Status"}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="statusDefinition">
    //                     <div className="statusNameOfSender">
    //                         {"Updated by: " + props.policyStatusData.nameOfSender}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData.timeStamp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData.timeStamp).toLocaleTimeString("en-US")}
    //                     </div>

    //                     <div className="statusDefinitionText">
    //                         {statusArray[currentStatus]}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     }

    //     if(props.policyStatusData?.type === "Note"){
    //         const sanitizedText = xss.filterXSS(props.policyStatusData?.status);

    //         returningTemplate.push(
    //             <div key={0} className="statusDetailBody">
    //                 <div className="statusBodyIconWrap">
    //                     <div className="statusDefinitionTitle">
    //                         {"Direct Note"}
    //                     </div>
    //                     <div className="statusBodyIconInnerWrap">
    //                         <div className="statusBodyIcon">
    //                             <StatusIcon currentStatus={props.policyStatusData?.status} type={"Note"}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="statusDefinition">
    //                     <div className="statusNameOfSender">
    //                         {"From: " + props.policyStatusData?.nameOfSender}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData?.timeStamp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData?.timeStamp).toLocaleTimeString("en-US")}
    //                     </div>

    //                     <div className="statusDefinitionText">
    //                         {HtmlReactParser(sanitizedText)}
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     if(props.policyStatusData?.type === "Follow Up"){
    //         function displayFollowUpcheckStatus(){
    //             var returningCheckStatus = '';
    //             if(props.policyStatusData?.checkStatus === "Completed"){
    //                 returningCheckStatus = "Completed";
    //             }

    //             if(props.policyStatusData?.checkStatus === "Open"){
    //                 returningCheckStatus = "Awaiting";
    //             }

    //             if(Date.parse(props.policyStatusData?.followUpDateTime) < new Date().getTime() && props.policyStatusData?.checkStatus === "Open"){
    //                 returningCheckStatus = "Missed";
    //             }

    //             return returningCheckStatus;
    //         }

    //         returningTemplate.push(
    //             <div key={0} className="statusDetailBody">
    //                 <div className="statusBodyIconWrap">
    //                     <div className="statusDefinitionTitle">
    //                         {"Follow Up"}
    //                     </div>
    //                     <div className="statusBodyIconInnerWrap">
    //                         <div className="statusBodyIcon">
    //                             <StatusIcon currentStatus={props.policyStatusData?.status} type={"Follow Up"}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="statusDefinition">
    //                     <div className="statusNameOfSender">
    //                         {"Created by: " + props.policyStatusData?.nameOfSender}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData?.timeStamp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {new Date(props.policyStatusData?.timeStamp).toLocaleTimeString("en-US")}
    //                     </div>
    //                     <div className="followUpTimeDetails">
    //                         {"Follow Up time set up for "}
    //                         {new Date(props.policyStatusData?.followUpDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
    //                         {" at " + new Date(props.policyStatusData?.followUpDateTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
    //                     </div>
    //                     <div className="statusDefinitionText">
    //                         {props.policyStatusData?.status}
    //                     </div>
    //                     <div className="followUpToggle">
    //                         {props.policyStatusData?.checkStatus ?
    //                             displayFollowUpcheckStatus()
    //                             : null
    //                         }
                            
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     if(props.policyStatusData?.type === "Payment"){
    //         returningTemplate.push(
    //             <div key={0} className="statusDetailBody">
    //                 <div className="statusBodyIconWrap">
    //                     <div className="statusDefinitionTitle">
    //                         Payment
    //                     </div>
    //                     <div className="statusBodyIconInnerWrap">
    //                         <div className="statusBodyIcon">
    //                             <StatusIcon type={"Payment"}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="statusDefinition">
    //                     <div className="statusNameOfSender">
    //                         {"Date Paid: " + new Date(props.policyStatusData?.dateCreated).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
    //                     </div>
    //                     <div className="statusTimeStamp">
    //                         {props.policyStatusData.datePremiumApplied === '' ?
    //                             "Date Applied: Pending"
    //                         :
    //                             "Date Applied:" + new Date(props.policyStatusData?.datePremiumApplied).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    //                         }
    //                     </div>
    //                     <div className="followUpTimeDetails">
    //                         {"Amount paid: " + session?.env?.functions?.convertIntToCurrency(props.policyStatusData?.amountPaid)}
    //                     </div>
    //                     <div className="statusDefinitionText">
    //                         A payment has been made towards the policy. Date paid and date applied may not be the same.
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     return returningTemplate;
    // }

    return (
        <div className="statusDetails g dP">
            <div className={`definition g f cC fR dP dG bR${timelineItem?.checkStatus ?
                ` ${timelineItem?.checkStatus}` : ''}${timelineItem?.type ?
                    ` ${timelineItem?.type}` : ''}`}>
                <div className="g f cC fR fC icon">
                    {icon()}
                </div>
                <div className={`format g f fC cL dG ${statusData?.type ?? "status"}`} onClick={()=>{console.log(props?.overlayProps);}}>
                    <div className="header g f">
                        <div className="name f cL bold g dG">
                            <div className="f cL">
                                {friendlyTerm()}
                            </div>
                            <div className="f cL">
                                {statusData?.altIcon &&
                                    React.cloneElement(statusData?.altIcon, {
                                        sx: { color: statusData?.color?.[timelineItem?.checkStatus] }
                                    })
                                }
                            </div>
                        </div>
                        <div className="date f cR g">
                            <div className="f bR">
                                {session?.env?.functions?.reformatDate(timelineItem?.timeStamp)}
                            </div>
                            <div className="f tR">
                                {session?.env?.functions?.reformatDate(timelineItem?.timeStamp, "timestamp")}
                            </div>
                        </div>
                    </div>
                    {statusData?.type === "response" &&
                            <div className="subHeader f cL">
                                {`Reply to Your ${statusData?.responseType}:`}
                            </div>
                    }
                    {content()}
                    {timelineItem?.checkStatus &&
                        <div className="f cR">
                            <div className="checkStatus bR bold fR">
                                {checkStatus()}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default StatusDetails;
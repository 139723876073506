import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone';

import './SideBar.css';

function SideBar(props){
    const session = props?.session;

    const navigation = {...session?.navigation?.data}
    function updateInstance(instance){
        navigation.history.push(instance)
        session?.set("navigation",
            ["currentInstance", 'history'],
            [instance, navigation.history]
        );
    }

    function printPages(){

        let returningPages = [];

        if(session?.navigation?.functions?.verifyPageAccess("dashboard")){
            returningPages.push(
                <Link
                    key="home"
                    to="/dashboard"
                    // className={"sideBarBtn fC f g cC" + props.maintainSelectedPage("home")}
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "dashboard" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "dashboard", "instance" : "all"});}}
                >
                    <DashboardTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Home
                        </div>
                    </div>
                </Link>
            )
        }

        if(session?.navigation?.functions?.verifyPageAccess(["origination", "servicing"])){
            returningPages.push(
                <Link
                    key="policies"
                    to="/policies"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "policies" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "policies", "instance" : "all"});}}
                >
                    <PolicyTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Policies
                        </div>
                    </div>
                </Link>
            );
        }

        if(session?.navigation?.functions?.verifyPageAccess("upload")){
            returningPages.push(
                <Link
                    key="upload"
                    to="/upload"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "upload" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "upload", "instance" : "all"});}}
                >
                    <UploadTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Upload
                        </div>
                    </div>
                </Link>
            );
        }

        if(session?.navigation?.functions?.verifyPageAccess("leads")){
            returningPages.push(
                <Link
                    key="leads"
                    to="/leads"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "leads" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "leads", "instance" : "all"});}}
                >
                    <RingVolumeTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Leads
                        </div>
                    </div>
                </Link>
            );
        }

        if(session?.navigation?.functions?.verifyPageAccess("bids")){
            returningPages.push(
                <Link
                    key="bids"
                    to="/bids"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "bids" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "bids", "instance" : "all"});}}
                >
                    <StorefrontTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            {session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ? "Cases" : "Bids"}
                        </div>
                    </div>
                </Link>
            );
        }

        if(session?.navigation?.functions?.verifyPageAccess("tertiary")){
            returningPages.push(
                <Link
                    key="tertiary"
                    to="/tertiary"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "tertiary" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "tertiary", "instance" : "all"});}}
                >
                    <SwapHorizontalCircleTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Tertiary
                        </div>
                    </div>
                </Link>
            );
        }

        if(session?.navigation?.functions?.verifyPageAccess("reporting")){
            returningPages.push(
                <Link
                    key="reporting"
                    to="/reporting"
                    className={`sideBarBtn fC f g cC${session?.navigation?.data?.currentInstance?.view === "reporting" ? " selected" : ''}`}
                    onClick={() => {updateInstance({view : "reporting", "instance" : "all"});}}
                >
                    <AnalyticsTwoToneIcon/>
                    <div className="cC f oH">
                        <div className="gCW">
                            Reporting
                        </div>
                    </div>
                </Link>
            );
        }

        return returningPages;
    }

    if(printPages().length > 1){
        return (
            <div className={"sideBar g ns fR pC" + printPages().length}>
                {printPages()}
            </div>
        )
    }
}

export default SideBar;

/*<div className={"sideBarBtn" + maintainSelectedPage("analytics")}>
                    <div className="sideBarBtnInner">
                        <div className="sideBarBtnInnerWrap">
                            <AnalyticsIcon/>
                        </div>
                    </div>
                    Analytics
                </div>*/
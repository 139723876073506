
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandCircleDownTwoToneIcon from '@mui/icons-material/ExpandCircleDownTwoTone';
import RadioButtonCheckedTwoToneIcon from '@mui/icons-material/RadioButtonCheckedTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import EqualizerTwoToneIcon from '@mui/icons-material/EqualizerTwoTone';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';

function DownloadBtn(props){
    const session = props?.session;
    const existingList = Object.keys(props?.formatList || {}).length && props?.formatList;

    const [loadingStatus, setLoadingStatus] = useState(false);

    const itemHandler = {
        "schedule" : {
            "icon" : <ListAltTwoToneIcon/>
        },
        "graph" : {
            "icon" : <EqualizerTwoToneIcon/>
        },
        "dataTape" : {
            "icon" : <TextSnippetTwoToneIcon/>
        },
        "report" : {
            "icon" : <AnalyticsTwoToneIcon/>
        }
    }

    return (
            <div
                className={`btn download multi bold bR f ns g dG fR${props?.inactive ? " inactive" : " p"}${props?.loadingStatus ? " progress" : ''}`}
                onClick={() => !existingList && props?.onClickDownload()}
                title={props?.showMenu ? undefined : "Download"}
            >
                <div className={`g f dG cC fR ${props?.loadingStatus ? '' : " s"}`}>
                    {props?.loadingStatus ?
                        <CircularProgress/>
                    :
                        <>
                            <div className="gCW f" onClick={()=>{existingList && props?.onClickDownload(props?.downloadFormat)}}>
                                Download
                            </div>
                                {existingList ?
                                    props?.showMenu ?
                                        <KeyboardArrowUpRoundedIcon
                                            onClick={(e) => !props?.inactive && (props?.setShowMenu(!props?.showMenu), e?.stopPropagation())}
                                        />
                                    :
                                        <ExpandCircleDownTwoToneIcon
                                            onClick={(e) => !props?.inactive && (props?.setShowMenu(!props?.showMenu), e?.stopPropagation())}
                                        />
                            :
                                <DownloadIcon/>}
                        </>
                    }

                </div>
                {(existingList && props?.showMenu) ? (
                    <div className="download multi list g f dG bR">
                        {existingList.map((item, index) => (
                            <div
                                key={index}
                                className={`item g p bR dP dG${props?.preferredFormat === item?.format ? " selected" : ''}`}
                                onClick={()=>{
                                    item?.onClick();
                                }}
                                title={item?.friendlyTerm}
                            >
                                {props?.preferredFormat === item?.format ?
                                    <RadioButtonCheckedTwoToneIcon/>
                                :
                                    <RadioButtonUncheckedTwoToneIcon/>
                                }
                                <div className="f gCW cC">
                                    {item?.friendlyTerm}
                                </div>
                                <div className="cC f fR fC gC3">
                                    {itemHandler?.[item?.format]?.icon}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
      );
      
}

export default DownloadBtn;
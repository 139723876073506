import React from 'react';
// import { Link } from 'react-router-dom';

import StatusIcon from '../StatusIcons';
// import LeadsIcon from './LeadsIcons';

// import CircleIcon from '@mui/icons-material/Circle';
// import HtmlReactParser from 'html-react-parser';
// import xss from 'xss';

import { timeStamp, convertIntToCurrency, dateFormat } from '../../envFunctions.js';
// import { dateFormat } from '../envFunctions.js';

import './TimelinePost.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';

import { useState, useEffect } from 'react';

function ServicingTimelinePost(props){
    useEffect(() => {
       // if(props.type === "Closed"){
        //    console.log(props);
       // }
    }, []);

    if(props.type === "VOC"){
        let checkStatus = props.data?.taskStatus?.toLowerCase();

        if(checkStatus === "closed"){
            checkStatus = "completed";
        }
        if(checkStatus === "new"){
            checkStatus = "open";
        }
        if(checkStatus === "progress"){
            checkStatus = "submitted";
        }
        if(checkStatus === "problem"){
            checkStatus = "issue";
        }
        return (
            <div className="voc timelinePostBlock g bR ns p" onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
                <div className="header g">
                    <div className="cC">
                        {props.data?.readStatus === false && 
                            <div className="cC">
                                <div className="notificationAlert">
                                </div>
                            </div>
                        }
                    </div>
                    <div className="cL f bold">
                        Verification
                    </div>
                    <div className="cR f e">
                        {props.data?.completedDate ?
                            timeStamp(props.data?.completedDate, props.mobileDevice)
                        : 
                            timeStamp(props.data?.dateCreated, props.mobileDevice)
                        }
                    </div>
                </div>
                <div className="divider">
                </div>
                <div className="content g">
                    <div className={"cC " + checkStatus}>
                        <StatusIcon
                            currentStatus={props.status}
                            type={props.type}
                        />
                    </div>
                    <div className="cL">
                        {props.type}
                    </div>
                </div>
                <div className="details g b">
                    <div className="cL gC2">
                        COI: 
                        <div className="cL s f">
                            {convertIntToCurrency(props.data?.currentCOI, props.mobileDevice, true)}
                        </div>
                    </div>
                    <div className="cC">
                        AV: 
                        <div className="cL s f">
                            {convertIntToCurrency(props.data?.accountValue, props.mobileDevice, true)}
                        </div>
                    </div>
                    <div className="cC">
                        CSV: 
                        <div className="cL s f">
                            {convertIntToCurrency(props.data?.cashValue, props.mobileDevice, true)}
                        </div>
                    </div>
                    <div className="cR e">
                        <div className="cC">
                            {checkStatus === "open" && <div className="cR e"><span>New</span></div>}
                            {checkStatus === "submitted" && <div className="cR e"><span>Pending</span></div>}
                            {checkStatus === "completed" && <div className="cR e"><span>Done</span></div>}
                        </div>
                        <div className={"cC " + checkStatus}>
                            <CheckCircleIcon/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(props.type === "Payment"){
        return (
            <div className="payment timelinePostBlock g bR p ns" onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
                <div className="header g">
                    <div className="cC">
                        {props.data?.readStatus === false && 
                            <div className="cC">
                                <div className="notificationAlert">
                                </div>
                            </div>
                        }
                    </div>
                    <div className="cL f bold">
                        Payment Details
                    </div>
                    <div className="cR e">
                        {timeStamp(props.timeStamp)}
                    </div>
                </div>
                <div className="divider"></div>
                <div className="content g">
                    <div className="cC">
                        <StatusIcon type={props.type} />
                    </div>
                    <div className={props.type === "Note" ? "cL dP" : "cL"}>
                        Payment
                    </div>
                    <div className="cR f e">
                        <div className="cR">
                            Paid:
                        </div>
                        <div className="s bold">
                            {convertIntToCurrency(props.data.amountPaid)}
                        </div>
                    </div>
                </div>
                { props.data.datePremiumApplied &&
                    <div className="details alt g b">
                        <div className="cC">
                            <PlaylistAddCheckCircleIcon/>
                        </div>
                        <div className="f cL">
                            Date Applied:
                            <div className="cC bold s">
                                {dateFormat(props.data.datePremiumApplied)}
                            </div>
                        </div>
                    </div>
                }
                { !props.data.datePremiumApplied && props.firstVOCDate > props.data.dateCreated &&
                    <div className="details alt g b">
                        <div className="cC prior">
                            <LowPriorityIcon/>
                        </div>
                        <div className="f cL">
                            Prior to Servicing
                        </div>
                    </div>
                }
                { !props.data.datePremiumApplied && props.soldDate && props.firstVOCDate < props.data.dateCreated &&
                    <div className="details alt g b">
                        <div className="cC closed">
                            <CheckCircleOutlineIcon/>
                        </div>
                        <div className="f cL">
                            Policy Closed
                        </div>
                    </div>
                }
                { !props.data.datePremiumApplied && props.soldDate && !props.firstVOCDate &&
                    <div className="details alt g b">
                        <div className="cC closed">
                            <CheckCircleOutlineIcon/>
                        </div>
                        <div className="f cL">
                            Policy Closed
                        </div>
                    </div>
                }
                { !props.data.datePremiumApplied && !props.soldDate && props.firstVOCDate < props.data.dateCreated &&
                    <div className="details alt g b">
                        <div className="cC closed">
                            <VerifiedUserIcon/>
                        </div>
                        <div className="f cL">
                            Pending VOC
                        </div>
                    </div>
                }
                { !props.data.datePremiumApplied && !props.soldDate && !props.firstVOCDate &&
                    <div className="details alt g b">
                        <div className="cC closed">
                            <VerifiedUserIcon/>
                        </div>
                        <div className="f cL">
                            Pending VOC
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ServicingTimelinePost;

import React from 'react';
import { useState, useEffect, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function InputField(props){
    const [tempIsPassword, settempIsPassword] = useState(props.isPassword);
    const [dropdownStatus, setDropdownStatus] = useState("dropdownList");
    const [errorStatus, setErrorStatus] = useState(false);
    const [showPasswordReq, setShowPasswordReq] = useState(false);
    const [isDropdownVisible, setDropdownVisibility] = useState(false);

    const handleInputClickOrFocus = () => {
        setDropdownVisibility(true);
    };

    const handleInputBlur = () => {
        setDropdownVisibility(false);
    };

    const handleIconClick = () => {
        if(!props.readOnly){
            setDropdownVisibility((prevVisibility) => !prevVisibility);
        }
    };

    function isNullOrWhiteSpace(str) {
        if(typeof str === "number"){
            return false;
        }

        return typeof str !== 'string' || !str.trim();
    }

    var matchingDropDown = null;

    useEffect(()=>{
        if(props.forceTracker !== props.name){
            toggleDropdown(true);
        }
    }, [props.forceTracker]);

    useEffect(()=>{
        if(props.returnDataFunction){
            props.returnDataFunction(props.returnDataKey, errorStatus);
        }
    }, [errorStatus]);

    var inputStatus = null;
    var visibleStatus = true;
    var specialField = [];
    var placeholder = props.placeholder;
    var placeholderDisplay = props.placeholder;
    var value = !isNullOrWhiteSpace(props.value) ? props.value : '';
    var readOnlyStatus = props.readOnly;
    var autoComplete = props.autoComplete;

    if(placeholder === "Password" && props.requirements !== undefined){
        if(value === ''){
            if(showPasswordReq){
                setShowPasswordReq(false);
            }
        }else{
            if(!showPasswordReq){
                setShowPasswordReq(true);
            }
        }
        //placeholder = "Minimum 6 characters, 1 special character & 1 capital";
    }

    function showPasswordRequirements(){

    }

    function passwordEligible(value){
        var passwordStatusMessage = '';
        var specialChars = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
        var strength = 0;
        if (value.match(/[a-z]+/)) {
            strength += 1;
        }
        if (value.match(/[A-Z]+/)) {
            strength += 1;
        }
        if (value.match(/[0-9]+/)) {
            strength += 1;
        }
        if (specialChars.test(value)) {
            strength += 1;
        }
        
        if (value.length < 8) {
            strength -= 1;
            passwordStatusMessage = "minimum number of characters is 8";
        }

        if(strength === 4){
            return '';
        }

        return " active";
    }

    function toggleShowPassword(){
        if(tempIsPassword === ''){
            settempIsPassword(true);
        }else{
            settempIsPassword('');
        }
    }


    function getAge(dateString){
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    function toggleDropdown(force){
        if(readOnlyStatus){
            return;
        }
        if(force){
            setDropdownStatus("dropdownList");
            return;
        }
        if(dropdownStatus === "dropdownList"){
            setDropdownStatus("dropdownList active");
        }else{
            setDropdownStatus("dropdownList");
        }
    }

    if(autoComplete === undefined){
        autoComplete = true;
    }

    if(readOnlyStatus !== true){
        inputStatus = "active";
    }

    if(props.loadingStatus){
        inputStatus = "loading";
        placeholder = "";
        value = "";
    }

    if(props.preface){
        inputStatus += " preFace";
    }

    if(isNullOrWhiteSpace(props.value)){
        visibleStatus = false;
    }

    if(props.value === undefined){
        visibleStatus = false;
    }

    if(value !== ''){
        placeholderDisplay = placeholderDisplay.replace("*", '');
        placeholder = placeholder.replace("*", '');
    }

    if(props.type !== undefined && props.type !== "number"){
        let iconColor = "gray";

        if(props.type === "date" || props.type === "dateWithAge"){
            let color = "gray";
            if(value !== '' && !readOnlyStatus){
                iconColor = "#00BFFF";
                color = "black";
            }

            let age = getAge(value);

            specialField.push(
                <input
                    key={0}
                    type="date"
                    className={inputStatus}
                    name={props.name}
                    readOnly={readOnlyStatus}
                    value={value}
                    onChange={(evt) => props.functionPointer === undefined ? null : props.functionPointer(evt, props?.setter)}
                    autoComplete={autoComplete}
                    style={{color : color}}
                />
            );

            if(!visibleStatus){
                specialField.push(
                    <div
                        key={1}
                        className="datePlaceholder">
                            {placeholder}
                    </div>
                );
            }

            specialField.push(<CalendarMonthIcon key={2} sx={{ color: iconColor }}/>);

            if(props.type === "dateWithAge" && !isNaN(age)){
                if(age > 0){
                    specialField.push(
                        <div key={3} className="dateAge">
                            {"Age: " + age}
                        </div>
                    );
                }else{
                    specialField.push(
                        <div key={4} className="dateAge">
                            {"Invalid Age: " + age}
                        </div>
                    );
                }
            }
        }

        if(props.type === "dropdown" || props.type === "dropdownInvert"){
            function handleMouseDown(event, item) {
                event.stopPropagation();
                props.functionPointer(event, { name: props.name, value: item.insuranceCompanyName, siblingName: "policyInsuranceCompanyID", siblingValue : item.recordID });
                setTimeout(() => {
                    toggleDropdown(false);
                    setDropdownVisibility(false);
                }, 0);
            }
   
            let listItems = [];
            let listItemDisplay = [];
            let listItemFullDisplay = [];
            let invert = '';
            if(props.type === "dropdownInvert"){
                invert = " invert";
            }
            matchingDropDown = false;

            if(!Array.isArray(props.list)){
                listItems = props.list;

                Object.keys(listItems).forEach(function(data, y){
                    Object.keys(listItems[data]).forEach(function(keyTag, i){
                        //Catch for full display ( When something is selected, show the full list of items )
                        if(keyTag === "insuranceCompanyName"){
                            listItemFullDisplay.push(
                                <div
                                    key={data}
                                    className="dropdownListItem"
                                    onMouseDown={(e) => handleMouseDown(e, listItems[data]) }
                                    >
                                    <p>{listItems[data][keyTag]}</p>
                                </div>
                            );
                        }
                        if(value === '' || typeof listItems[data][keyTag] === "string" && listItems[data][keyTag].toLowerCase().indexOf(value.toLowerCase()) !== -1 || matchingDropDown){
                            if(keyTag === "insuranceCompanyName"){
                                if(value === listItems[data][keyTag]){
                                    matchingDropDown = true;
                                }

                                listItemDisplay.push(
                                    <div
                                        key={data}
                                        className="dropdownListItem"
                                        onMouseDown={(e) => handleMouseDown(e, listItems[data]) }
                                        >
                                        <p>{listItems[data][keyTag]}</p>
                                    </div>
                                );
                            }
                        }
                    });
                });
            }else{
                listItems = props.list;

                for (let i = 0; i < listItems.length; i++){
                    listItemFullDisplay.push(
                        <div
                            key={listItems[i]}
                            className="dropdownListItem s"
                            onMouseDown ={ (e) => {
                                e.stopPropagation();
                                props.functionPointer(e, {name : props.name, value : listItems[i]});
                                setTimeout(() => toggleDropdown(false), 0); setDropdownVisibility(false);}}>
                            <span>{listItems[i]}</span>
                        </div>
                    );
                    if(value === listItems[i]){
                        matchingDropDown = true;
                    }
                    if(value === '' || typeof listItems[i] === "string" && listItems[i].toLowerCase().indexOf(value.toLowerCase()) !== -1 || matchingDropDown){
                        //Catch for full display ( When something is selected, show the full list of items )
                        listItemDisplay.push(
                            <div
                                key={listItems[i]}
                                className="dropdownListItem s"
                                onMouseDown ={ (e) => {
                                    e.stopPropagation();
                                    props.functionPointer(e, {name : props.name, value : listItems[i]});
                                    setTimeout(() => toggleDropdown(false), 0); setDropdownVisibility(false);}}>
                                <span>{listItems[i]}</span>
                            </div>
                        );
                    }
                }                
            }

            return (
                <div key={props.name} className={inputFieldContainer()} onClick={ props.onClick ? (e) => { props.onClick(e, props.name) } : null }>
                    <div className="inputFieldWrapper">
                        <div className="inputFieldContainer">
                            <div className="fieldName"> {visibleStatus ? placeholderDisplay : null} </div>
                            <input
                                className={inputStatus}
                                placeholder={placeholder}
                                name={props.name}
                                readOnly={readOnlyStatus}
                                value={value}
                                onChange={(evt) => props.functionPointer === undefined ? null : props.functionPointer(evt, props?.setter)}
                                autoComplete={autoComplete}
                                onClick={handleInputClickOrFocus}
                                onFocus={handleInputClickOrFocus}
                                onBlur={handleInputBlur}
                            />
                            {isDropdownVisible && !props.readOnly ?
                                <ArrowDropDownIcon sx={{ color: iconColor }} onClick={handleIconClick}/>
                            :
                                <ArrowDropUpIcon sx={{ color: iconColor }} onClick={handleIconClick}/>
                            }
                            {isDropdownVisible && !props.readOnly &&(
                                <div className={dropdownStatus + invert + " active"}>
                                    <div className="dropdownListInner">
                                        {matchingDropDown ? 
                                            listItemFullDisplay
                                        :
                                            listItemDisplay
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }

    function inputFieldContainer(){

        var currentContainer = "inputField";
        if(props.error){
            currentContainer = "inputField error";
            placeholderDisplay = placeholderDisplay + " " + props.error;
        }

        if(props.returnDataKey !== undefined){
            if(value === ''){
                if(props.formSubmitted){
                    currentContainer = "inputField error";
                }
                if(errorStatus){
                    setErrorStatus(false);
                }
            }else{
                if(matchingDropDown === false){
                    if(!errorStatus){
                        setErrorStatus(true);
                    }
                    currentContainer = "inputField error";
                    placeholderDisplay = placeholderDisplay + " is Not Valid";
                }else{
                    if(errorStatus){
                        setErrorStatus(false);
                    }
                }
            }
        }

        if(props.requirements === true){
            currentContainer += " passwordContainer";
        }

        return currentContainer;
    }

    return (
        props.type !== undefined && props.type !== "number" ?
            <div
                key={props.name}
                className={`${
                    inputFieldContainer()}${
                        props?.readOnly ? " inactive" : ''}${
                            props?.search ? " search" : ''}`}
                onClick={ props.onClick ? (e) => { props.onClick(e, props.name) } : null }
            >
                <div className="fieldName"> {visibleStatus ? placeholderDisplay : null} </div>
                {specialField}
            </div>
        :
        <div key={props.name} className={`${inputFieldContainer()}${value ? " g" : ''}`} onClick={ props.onClick ? (e) => { props.onClick(e, props.name) } : null }>
            {!props?.search &&
                <div className={`fieldName gCW`}>
                    {visibleStatus ? placeholderDisplay : null }
                </div>
            }            { props.preface ? <div className="inputPreface">{props.preface}</div> : null }
            <input  id={props.id ? props.id : null}
                    ref={props.refVar ? props.refVar : null}
                    className={inputStatus}
                    placeholder={placeholder}
                    name={props.name}
                    readOnly={readOnlyStatus}
                    value={value}
                    onChange={(evt) => props.functionPointer === undefined ? null : props.functionPointer(evt, props?.setter)}
                    type={ props.phoneNumber ? "tel" : props.type === "number" ? props.type : tempIsPassword ? "password" : null }
                    autoComplete={autoComplete.toString()}
                    onKeyDown={props.keyDownFunction !== undefined ? (e)=>{ props.keyDownFunction(e) } : null}/>
            { props.isPassword ?
                tempIsPassword ?
                    <VisibilityIcon className="showPasswordBtn" onClick={ toggleShowPassword }/>
                    :
                    <VisibilityOffIcon  className="showPasswordBtn" onClick={ toggleShowPassword }/>
            :
            null }
            {showPasswordReq ?
            <div className={"passwordRequirements dP" + passwordEligible(value)}>
                {"1 Uppercase, 1 lowercase, 1 symbol, 1 number and at least 8 characters."}
            </div>
            :
            null}
        </div>
     )
 }

 export default InputField;
import { useState, useEffect } from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import StatusIcon from '../StatusIcons';

function Note(props){
    const session = props?.session;
    const timelineItem = props?.timelineItem;
    const timelineData = props?.timelineData;
    const timeAgo = props?.timeAgo;

    return (
        <div className={`newTimelinePost default note g f bR p ${props?.stem}`} onClick={props?.onClick}>
            <div className="header g f fR e dG">
                {timelineItem?.readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold">
                    Note
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider">
            </div>
            <div className="content g f fR cC dG">
                <StatusIcon
                    currentStatus={timelineItem?.shippingStatus}
                    type={timelineItem?.type}
                    originator={timelineItem?.originator}
                    timelineType={timelineData?.timelineType}
                />
                <div className="cL f gC2">
                    {timelineItem?.content}
                </div>
            </div>
        </div>
    );
}

export default Note;

import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import ErrorIcon from '@mui/icons-material/Error';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';

function Response(props){
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;

    const reponseType = props?.timelineItem?.type === "Response - Bid" ? "Bid" : "Request";

    return (
        <div className={`newTimelinePost default request g f bR p ${props?.stem} ${timelineItem?.checkStatus}`} onClick={props?.onClick}>
            <div className="header g f fR e dG">
                {timelineItem?.readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold dG">
                    Response
                    <ReplyIcon/>
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider dT">
            </div>
            <div className="content g f cC dG b e">
                <TextSnippetTwoToneIcon />
                <div className="f cL gC2">
                    {`Reply to Your ${reponseType}:`}
                </div>
                <div className="vDivider dT gC1">
                </div>
                <div className="f cL gC2">
                    {timelineItem?.content}
                </div>
            </div>
        </div>
    );
}

export default Response;
import React, { useEffect, useState, useRef } from 'react';

import PrintingFunctions from '../Functions/PrintingFunctions.js';

function ResultsRenderHandler({
    session,
    sortedData,
    attributeData,
    renderFormattedColumnValue,
    // searchData,
    // updateReporting,
    baseModule,
    allColumns,
    rowRefs,
    remotePath,
    selectedReport,
  }) {
    const reporting = session?.reporting;
    // const selectedReport = reporting?.data?.selectedReport;
    const tempSummaryList = Array.isArray(sortedData?.list) ? [...sortedData?.list] : [];
    const summaryColumns = selectedReport?.columns.filter(column => column?.summarizeBy);

    // const printingFunctions = PrintingFunctions({session, cachedFormattedDates})?.functions;

    const applyHierarchicalGrouping = (list, criteria, summaries, path = []) => {
        if (criteria.length === 0) return list;

        const grouped = groupByCriterion(list, criteria[0], summaries);
        Object.keys(grouped).forEach(key => {
            const newPath = [...path, key];  // Correctly accumulating the path
            grouped[key].path = newPath;  // Store the full path in the grouped object
    
            summaries.forEach(summary => {
                const summaryValue = calculateSummary(grouped[key].list, summary.fieldBy, summary.summarizeBy);
                if (!grouped[key].summaries) grouped[key].summaries = {};
                if (!grouped[key].summaries[summary.fieldBy]) grouped[key].summaries[summary.fieldBy] = {};
                grouped[key].summaries[summary.fieldBy][summary.summarizeBy] = summaryValue;
            });
    
            // Recurse with the correct new path
            if (criteria.length > 1) {
                grouped[key].list = applyHierarchicalGrouping(grouped[key].list, criteria.slice(1), summaries, newPath);
            }
        });
    
        return grouped;
    };

    const groupByCriterion = (list, criterion, summaries) => {
        const grouped = list.reduce((acc, item) => {
            let key = "Other";
            const itemValue = item?.[criterion?.attr];

            const returnType = ['currency', 'percentage'].includes(attributeData?.[criterion?.attr]?.returnType)
            ? attributeData?.[criterion?.attr]?.returnType
            : undefined;

            const prefix = returnType === 'currency' ? '$' : undefined;
            const suffix = returnType === 'percentage' ? '%' : undefined;

            switch (criterion?.combineBy) {
                case "Equal Value":
                    key = renderFormattedColumnValue(itemValue, attributeData?.[criterion?.attr]?.returnType, selectedReport?.search) || 'Other';
                    break;
                case "First Letter":
                    key = typeof itemValue === 'string' && itemValue.length > 0 ? itemValue[0] : 'Other';
                    break;
                case "First Word":
                    key = typeof itemValue === 'string' ? itemValue.split(' ')[0] : 'Other';
                    break;
                case "Day":
                case "Month":
                case "Year":
                case "Quarter":
                    // Check if itemValue is a numeric value (potential timestamp) and parse accordingly
                    const parsedDate = typeof itemValue === 'number'
                        ? new Date(itemValue)
                        : new Date(itemValue);
                
                    if (!isNaN(parsedDate.getTime())) { // Confirm the date is valid
                        const monthNames = ["January", "February", "March", "April", "May", "June",
                                            "July", "August", "September", "October", "November", "December"];
                        switch (criterion?.combineBy) {
                            case "Day":
                                key = parsedDate.getDate().toString();
                                break;
                            case "Month":
                                key = monthNames[parsedDate.getMonth()]; // Get the full month name
                                break;
                            case "Year":
                                key = parsedDate.getFullYear().toString();
                                break;
                            case "Quarter":
                                const month = parsedDate.getMonth() + 1;
                                const quarter = `Q${Math.ceil(month / 3)}`;
                                key = `${quarter} ${parsedDate.getFullYear()}`;
                                break;
                        }
                    } else {
                        key = "Invalid Date"; // Provide a fallback for invalid dates
                    }
                    break;
                case "0.1":
                case "1":
                case "10":
                case "100":
                case "1,000":
                case "10,000":
                case "100,000":
                case "1,000,000":
                    const threshold = parseFloat(criterion?.combineBy.replace(/,/g, ''));
                    const numericItemValue = parseFloat(itemValue);
                    if (!isNaN(numericItemValue)) {
                        const rangeStart = threshold * Math.floor(numericItemValue / threshold);
                        const rangeEnd = rangeStart + threshold;
                        key = `${prefix ?? ''}${rangeStart.toLocaleString()}${suffix ?? ''} - ${prefix ?? ''}${rangeEnd.toLocaleString()}${suffix ?? ''}`;
                    }
                    break;
            }

            if (!acc[key]) {
                acc[key] = {
                    list: [],
                    rawList: [],
                    groupBy: criterion.attr,
                    id: criterion?.id,
                    summaries: {},
                    rangeInfo: {
                        range: key,
                        field: criterion.attr,
                        combineBy: criterion.combineBy
                    },
                    criterion: criterion,
                };
                summaries.forEach(summary => {
                    acc[key].summaries[summary.fieldBy] = { [summary?.summarizeBy]: 0 };
                });
            }
    
            acc[key].list.push(item);
            acc[key].rawList.push(item);
    
            return acc;
        }, {});

        const sortingConfig = reporting?.data?.sorting;
        const isAttrSorted = sortingConfig?.some(sort => sort?.columnName === criterion?.attr);
        
        const sortedKeys = isAttrSorted ? Object.keys(grouped) : Object.keys(grouped).sort((a, b) => {
            const numA = Number(a.replace(/\D/g, ''));
            const numB = Number(b.replace(/\D/g, ''));
            return numA - numB; // Numerical sort for keys that contain numbers
        });
    
        const sortedGrouped = {};

        sortedKeys.forEach(key => {
            sortedGrouped[key] = grouped[key];
        });
    
        return sortedGrouped;
    };

    const calculateSummary = (list, fieldBy, summarizeBy) => {
        if (!Array.isArray(list)) { return 0; }
        const values = list.map(item => item[fieldBy]);
        const operation = summarizationOperations[summarizeBy];
        if (typeof operation !== 'function') {
            return 0;
        }

        const result = operation(values);
        return result;
    };

    const summarizationOperations = {
        Sum: list => list.reduce((acc, item) => acc + (Number(item) || 0), 0),
        Average: list => {
            const validNumbers = list.filter(item => item !== '' && item != null && !isNaN(Number(item))).map(Number);
            return validNumbers.length ? Number((summarizationOperations.Sum(validNumbers) / validNumbers.length).toFixed(6)) : 0;
        },
        Count: list => list.length,
        Min: list => {
            const validNumbers = list.filter(item => item !== '' && item != null && !isNaN(Number(item))).map(Number);
            return validNumbers.length ? Math.min.apply(null, validNumbers) : Infinity;
        },
        Max: list => {
            const validNumbers = list.filter(item => item !== '' && item != null && !isNaN(Number(item))).map(Number);
            return validNumbers.length ? Math.max.apply(null, validNumbers) : -Infinity;
        },
    };

    function calculateMaxDepth(groupedData, currentDepth = 0) {
        // Find maximum depth of the object
        return Object.values(groupedData).reduce((max, group) => {
            if (Array.isArray(group.list)) {
                return Math.max(max, currentDepth + 1);
            } else {
                return Math.max(max, calculateMaxDepth(group.list, currentDepth + 1));
            }
        }, currentDepth);
    }

    const cellClick = (summaryLevel) => {
        const showAll = summaryLevel === "all";
        const subReport = { ...selectedReport?.subReport };
        subReport.list = showAll ? [...selectedReport?.list] : summaryLevel?.["rawList" ?? "list"];
        subReport.columns = reporting?.data?.defaultColumns?.[selectedReport?.referenceStem]?.detailedArray || [];
        subReport.name = " > " + (showAll ? " All" : summaryLevel?.path.join(" > "));
    
        // Use a Set to track existing column names to avoid duplicates
        const existingColumnNames = new Set(subReport.columns.map(col => col.columnName));
    
        // Separate columns into non-custom and custom arrays
        const nonCustomColumns = [];
        const customColumns = [];
    
        // Filter columns from selectedReport to add only those that are custom and meet other criteria
        selectedReport?.columns.forEach(column => {
            // Check if the column is custom
            const isCustom = column?.custom;
            const columnNameKey = isCustom ? column?.fieldBy : column?.columnName; // For custom columns, use fieldBy as the identifier
    
            if ((column?.summarizeBy !== "Count") && !existingColumnNames.has(columnNameKey)) {
                const newColumn = {
                    id: subReport.columns.length.toString(), // Incremental ID based on the current length
                    columnName: column?.columnName ?? column?.fieldBy, // Use fieldBy for custom columns
                    friendlyTerm: attributeData?.[column?.columnName ?? column?.fieldBy]?.friendlyTerm || column?.friendlyTerm,
                    frozen: column?.frozen,
                    editable: true // Assuming all added columns are editable
                };
    
                if (isCustom) {
                    customColumns.push(newColumn);
                } else {
                    nonCustomColumns.push(newColumn);
                }
            }
        });
    
        // Add non-custom columns to the front and custom columns to the end
        subReport.columns = [...nonCustomColumns, ...subReport.columns, ...customColumns];
        // updateReporting(`${remotePath ?? ''}selectedReport`, "subReport", subReport);
        session?.set(baseModule, `${remotePath ?? ''}selectedReport.subReport`, subReport);
    };
    
    function generateSummaryRow(summaryData) {
        // Calculate final summaries based on summary data and report configuration
        const finalSummaries = Object.keys(summaryData).reduce((acc, id) => {
            const column = selectedReport.columns.find(col => col.id === id);
            if (column) {
                try {
                    acc[id] = calculateSummary(selectedReport?.list, column.fieldBy, column.summarizeBy);
                } catch (error) {
                    console.error(`Error calculating summary for column ${id}:`, error);
                }
            }
            return acc;
        }, {});

        // Generate summary row cells based on the calculated summaries and the current columns
        const summaryRowCells = allColumns.map((column, index) => {
            if (index === 0) {
                // Render "Total(s)" for the first cell
                return (
                    <div key={`summary-total`} className="dP f" style={{ gridColumn: 1 }}>
                        <div className="summaryRowItem f cC g bold bR">
                            <span className="f gCW s e">Total(s)</span>
                        </div>
                    </div>
                );
            }
    
            const finalValue = finalSummaries?.[column?.id];
            if (finalValue) {
                return (
                    <div key={`${column?.id}-${index}`} className="dP f" style={{ gridColumn: index + 1 }}>
                        <div className="summaryRowItem f cC g bold bR">
                            <span className="f gCW s e">
                                {renderFormattedColumnValue(finalValue.toString(), attributeData?.[column?.fieldBy]?.returnType, selectedReport?.search, column?.summarizeBy)}
                            </span>
                        </div>
                    </div>
                );
            }
            return null;
        }).filter(cell => cell !== null);
    
        // Construct the summary row if there are summary cells to display
        if (summaryRowCells.length > 0) {
            return (
                <div
                    key="summaryRow"
                    className="row summary g cC fR p"
                    style={{ gridTemplateColumns: `repeat(${allColumns.length}, 1fr)` }}
                    onClick={()=>{cellClick("all")}}
                >
                    {summaryRowCells}
                </div>
            );
        }
    
        return null;  // Return null if no summary cells are to be displayed
    }

    function initializeRefs(groupedData, rowRefs, path = '') {
        Object.entries(groupedData).forEach(([key, group], index) => {
            const newPath = `${path}${index}`;
            // Ensure the ref entry exists and has a structure to hold both the ref and the data
            if (!rowRefs[newPath]) {
                rowRefs[newPath] = {
                    ref: React.createRef(),
                    data: {}  // Initialize data storage
                };
            }
            
            // Assign the current group data to the ref object
            rowRefs[newPath].data = group;
    
            if (group.list && typeof group.list === 'object' && !Array.isArray(group.list)) {
                initializeRefs(group.list, rowRefs, `${newPath}-`); // Recursive call
            }
        });
    }

    function generateBlocks() {
        const activeCriteria = selectedReport?.criteria?.groupBy?.filter(criterion => 
            !criterion?.inactive && 
            typeof criterion?.attr === 'string' && criterion.attr.trim() !== '' &&
            typeof criterion?.formType === 'string' && criterion.formType.trim() !== '' &&
            typeof criterion?.combineBy === 'string' && criterion.combineBy.trim() !== ''
        ) ?? [];
        const summaryData = {}; // Initialize summary data storage for each summarizable column

        // Initialize storage for summary data for each column that requires summarization
        selectedReport?.columns?.forEach(column => {
            if (column?.fieldBy && column?.summarizeBy && column?.id) {
                summaryData[column?.id] = [];
            }
        });

        function renderGroupBlocks(groupedData, level = 0, maxDepth, refs, path = '') {
            let rowIndex = 2;
            const entries = Object.entries(groupedData);
            const hasSiblings = entries.length > 1;

            return entries.map(([key, group], index) => {
                const newPath = `${path}${index}`;
                let blockStyle = {};

                let columnWidth = `calc(100% / ${allColumns?.length - level})`;
                let isMostNested = level === maxDepth - 1;  // Check if current level is the most nested

                if (level === 0) {
                    blockStyle = { gridTemplateColumns: `${columnWidth} 1fr` };
                } else {
                    blockStyle = {
                        gridTemplateColumns: `${columnWidth} 1fr`,
                        gridColumn: '2',
                        gridRow: rowIndex.toString()
                    };
                    rowIndex++;
                }

                const customColumnCount = allColumns?.filter(column => column?.custom !== undefined)?.length;
                const baseColumn = Math.max(0, (allColumns?.length || 0) - (level + 1)) - customColumnCount + 1;
                const summaryValues = Object.entries(group.summaries || {}).map(([field, summary], index) => {

                    const summaryStyle = {
                        gridColumn: baseColumn + index,
                        gridRow: '1'
                    };

                    return (
                        Object.entries(summary).map(([sumType, value]) => {
                            const cellValue = renderFormattedColumnValue(value, attributeData?.[field]?.returnType, selectedReport?.search, sumType);

                            return (
                                <div
                                    className={`cell p s e value cC column-${field}`}
                                    style={summaryStyle}
                                    key={`${field}-${sumType}`}
                                    title={cellValue}
                                    onClick={()=>{cellClick(group)}}
                                >
                                    {cellValue}
                                </div>
                            );
                        })
                    )
                });

                let content;
                let valueFieldCount = allColumns.filter(column => column?.custom).length;
                if (Array.isArray(group?.list) && group?.list?.length > 0) {

                    const cellValues = selectedReport.columns.map((column, columnIndex) => {
                        let cellContent = (group?.summaries && group?.summaries?.[column?.fieldBy] && group?.summaries?.[column?.fieldBy]?.[column?.summarizeBy]) ?
                            group.summaries?.[column?.fieldBy]?.[column?.summarizeBy]
                        :
                            group.list?.[0]?.[column.fieldBy];

                        const cellValue = renderFormattedColumnValue(cellContent, attributeData?.[column?.fieldBy]?.returnType, selectedReport?.search, column?.summarizeBy);

                        if (column?.fieldBy) {
                            return (
                                <div
                                    key={`${key}-item-${columnIndex}`}
                                    className={`cell p s e value cC column-${column?.fieldBy}`}
                                    title={cellValue}
                                    onClick={()=>{cellClick(group)}}
                                >
                                    <span className="gCW">
                                        {cellValue}
                                    </span>
                                </div>
                            );
                        }
                    });

                    const resultsStyle = {
                        gridTemplateColumns: `repeat(${valueFieldCount}, 1fr)`,
                        gridColumn: '2',
                        gridRow: '1'
                    };

                    content = (
                        <div
                            className={`cC f g fR fC level-${level}`}
                            style={resultsStyle}
                        >
                                {cellValues}
                        </div>
                    )
                } else {
                    content = renderGroupBlocks(group?.list, level + 1, maxDepth, refs, `${newPath}-`);
                }

                function summaryGroupBlockClass(isSummarization = false) {
                    let classNames = `summaryGroupBlock g level-${level}`;
                    classNames += level % 2 === 0 ? ' even' : ' odd';
                    classNames += level === 0 ? ' parent' : '';
                    classNames += hasSiblings ? ' sibling' : '';
                    classNames += isMostNested ? ' values' : '';
                    classNames += isSummarization ? ' summarization' : '';
                    return classNames;
                }

                // const cellValue = group?.rangeInfo?.combineBy && /^[0-9,.]+$/.test(group.rangeInfo.combineBy.replace(/ /g, ''))
                const cellValue = group?.rangeInfo?.combineBy
                ? key
                : renderFormattedColumnValue(key, attributeData?.[group?.groupBy]?.returnType, selectedReport?.search);

                return (
                    <div
                        key={`${key}-${level}${path}`}
                        className={summaryGroupBlockClass()}
                        ref={rowRefs[newPath]?.ref}
                        style={blockStyle}
                        onClick={()=>{
                            console.log(rowRefs);
                        }}
                    >
                        <div
                            className="cell p s e cC"
                            onClick={()=>{cellClick(group)}}
                            title={cellValue}
                        >
                            <span className="gCW">
                                {cellValue}
                            </span>
                        </div>
                        {(summaryValues && !isMostNested) &&
                            <div
                                key={`${key}-${level}`}
                                className={summaryGroupBlockClass(true)}
                                style={{
                                    gridTemplateColumns: `repeat(${allColumns?.length - level - 1}, calc(100% / ${allColumns?.length - level - 1}))`,
                                    gridColumn: '2',
                                    gridRow: '1'
                                }}
                            >
                                {summaryValues}
                            </div>
                        }
                        {content}
                    </div>
                );
            });
        }

        if (activeCriteria.length > 0) {
            const hierarchicalGroups = applyHierarchicalGrouping(tempSummaryList, activeCriteria, summaryColumns);

            const maxDepth = calculateMaxDepth(hierarchicalGroups);
            initializeRefs(hierarchicalGroups, rowRefs);
            const blocks = renderGroupBlocks(hierarchicalGroups, 0, maxDepth, rowRefs, '');
            blocks.push(generateSummaryRow(summaryData));

            return blocks;
        }

        return [];
    }

    function generateRows() {
        const activeCriteria = selectedReport?.criteria?.groupBy?.filter(criterion => 
            !criterion?.inactive && 
            typeof criterion?.attr === 'string' && criterion.attr.trim() !== '' &&
            typeof criterion?.formType === 'string' && criterion.formType.trim() !== '' &&
            typeof criterion?.combineBy === 'string' && criterion.combineBy.trim() !== ''
        ) ?? [];
        const results = [];
        const summaryData = {}; // Initialize summary data storage for each summarizable column
    
        // Initialize storage for summary data for each column that requires summarization
        selectedReport.columns.forEach(column => {
            if (column?.fieldBy && column?.summarizeBy && column?.id) {
                summaryData[column.id] = [];
            }
        });

        const renderGroupHierarchy = (groupedData, level = 0, path = '') => {
            const rows = [];
            Object.entries(groupedData).forEach(([key, value], index) => {
                const newPath = `${path}${index}`; // Constructs the path for the current entry
    
                const rowCells = selectedReport.columns.map((col, columnIndex) => {
                    let cellContent = '';
                    let summaryInfo = value?.summaries?.[col?.fieldBy]; // Define summaryInfo within the correct scope
                    if (col?.id === value?.id) {
                        cellContent = key;
                    } else if (col?.summarizeBy && summaryInfo) {
                        cellContent = summaryInfo?.[col?.summarizeBy] || null;
                    }

                    return cellContent ? (
                        <div 
                            key={`${key}-${level}-${columnIndex}`} 
                            className={`cell cC${col?.columnName === value?.groupBy ? " group-name" : ''}`}
                            style={{ gridColumn: columnIndex + 1 }} // Directly align the cell under the corresponding header
                        >
                            {renderFormattedColumnValue(cellContent, attributeData?.[col?.fieldBy ?? col?.columnName]?.returnType, selectedReport?.search, col?.columnName ? "grouped" : undefined)}
                        </div>
                    ) : null;
                }).filter(Boolean); // Filter out null elements to not render them
    
                rows.push(
                    <div 
                        className={`row g cC`} 
                        style={{ gridTemplateColumns: `repeat(${selectedReport?.columns?.length}, 1fr)` }}
                        ref={rowRefs[newPath]?.ref}
                        onClick={() => { cellClick(value) }}
                        key={`${path}-${key}-${level}-Row`}
                    >
                        {rowCells}
                    </div>
                );
    
                if (typeof value?.list === 'object' && !Array.isArray(value?.list)) {
                    rows.push(...renderGroupHierarchy(value?.list, level + 1, `${newPath}-`));
                }
            });
    
            return rows;
        };

        if (activeCriteria.length > 0) {
            const hierarchicalGroups = applyHierarchicalGrouping(tempSummaryList, activeCriteria, summaryColumns);
            initializeRefs(hierarchicalGroups, rowRefs);
            const hierarchicalRows = renderGroupHierarchy(hierarchicalGroups);
            results.push(...hierarchicalRows);
            results.push(generateSummaryRow(summaryData));
        }
    
        return results;
    }

    const functions = {
        generateRows,
        generateBlocks,
        initializeRefs,
        applyHierarchicalGrouping,
    }

    const resultsRenderHandler = {
        functions,
    };
    
    return resultsRenderHandler;
};

export default ResultsRenderHandler;
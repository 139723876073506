import React, { useEffect, useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import PasswordReset from "./PasswordReset.js";
import Preferences from "./Preferences.js";
import Notifications from "./Notifications.js";
import CriteriaBoard from "./CriteriaBoard.js";
import ReportErrors from "./ReportErrors.js";
import SettingsMenu from "./SettingsMenu.js";
import ReportOptions from "./ReportOptions.js";
import BidTransition from './Bids/BidTransition.js';
import LineGraph from './Illustrations/LineGraph.js';
import StatusDetails from './Timeline/StatusDetails.js';

function OverlayHandler(props){
    const session = props?.session;
    const envFunctions = session?.env?.functions;
    const reporting = session?.reporting;
    const userFunctions = session?.user?.functions;
    const caseFunctions = session?.case?.functions;
    const policyHandler = session?.case?.policyHandler;
    const policyList = session?.case?.data?.policy;
    const sessionUser = props?.session?.user?.data;
    const viewType = session?.env?.viewport?.viewType;

    // const [overlayName, setOverlayName] = useState(typeof props?.overlay === 'object' ? props?.overlay?.name : (typeof props?.overlay === 'string' ? props?.overlay : undefined));
    // const [overlayProps, setOverlayProps] = useState(typeof props?.overlay === 'object' ? props?.overlay?.props : undefined);
    let overlayName;
    let overlayProps;

    // Check if overlay contains props
    if (typeof props?.overlay === 'object'){
        overlayName = props?.overlay?.name;
        overlayProps = props?.overlay?.props;
    }else if((typeof props?.overlay === 'string')){
        overlayName = props?.overlay;
    }

    const headerHandler = {
        "policyScroller" : "My Policies",
        "settings" : "User Options",
        "passwordChange" : "Password Change",
        "notifications" : "Notifications",
        "emailPreferences" : "Email Preferences",
        "criteriaHandler" : "Report Builder",
        "reportErrorHandler" : "Warning",
        "reportOptions" : "Report Options",
        "bidTransition" : `${session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid"} Transition`,
        "lineGraph" : overlayProps?.graphName ?? "Line Graph",
        "statusDetail" : "Status Details",
    }

    const moduleBlockType = {
        "settingsMenu" : " inline",
        "criteriaHandler" : " default",
        "reportOptions" : " default",
    }

    const overflowAuto = ["criteriaHandler", "reportOptions"];

    const [policyCount, setPolicyCount] = useState(Object.keys(session?.case?.policy || {}).length);

    function printPolicies() {
        return (
          <div className="list f g fC dP tO">
            {Object.entries(policyList).map(([key, policy]) => {
                let currentStatus = session?.case?.data?.currentPolicyKey === key;
                const isClosed = policyHandler?.checkClosedStatus(session?.case?.data?.trackline[policy?.relatedPolicyID]);
                const onHold = policy?.policyStatus === "On Hold";
                const policyProgress = caseFunctions.currentProgress(isClosed ? "Unqualified Closed" : policy?.policyStatus);

                function printPolicyStatus(policyStatus) {
                    if (policyStatus === "Closed Won") {
                        return "Complete";
                    } else if (isClosed) {
                        return "Closed";
                    } else if (onHold){
                        return "On Hold"
                    } else {
                        return policyHandler?.insuredFriendlyStatus[policyStatus];
                    }
                }

                function rowClass(policyStatus) {
                    const currentGroup = policyHandler?.currentGroup(policyStatus);
                    const isActive = currentStatus ? " active" : '';
                    let groupTag = '';

                    if (policyStatus === "Closed Won") {
                        groupTag = " Complete";
                    } else if (isClosed) {
                        groupTag = " Closed";
                    } else if (onHold){
                        groupTag =  " OnHold"
                    } else {
                        groupTag = " " + currentGroup;
                    }

                    return `row f cC g fR${groupTag}${isActive}`;
                }

                return (
                    <div
                        key={key}
                        className={rowClass(policy?.policyStatus)}
                    >
                        <div
                            className={`f cC g data p bold fR${viewType === "mobile" ? '' : " s e"}`}
                            onClick={() => {session?.case?.functions?.updateCurrentPolicyKey(key); session?.env?.setOverlay();}}
                        >
                            <div className="f cL oH">
                                <div className="gCW">
                                    {policy.policyNumber ?? "N/A"}
                                </div>
                            </div>
                            <div className="f cC oH">
                                <div className="gCW">
                                    {envFunctions?.convertIntToCurrency(policy?.policyDeathBenefit, viewType === "mobile" ? true : false ) ?? "-"}
                                </div>
                            </div>
                            {viewType !== "mobile" &&
                                <div className="f cC oH">
                                    <div className="gCW">
                                        {envFunctions?.reformatDate(policy?.policyIssueDate) ?? "N/A"}
                                    </div>
                                </div>
                            }
                            <div className="status f cC g fC">
                                <div className="f cC oH">
                                    <div className="gCW">
                                        {printPolicyStatus(policy?.policyStatus)}
                                    </div>
                                </div>
                                <div className="progressBar pR" style={{ backgroundColor: policyProgress?.color?.altColor }}>
                                    <div className="progress" style={{ width: policyProgress.progress, backgroundColor: policyProgress?.color?.main }}>
                                    </div>
                                </div>
                            </div>
                            {viewType !== "mobile" &&
                                <div className="checkStatus f cC">
                                    {currentStatus && <VisibilityIcon />}
                                </div>
                            }
                        </div>
                    </div>
                );
            })}
          </div>
        );
    }

    // useEffect(() => {
    //     if (typeof props?.overlay === 'object') {
    //         console.log("Have to update the props?")
    //         console.log(props?.overlay?.props);
    //         setOverlayName(props?.overlay?.name || '');
    //         setOverlayProps(props?.overlay?.props);
    //     } else if (typeof props?.overlay === 'string') {
    //         setOverlayName(props?.overlay);
    //         setOverlayProps(undefined);
    //     } else if (props?.overlay === undefined){
    //         setOverlayName(props?.undefined);
    //         setOverlayProps(undefined);
    //     }
    // }, [props]);

    useEffect(() => {
        const count = Object.keys(policyList || {}).length;
        setPolicyCount(count);
    }, [session?.case?.policy]);

    return (
        overlayName ?
            <div className="overlay cC" onClick={(e)=>{props?.handleClose(e, overlayName, props?.setter)}}>
                <div className={`moduleBlock${moduleBlockType[overlayName] ?? " default"} g oH ${overlayName}`}>
                    {overlayName !== "settingsMenu" &&
                        <div className="header g f fR cC">
                            <div className="gC2 f cC">
                                {headerHandler[overlayName]}
                            </div>
                            {/* <div className="btn close f p cC" onClick={()=>{props?.setter(false)}}> */}
                            <div className="btn close f p cC" onClick={()=>{props?.setter(false);}}>
                                <CloseRoundedIcon/>
                            </div>
                        </div>                        
                    }
                    <div className={`format g f pR fC${overflowAuto?.includes(overlayName) ? " oA" : " oH"}`}>
                        {overlayName === "policyScroller" &&
                            <div className="policies listBoard g f oH">
                                <div className="columnLabels g f cC bold dP fR">
                                    <div className={`labels g f${policyCount > 5 ? " alt" : ''}`}>
                                        <div className="f cC">
                                            {`Policy ${viewType === "mobile" ? "#" : "Number"}`}
                                        </div>
                                        <div className="f cC">
                                            Death Benefit
                                        </div>
                                        {viewType !== "mobile" &&
                                            <div className="f cC">
                                                Issue Date
                                            </div>
                                        }
                                        <div className="f cC">
                                            Progress
                                        </div>
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="body f oH b dP">
                                    <div className="content wrap f tO bR">
                                        {printPolicies()}
                                    </div>
                                </div>
                            </div>
                        }
                        {overlayName === "notifications" &&
                            <Notifications
                                session={session}
                                policyCount={policyCount}
                            />
                        }
                        {overlayName === "settings" &&
                            <div className="settingsOptions g f oH nS">
                                <div className="f cC s e g fC">
                                    <div className="accountInfo g f cC fC">
                                        <div className="f cL">
                                            {session?.user?.data?.firstName + " " + session?.user?.data?.lastName}
                                        </div> 
                                        <div className="f cL">
                                            {session?.user?.data?.email}
                                        </div>
                                    </div>
                                    <div className="options g f cC fC p">
                                        <div className="option f cC bR s e bold" onClick={()=>{session?.env?.setOverlay("emailPreferences")}}>
                                            Email Preferences
                                        </div>
                                    </div>
                                    <div className="options g f cC fC p">
                                        <div className="option f cC bR s e bold" onClick={()=>{session?.env?.setOverlay("passwordChange")}}>
                                            Change Password
                                        </div>
                                    </div>
                                </div>
                                <div className="logout g f cRe dP" onClick={()=>{session?.user?.functions?.logout()}}>
                                    <div className="btnWIcon f e bR bold cC p gC2">
                                        <div className="f cC">
                                            Logout
                                        </div>
                                        <LogoutRoundedIcon/>
                                    </div>
                                </div>
                            </div>
                        }
                        {overlayName === "passwordChange" &&
                            <PasswordReset
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "emailPreferences" &&
                            <Preferences
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "criteriaHandler" &&
                            <CriteriaBoard
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "reportErrorHandler" &&
                            <ReportErrors
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "settingsMenu" &&
                            <SettingsMenu
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "reportOptions" &&
                            <ReportOptions
                                session={session}
                                selectedReport={overlayProps?.selectedReport}
                                baseModule={overlayProps?.baseModule ?? "reporting"}
                                baseData={overlayProps?.baseData ?? reporting?.data}
                                remotePath={overlayProps?.remotePath}
                            />
                        }
                        {overlayName === "bidTransition" &&
                            <BidTransition
                                session={session}
                                selectedStatus={overlayProps?.selectedStatus ?? "Requested Info"}
                                currentCase={overlayProps?.currentCase}
                                stem={overlayProps?.stem}
                                branch={overlayProps?.branch}
                                instance={overlayProps?.instance}
                            />
                        }
                        {overlayName === "lineGraph" &&
                            <LineGraph
                                session={session}
                                overlayProps={overlayProps}
                            />
                        }
                        {overlayName === "statusDetail" &&
                            <StatusDetails
                                session={session}
                                overlayProps={overlayProps}
                            />
                        }
                    </div>
                </div>
            </div>
        :
            false
    )
};

export default OverlayHandler
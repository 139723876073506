import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import PaidIcon from '@mui/icons-material/Paid';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import GavelIcon from '@mui/icons-material/Gavel';
import TagIcon from '@mui/icons-material/Tag';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CallEndIcon from '@mui/icons-material/CallEnd';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ForwardIcon from '@mui/icons-material/Forward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import MovingIcon from '@mui/icons-material/Moving';
import TimelineIcon from '@mui/icons-material/Timeline';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import { convertIntToCurrency } from '../../envFunctions.js';
import { displayAssignedPrompt } from '../../Components/CaseOptions/CaseOptions';

import { red, purple, blue, blueGrey, green, grey, orange} from '@mui/material/colors';
import { ThumbsUpDown } from '@mui/icons-material';

function KanbanBlock(props) {
    const [kanbanBlock, setKanbanBlock] = useState(props.kanbanBlockInfo || {});
    const path = buildPath();
    const handlePhoneNumber = (event) => { event.preventDefault(); };
    const [bidVerbiage, setBidVerbiage] = useState("Bid");
    let kanbanBlockData = buildKanbanBlockData();
    let loadBlockClass = buildKanbanBlockHeader();
    let stage = buildKanbanBlockClass();

    // if(props.kanbanBlockInfo?.winningBid === true){
    //     className = " winning";
    // }

    function buildKanbanBlockHeader(){
        if(props?.type === "bid"){
            return kanbanBlock.stage;
        }

        if(props?.type === "lead"){
            return kanbanBlock.leadStatus;
        }
    }

    function buildPath(){
        if(props?.type === "bid"){
            return "/bids/" + kanbanBlock.recordID;
        }

        if(props?.type === "lead"){
            return "/leads/" + kanbanBlock.leadID;
        }
    }

    function buildKanbanBlockClass() {
        let currentStage = props?.kanbanBlockInfo?.stage;
        if(props?.stage){
            return props?.stage;
        }

        const classList = {
            stage1: ["NEW"],
            stage2: ["UNDER REVIEW"],
            stage3: ["BIDDING"],
            closing: ["CLOSING"],
            closed: ["CLOSED"],
        };

        if (currentStage === "CLOSED") {
            if (props?.kanbanBlockInfo?.shippingStatus === "Won") {
                return "won";
            } else {
                return "closed";
            }
        }

        const matchingEntry = Object.entries(classList).find(([key, values]) =>
            values.includes(currentStage)
        );

        return matchingEntry ? matchingEntry[0] : '';
    }

    function buildKanbanBlockData(){
        function printDataRow(key, icon, data){
            if(key === "phoneNumber"){
                return (
                    <div
                        key={key}
                        className="f data g"
                    >
                        <div className="f cC">
                            {icon}
                        </div>
                        <div className="f cL">
                            <span onClick={handlePhoneNumber}>
                                <input
                                    type="tel" readOnly={true}
                                    value={displaykanbanBlockAttr(kanbanBlock.leadPhoneNumber)}
                                />
                            </span>
                        </div>
                    </div>
                )
            }

            return(
                <div
                    key={key}
                    className="f data g oH"
                >
                    <div className="f cC">
                        {icon}
                    </div>
                    <div className="f cL gCW">
                        {data ? data : "N/A"}
                    </div>
                </div>
            )
        }

        if(props?.type === "bid"){
            return ([
                printDataRow("name", <PersonOutlineIcon sx={{ color: grey[800] }}/>, displayKanbanBlockAttr("fullName")),
                printDataRow("insuranceCo", <ApartmentIcon sx={{ color: blueGrey[500] }}/>, displayKanbanBlockAttr(kanbanBlock.insuranceCompany)),
                printDataRow("deathBenefit", <PaidIcon sx={{ color: green[500] }}/>, displayKanbanBlockAttr("currency")),
                printDataRow("le", <SsidChartIcon sx={{ color: orange[500] }}/>, displayKanbanBlockAttr("LE")),
                printDataRow("age", <CalendarMonthIcon  sx={{ color: purple[500] }}/>, displayKanbanBlockAttr("age")),
                printDataRow("policyNumber", <TagIcon sx={{ color: blueGrey[500] }}/>, kanbanBlock?.policyNumber)
            ]);
        }

        if(props?.type === "lead"){
            return ([
                printDataRow("phoneNumber", <TagIcon sx={{ color: blueGrey[500] }}/>, displaykanbanBlockAttr(kanbanBlock.leadPhoneNumber)),
                printDataRow("name", <PersonOutlineIcon sx={{ color: grey[800] }}/>, displayKanbanBlockAttr("fullName")),
                printDataRow("deathBenefit", <FavoriteBorderIcon sx={{ color: red[500] }}/>, displaykanbanBlockAttr(kanbanBlock.leadHealthStatus)),
                printDataRow("le", <PaidIcon sx={{ color: green[500] }}/>, displaykanbanBlockAttr("currency")),
                printDataRow("age", <CalendarMonthIcon  sx={{ color: purple[500] }}/>, displaykanbanBlockAttr("age"))
            ]);
        }
    }

    if(props.kanbanBlockType && props.kanbanBlockType !== "result"){
        loadBlockClass = props.kanbanBlockType;
    }

    const [timeDifference, setTimeDifference] = useState('');
    const [followUpTimeDifference, setFollowUpTimeDifference] = useState('');

    function displayClosedType(returnType, status) {
        const closedStatuses = {
          Won: { icon: <CelebrationIcon sx={{color:"#004d40"}}/>, text: "Won", loadBlockClass: "closedWon" },
          Closing: { icon: <GavelIcon sx={{ color: orange[500] }} />, text: "In Contracts", loadBlockClass: "closedInContracts" },
          "Lost with bid": { icon: <SentimentVeryDissatisfiedIcon sx={{ color: red[400] }} />, text: "Lost with Bid", loadBlockClass: "lost" },
          "In Conflict": { icon: <NearbyErrorIcon sx={{ color: red[400] }} />, text: "In Conflict", loadBlockClass: "lost" },
          "No Bid": { icon: <ThumbDownOffAltIcon sx={{ color: red[400] }} />, text: "No Bid", loadBlockClass: "lost" },
          "Does Not Fit Parameters": { icon: <ExtensionOffIcon sx={{ color: red[400] }} />, text: "Invalid Params", loadBlockClass: "lost" },
        };

        const closedStatus = closedStatuses[kanbanBlock.shippingStatus] || closedStatuses[status];

        if (returnType === "icon") {
          return closedStatus ? closedStatus.icon : <DoDisturbOnIcon sx={{ color: red[400] }} />;
        }

        loadBlockClass = closedStatus ? closedStatus.loadBlockClass : "lost";

        return closedStatus ? closedStatus.text : "Closed";
    }

    const convertTimeStamp = (directTime) => {
        if (!kanbanBlock) {
            return null;
        }

        var timestamp = kanbanBlock.lastUpdate;
            timestamp = Date.parse(timestamp);
            timestamp = timestamp / 1000;
        var seconds = Math.floor((new Date().getTime() / 1000) - timestamp);

        if(directTime){
            timestamp = directTime;
            timestamp = Date.parse(timestamp);
            timestamp = timestamp / 1000;
            seconds = Math.floor(timestamp - (new Date().getTime() / 1000));
        }

        var interval = Math.floor(seconds / 31536000);

        if(timestamp > (new Date().getTime() / 1000) && directTime === undefined || directTime && timestamp < (new Date().getTime() / 1000)){
            return false;
        }

        if (interval > 1) return interval + " yrs";

        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return interval + " mo";

        interval = Math.floor(seconds / 86400);
        if (interval >= 1){
            if(interval === 1){
                return "1 day";
            }else{
                return interval + " days";
            }
        }

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + "h";

        interval = Math.floor(seconds / 60);
        if (interval > 1) return interval + "m ";

        if(0 < seconds < 30 ){
            if(directTime){
                return "Soon";
            }else{
                return "Moments";
            }
        }

        return Math.floor(seconds) + "s";
    };

    function displayNotification(notificationCount, type){
        var missedFollowUpClass = "missedFollowUp";

        if(type === "request"){
            if(notificationCount > 1){
                missedFollowUpClass += " inline";
            }

            return  (
                <div
                    key={missedFollowUpClass + kanbanBlock.taskID}
                    className={missedFollowUpClass}
                >
                    <PsychologyAltIcon sx={{ color: red[400] }}/>
                    <div className="missedFollowUpCount gC2">
                        Response
                    </div>
                </div>
            )
        }

        if(type === "bid"){
            var followUpRemainingTimeClass = "followUpRemainingTime";

            if(props.detailedView && props.singleBidView !== true){
                followUpRemainingTimeClass = "followUpRemainingTime inline";
            }
            //console.log(kanbanBlock);
            return  (
                <div
                    key={followUpRemainingTimeClass + kanbanBlock.taskID}
                    className={followUpRemainingTimeClass}
                >
                    {kanbanBlock.latestBidStatus === "Denied" &&
                        <ThumbDownIcon sx={{ color: purple[800] }}/>
                    }
                    {kanbanBlock.latestBidStatus === "Denied" &&
                        <div className="followUpTimer gC2">
                            Declined
                        </div>
                    }
                    {kanbanBlock.latestBidStatus === "Completed" &&
                        <RecommendIcon sx={{ color: purple[800] }}/>
                    }
                    {kanbanBlock.latestBidStatus === "Completed" &&
                        <div className="followUpTimer gC2">
                            Accepted
                        </div>
                    }
                </div>
            )
        }
    }

    function confirmValidFollowUpTime(){
        try{
            if(kanbanBlock.nextFollowUp !== '' && (Date.parse(kanbanBlock.nextFollowUp) > new Date().getTime())){
                return true;
            }
            if(props.followUpTime){
                return(isIsoDate(props.followUpTime.toISOString()));
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    function isIsoDate(str) {
        if(str === null || str === undefined || str === ''){
            return false;
        }
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
            const d = new Date(str); 
            return d instanceof Date && !isNaN(d) && d.toISOString()===str;
        }catch(error){
            return false;
        } 
    }

    function displayKanbanBlockAttr(attr){
        if(attr === "fullName"){
            if(props?.type === "bid"){
                if(!kanbanBlock.primaryFullName){
                    return "N/A";
                }

                return kanbanBlock.primaryFullName;                
            }
            if(props?.type === "lead"){
                var firstName = kanbanBlock.leadFirstName;
                var lastName = kanbanBlock.leadLastName;
    
                if(!kanbanBlock?.leadFirstName || kanbanBlock.leadFirstName === ''){
                    firstName = "Unknown";
                }

                if(!kanbanBlock?.leadFirstName || kanbanBlock.leadLastName === ''){
                    lastName = "Unknown";
                }
    
                if(firstName === "Unknown" && lastName === "Unknown"){
                    return "N/A";
                }
    
                return firstName + " " + lastName;        
            }
        }

        if(attr === "currency"){
            function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

            if(!kanbanBlock?.deathBenefit){
                return "N/A";
            }
            if(!isNumber(kanbanBlock.deathBenefit)){
                return "N/A";
            }

            return (convertIntToCurrency(kanbanBlock.deathBenefit));
        }

        if(attr === "LE"){
            if(!kanbanBlock?.averageLEMonths){
                return "N/A";
            }

            return kanbanBlock.averageLEMonths + " mo Average LE";
        }

        if(attr === "age"){
            if(!kanbanBlock?.primaryBirthDate){
                return "N/A";
            }

            // const birthdate = new Date(kanbanBlock?.primaryInsuredDOB);
            // const ageInMs = Date.now() - birthdate.getTime();
            // const ageInYears = ~~(ageInMs / (31557600000));

            return kanbanBlock?.age + " Years Old";
        }

        return attr !== null && attr !== undefined && attr !== '' ? attr : "N/A";

    }

    function printClosedSection(){
        function toCamelCase(str) {
            const words = str.trim().split(/\s+/);

            const camelCaseWords = words.map((word, index) => {
              if (index === 0) {
                return word.toLowerCase();
              } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
              }
            });
          
            return camelCaseWords.join('');
        }
          
        let closedSectionBlock = [];
        const closedStatuses = [
            "Lost with bid",
            "In Conflict",
            "No Bid",
            "Does Not Fit Parameters",
            // "Unqualified"
        ];

        for (const status of closedStatuses) {
            let displayStatus = status;
            const containsCaseInsensitive = /\bbid\b/i.test(status);

            if(status === "Does Not Fit Parameters"){
                displayStatus = "Invalid Params";
            }

            if (containsCaseInsensitive) {
              displayStatus = displayStatus.replace(/\bbid\b/gi, (match) => {
                if (match.toLowerCase() === "bid") {
                    return bidVerbiage === "Interest" ? "Interest" : "Bid";
                }
                return match;
              });
            }

            closedSectionBlock.push([
                <div
                    key={status}
                    className="subGroup f fR g cC bR dP"
                >
                    {displayClosedType("icon", status)}
                    <div className="cL f s oH">
                        <div className="gCW">
                            {props?.completedData?.[toCamelCase(status) + "Count"] > 0 ?
                                props?.completedData?.[toCamelCase(status) + "Count"]+ " " + displayStatus
                            :
                                "0 " + displayStatus}
                        </div>
                    </div>
                    <div className="f cC">
                        <PaidIcon/>
                    </div>
                    <div className="cL f s oH">
                        <div className="gCW">
                            {convertIntToCurrency(props?.completedData?.[toCamelCase(status) + "Total"])}
                        </div>
                    </div>
                </div>
            ]);
        };

        return (
            <div
                key="subSection"
                className="subGroups g"
            >
                <div className="vDivider">
                </div>
                <div className="container cL g f fC">
                    {closedSectionBlock}
                </div>
            </div>
        );
    }

    function displaykanbanBlockAttr(attr){
        if(attr === "fullName"){
            var firstName = kanbanBlock.leadFirstName;
            var lastName = kanbanBlock.leadLastName;

            if(kanbanBlock.leadFirstName === '' || kanbanBlock.leadFirstName === null || kanbanBlock.leadFirstName === undefined){
                firstName = "Unknown";
            }
            if(kanbanBlock.leadLastName === '' || kanbanBlock.leadLastName === null || kanbanBlock.leadLastName === undefined){
                lastName = "Unknown";
            }

            if(firstName === "Unknown" && lastName === "Unknown"){
                return "N/A";
            }

            return firstName + " " + lastName;
        }

        if(attr === "currency"){
            function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

            if(kanbanBlock.leadDeathBenefit === '' || kanbanBlock.leadDeathBenefit === null || kanbanBlock.leadDeathBenefit === undefined){
                return "N/A";
            }
            if(!isNumber(kanbanBlock.leadDeathBenefit)){
                return "N/A";
            }

            return (convertIntToCurrency(kanbanBlock.leadDeathBenefit));
        }

        if(attr === "age"){
            if(kanbanBlock.leadBirthDate === '' || kanbanBlock.leadBirthDate === null || kanbanBlock.leadBirthDate === undefined){
                return "N/A";
            }

            return kanbanBlock.leadBirthDate + " Years Old";
        }

        if(attr === '' || attr === null || attr === undefined){
            return "N/A";
        }

        return attr;
    }

    useEffect(() => {
        setKanbanBlock(props.kanbanBlockInfo || {});
    }, [props.kanbanBlockInfo]);

    useEffect(() => {
        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
            setBidVerbiage("Interest");
        }
    }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

    useEffect(() => {
    //    console.log(props.kanbanBlockInfo)
    }, []);

    useEffect(() => {
        if (props.kanbanBlockInfo?.lastUpdate) {
            setTimeDifference(convertTimeStamp());
        }
    }, []);

    if (loadBlockClass === "closed" && props.closedType) {
        loadBlockClass = props.closedType;
        const listPath = props.closedType === "Won" ? "/bids/" : "";
        const closedTypeMap = {
            WON: {
              icon: <CelebrationIcon />,
              text: <div className="cL">{props.closedCount ? props.closedCount : 0} Won</div>
            },
            CONTRACTS: {
              icon: <GavelIcon />,
              text: <div className="cL">{props.closedCount ? props.closedCount : 0} In Contracts</div>
            },
            CLOSED: {
              icon: <DoNotDisturbOnIcon />,
              text: <div className="cL">{props.completedData?.closedCount ? props.completedData?.closedCount : 0} Closed</div>
            }
        };

        return (
            <div className={stage === "closed" ? "kanban noTopBar cC" : "kanban cC"}>
                {stage !== "closed" && topDisplayBar()}
                <Link
                    className={`kanbanBlock g ${stage}`}
                    to="/bids"
                    onClick={() => {
                        props.setActivityView("list");
                        props.onClick(props.closedType, "only", "list");
                    }}
                >
                    <div className="data g">
                        {closedTypeMap[props.closedType]?.icon}
                        {closedTypeMap[props.closedType]?.text}
                    </div>
                    <div className="data g">
                        <PaidIcon />
                        <div className="cL">
                        {props.closedType !== "CLOSED" &&
                            convertIntToCurrency(props.totalDeathBenefit)}
                        {props.closedType === "CLOSED" &&
                            convertIntToCurrency(props.completedData?.closedTotal)}
                        </div>
                    </div>
                    {props.closedType === "CLOSED" && (
                        <>
                            <div key="divider" className="divider"></div>
                            {printClosedSection()}
                        </>
                    )}
                </Link>
          </div>
        );
    }

    let kanbanBlockInner = (
        <>
            <div key={`header${kanbanBlock.recordID}`} className="header g f">
                <div className="cC oH">
                {loadBlockClass === "NEW" && <BeenhereIcon sx={{ color: green[500] }} />}
                {loadBlockClass === "UNDER REVIEW" && <SearchIcon sx={{ color: blue[500] }} />}
                {loadBlockClass === "BIDDING" && <ThumbsUpDown sx={{ color: purple[800] }}/>}
                {["CLOSED", "CLOSING"].includes(loadBlockClass) && displayClosedType("icon")}
                {["lead", "contactAttempted"].includes(loadBlockClass) && (
                    <>
                    {loadBlockClass === "lead" && <BeenhereIcon sx={{ color: green[500] }} />}
                    {loadBlockClass === "contactAttempted" && <CallEndIcon sx={{ color: blue[500] }} />}
                    <div className="cL">
                        {loadBlockClass === "lead" ? "New" : kanbanBlock.contactAttemptCount === 0 ? "Contact Attempted" : kanbanBlock.contactAttemptCount > 1 ? kanbanBlock.contactAttemptCount + " Calls" : "1 Call"}
                    </div>
                    </>
                )}
                </div>
                {["NEW", "UNDER REVIEW", "BIDDING", "CLOSED", "CLOSING"].includes(loadBlockClass) && (
                <div className="cL oH">
                    <div className="gCW">
                    {loadBlockClass === "NEW" && "New"}
                    {loadBlockClass === "UNDER REVIEW" && "Under Review"}
                    {loadBlockClass === "BIDDING" ?
                        bidVerbiage === "Interest" ?
                            "Interested"
                        :
                            "Bidding"
                        : null
                    }
                    {["CLOSED", "CLOSING"].includes(loadBlockClass) && displayClosedType("statement")}
                    </div>
                </div>
                )}
                <div className="cR oH">
                <div className="gCW">
                    {timeDifference === "0s" ? "Just Now" : timeDifference + " ago"}
                </div>
                </div>
            </div>
            <div key={`leadDivider${kanbanBlock.recordID}`} className="divider" />
            {buildKanbanBlockData()}
            <div className="blockAttr thin italics f cR g">
                {/* <div className="g f cL">
                    <div className="f cC">
                        <AutoAwesomeMotionIcon/>
                    </div>
                    <div className="f cL oH">
                        <div className="gCW">
                            In 2 Groups
                        </div>
                    </div>
                </div> */}
                <div className="g f cR gC2">
                    <div className="f cC">
                        <AssignmentIndIcon/>
                    </div>
                    <div className="f cL oH">
                        <div className="gCW">
                            {displayAssignedPrompt(kanbanBlock?.assignedUsersIDs, props?.sessionUser)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    if(props.kanbanBlockType === "result"){
        const activeDisplayBar = [
            "stage1",
            "stage2",
            "stage3",
        ];
        // "won",
        // "closed"
        // "closing",
        return (
            <div className="kanban cC">
                {topDisplayBar()}
                {Object.keys(props?.sessionUser?.accountData)?.length > 1 && (
                    <div className="accountName bold f cL oH">
                        <div className="gCW">
                            {props?.sessionUser?.accountData?.[kanbanBlock?.relatedAccountID]?.name || ''}
                        </div>
                    </div>
                )}
                {kanbanBlock.stage === "CLOSED" && kanbanBlock.shippingStatus !== "Won" ?
                    <div
                        className={"kanbanBlock g result " + stage}
                        onClick={()=>{
                            props.navigation.setCurrentBid(kanbanBlock);
                            props.navigation.setCurrentActivity("singleBid");
                        }}
                    >
                        {kanbanBlockInner}
                    </div>
                :
                    <Link
                        className={"kanbanBlock g result " + stage}
                        to={"/bids/" + kanbanBlock.recordID}
                        onClick={()=>{
                            props.navigation.setCurrentBid(kanbanBlock);
                            props.navigation.setCurrentActivity("singleBid");
                        }}
                    >
                        {kanbanBlockInner}
                    </Link>
                }

            </div>
        )
    }

    function printRequestPin(){
        let prompt = '0 Requests';
        let btnType = '';
        let numOfOpenRequests = kanbanBlock?.numOfOpenRequests;
        let numOfUnreadRequestResponses = kanbanBlock?.numOfUnreadRequestResponses;

        if(props?.closedType){
            numOfOpenRequests = props?.completedData[props?.stage + "TotalOpenRequests"];
            numOfUnreadRequestResponses = props?.completedData[props?.stage + "TotalUnreadRequests"];
        }

        if(numOfOpenRequests > 0){
            prompt = numOfOpenRequests + ` Open Request${kanbanBlock?.numOfOpenRequests === 1 ? '' : "s"}`

            btnType = "active ";
        }else{
            btnType = "inactive ";
        }

        if(numOfUnreadRequestResponses > 0){
            prompt = "Unread Response "
            btnType += "cover ";
        }else{
            btnType += "open ";
        }

        return (
            <div className={btnType + "request f cC bR oH e"}>
                <div className="split g f cC oH">
                    <div className="f cC">
                        <PsychologyAltIcon/>
                    </div>
                    <div className="f cC gCW">
                        {prompt}
                    </div>
                </div>
            </div>
        )
    }

    function printBidPin() {
        let prompt = '';
        let btnType = '';
        let showStyle = '';
        let bidValue = props?.closedType ? props?.totalBidValue : kanbanBlock.grossBid;
        let bidPrompt = bidVerbiage === "Interest" ? "Amount: " : "Bid: ";
        if (bidValue > 0) {
            btnType = "active ";

            if (kanbanBlock?.latestBidStatus && !["CLOSED", "CLOSING"].includes(kanbanBlock?.stage)) {
                if (kanbanBlock?.latestBidStatus === "Open") {
                    prompt = bidPrompt + convertIntToCurrency(bidValue, true);
                    showStyle = "open ";
                } else if (kanbanBlock?.latestBidStatus === "Denied") {
                    prompt = "Declined " + convertIntToCurrency(bidValue, true);
                    showStyle = "open ";
                } else if (kanbanBlock?.latestBidStatus === "Completed") {
                    prompt = "Accepted " + convertIntToCurrency(bidValue, true);
                    showStyle = "open ";
                    btnType = "accepted ";
                }
            } else {
                prompt = bidPrompt + convertIntToCurrency(bidValue, true);
                showStyle = "open ";

                if(["CLOSING"].includes(kanbanBlock?.stage)) {
                    if(["Closing"].includes(kanbanBlock?.shippingStatus)) {
                        prompt = "Accepting: " + convertIntToCurrency(bidValue, true);
                        btnType = "accepted ";
                    } else {
                        prompt = "Declined: " + convertIntToCurrency(bidValue, true);
                    }
                }
            }

            if (kanbanBlock?.numOfUnreadBidResponses > 0) {
                showStyle = "cover ";
            }

            btnType += showStyle;
        } else {
            btnType = "inactive ";
            prompt = bidVerbiage === "Interest" ? "No Interest" : "No Bid";
        }

        return (
          <div className={btnType + "bid f cC bR oH"}>
            <div className="g f cC s e">
              <div className="f cC oH">
                <div className="f cC gCW">
                  {prompt}
                </div>
              </div>
            </div>
          </div>
        );
    }

    function topDisplayBar(){
        if (props?.type === "bid") {
            const isClosed = kanbanBlock.stage === "CLOSED";
            const hasUnreadActivity = kanbanBlock?.numActivitiesUnread || kanbanBlock?.numMessagesUnread;
            let displayBar, notification;
            if (isClosed) {
                if(kanbanBlock.shippingStatus == "Won"){
                    //halo
                    return (
                        <div key={"spaceHolder topBar" + kanbanBlock.leadID} className="accepted spaceHolder topBar g f cC fR ns">
                            {"Accepted " + convertIntToCurrency(kanbanBlock?.grossBid, true)}
                        </div>
                    );
                }else{
                    return (
                        <div key={"spaceHolder topBar" + kanbanBlock.leadID} className="spaceHolder topBar g f cC fR ns">
                            Closed
                        </div>
                      );
                }

            } else {
              if (hasUnreadActivity) {
                notification = <div key="alert" className="notificationAlert"></div>;
              }

              displayBar =
                <>
                    {notification}
                    <div className="data g f fR fC cC">{printRequestPin()}</div>
                    <div className="data g f fR fC cC">{printBidPin()}</div>
                </>
            }
          
            const topBarClassName = `alert topBar ${hasUnreadActivity ? "unread" : ""} f cC fR ns`;
          
            return (
              <div key={"alert topBar" + kanbanBlock.leadID} className={topBarClassName}>
                {displayBar}
              </div>
            );
        }

        if(props?.type === "lead"){
            return (
                <div
                    key={"action topBar" + kanbanBlock.leadID}
                    className="action topBar f cC fR ns p">
                    <div className="f cC oH"
                        onClick={()=>{props.toggleLeadQuickView("closed", kanbanBlock)}}>
                        <DoDisturbOnIcon/>
                    </div>
                    <div className="f cC oH"
                        onClick={()=>{props.toggleLeadQuickView("contactAttempted", kanbanBlock)}}>
                        <AddIcCallIcon/>
                    </div>
                    <div className="f cC oH"
                        onClick={()=>{props.toggleLeadQuickView("inProgress", kanbanBlock)}}>
                        <MovingIcon/>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="kanban cR">
            {topDisplayBar()}
            {Object.keys(props?.sessionUser?.accountData)?.length > 1 &&
                (
                    <div className="accountName bold f cL oH">
                        <div className="gCW">
                            {props?.sessionUser?.accountData?.[kanbanBlock?.relatedAccountID]?.name || ''}
                        </div>
                    </div>
                )
            }
            <Link
                key="kanbanBlock"
                className={`kanbanBlock${kanbanBlock?.uploadError ? " error" : ''} cC g fC ${stage || ''}`}
                to={path}
                onClick={() => {
                props.navigation.setCurrentBid(kanbanBlock);
                props.navigation.setCurrentActivity("singleBid");
                }}
            >
                {kanbanBlockInner || ''}
            </Link>
        </div>
      );
}

export default KanbanBlock;
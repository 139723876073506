import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import ErrorIcon from '@mui/icons-material/Error';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

function BidTimelinePost(props){
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;

    return (
        <div className="treelinePost f cC g">
            <div className="f cC s e">
                <div className="treeline">
                </div>
            </div>
            <div className={`newTimelinePost default request g f bR p ${props?.stem} ${timelineItem?.checkStatus}`} onClick={props?.onClick}>
                <div className="header g f fR e dG">
                    {timelineItem?.readStatus === false && timelineItem?.originator === "Internal" &&
                        <div className="f cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    }
                    <div className="cL gC2 bold dG">
                        Request
                        <PsychologyAltTwoToneIcon/>
                    </div>
                    <div className="cR gC3">
                        {timeAgo}
                    </div>
                </div>
                <div className="divider dT">
                </div>
                <div className="content g f cC dG b e">
                    {timelineItem?.checkStatus === "Closed" || timelineItem?.checkStatus === "Completed" ?
                        <CheckCircleTwoToneIcon className="dC"/>
                    :
                        <PendingActionsTwoToneIcon className="dC"/>
                    }
                    <div className="f cL gC2">
                        Requested Information
                    </div>
                    <div className="f cR fR gC3">
                        <div className="dataPoint bold cC dT bR fR">
                            {timelineItem?.checkStatus === "Closed" || timelineItem?.checkStatus === "Completed" ? "Completed" : "Pending"}
                        </div>
                    </div>
                    <div className="vDivider dT gC1">
                    </div>
                    <div className="f cL gC2">
                        {timelineItem?.content}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BidTimelinePost;
import React from 'react';
import { useState } from 'react';

import { authorizeRequest } from '../envHandler.js';

import CloseIcon from '@mui/icons-material/Close';
import { lightBlue } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

function SendAppPanel(props){
    const [emailContent, setEmailContent] = useState('');
    const [downloadProgress, setDownloadProgress] = useState(false);
    const [emailProgress, setEmailProgress] = useState(false);

    const handleChange = (event) => {
        setEmailContent(event.target.value);
    };
  
    function toggleSendAppPanel(){
        props.setShowSendAppPanel(false);
    }

    function downloadAbacusApp(){
        setDownloadProgress(true);

        props.session?.env?.functions?.buildFetchRequest('downloadApp', null, {"Accept": "application/pdf"})
        .then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "AbacusIntakeForm.pdf";
            alink.click();
            setDownloadProgress(false);
        });
    }

    function sendAppEmail(){
        setEmailProgress(true);
        const paramVals = {
            "accountID" : props.sessionUser.accountID,
            "leadID" : props.leadID,
            "userEmail" : props.sessionUser.email,
            "userName" : props.sessionUser.firstName + " " + props.sessionUser.lastName,
            "clientEmail" : props.currentLeadInfo.leadEmail,
            "clientName" : props.currentLeadInfo.leadFirstName + " " + props.currentLeadInfo.leadLastName,
            "userID" : props.sessionUser.userID,
            "content" : emailContent,
        };

        props.session?.env?.functions?.buildFetchRequest('sendAppEmail', paramVals)
          .then(response => response.json())
          .then(resData => {

            if(resData.status === 200){
                toggleSendAppPanel();
            }
            if(resData.status === 500){
                console.log("No activity found.");
            }
            setEmailProgress(false);
          });
    }

    return (
        <div className="leadOptionsBoard" onClick={emailContent === '' ? (evt)=> {props.handleClose(evt)} : null }>
            <div className="leadOptionsBoardInnerWrap moduleBlock">
                <div className="miniModuleHeader">
                    <div className="notificationHeaderTitle">
                        Send an Application
                    </div>
                    <div className="closeBtn" onClick={()=> {toggleSendAppPanel()}}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="miniModuleBody">
                    <div className="leadNoteCreate">
                        <div className="leadNoteIcon">
                            <SendIcon sx={{ color: lightBlue[500] }}/>
                        </div>
                        <div className="leadSendAppPrompt">
                            Email Custom Message
                        </div>
                        <textarea
                            className="messageBox"
                            placeholder="Please respond to this email for any further questions regarding completing your application. We look forward to working with you!"
                            onChange={handleChange}
                            rows={20}
                        />
                    </div>
                    {downloadProgress ?
                        <div className="leadDownloadAppBtn ns">
                            <div className="inlineLoading">
                                <CircularProgress/>
                            </div>
                        </div>
                    :
                        <div className="leadDownloadAppBtn ns"
                            onClick={ ()=>{downloadAbacusApp()}}>
                            <DownloadIcon/>
                            <span>Download App</span>
                        </div>
                    }
                    {emailProgress ?
                        <div className="leadSendAppBtn ns" >
                            <div className="inlineLoading">
                                <CircularProgress/>
                            </div>
                        </div>
                    :
                        <div className="leadSendAppBtn ns"
                            onClick={()=>{sendAppEmail()}}>
                            <MailIcon/>
                            <span>Send via Email</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SendAppPanel;
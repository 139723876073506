import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import '../../index.css';
import './MessageBoard.css';

function MessageBubble(props){
    let bubbleType = "messageBubble sent";
    let messageTimeStamp = "messageTimeStamp";

    const [showStatus, setShowStatus] = useState('');

    if(props.messageData.originator === "Internal" || props.messageData.originator === "Abacus"){
        bubbleType = "messageBubble received";
    }

    if(props.messageData.loading === true){
        bubbleType = "messageBubble loading";
    }

    if(props.messageData.loading === "failed"){
        bubbleType = "messageBubble failed";
    }

    if(props.messageData.nameOfSender !== props.lastNameOfSender){
        messageTimeStamp = "messageTimeStamp full";
        bubbleType += " full";
    }
    function showTimeStamp(show){
        if(show){
            setShowStatus(" show");
        }else{
            setShowStatus('');
        }
    }

    function convertTimeStamp(timeStamp, separator) {
        if(timeStamp === undefined){
            return '';
        }
        timeStamp = Date.parse(timeStamp);
        if(separator){
            return new Date(timeStamp).toDateString("en-US")
        }else{
            return new Date(timeStamp).toLocaleTimeString("en-US");
        }
    }

    let returningMessage = [];
    if(convertTimeStamp(props.lastDate, true) !== convertTimeStamp(props.messageData.timeStamp, true) || props.messageKey === 0){
        returningMessage.push(
            <div
                key={props.messageData.timeStamp + "StampSeparator"}
                className="timeStampSeparator">{convertTimeStamp(props.messageData.timeStamp, true)}
            </div>
        );
    }

    returningMessage.push(
        <div
            key={messageTimeStamp + "Message"} className={bubbleType + showStatus}
            onMouseEnter={()=> showTimeStamp(true)}
            onMouseLeave={()=> showTimeStamp() }>
                {props.messageData.content}
                <div>
                    {messageTimeStamp === "messageTimeStamp full" ? 
                        <div className={"messageNameOfSender" + showStatus}>
                            {props.messageData.nameOfSender !== "undefined undefined" ? props.messageData.nameOfSender : "N/A" }
                        </div>
                    : null }
                    <div className={messageTimeStamp + showStatus}>
                        <div className="messageTimeStampInnerWrap">
                            <div>
                                {convertTimeStamp(props.messageData.timeStamp)}
                            </div>                            
                        </div>
                    </div>
                </div>
                
        </div>
    )

    return (
        returningMessage
    )
 }
   
 export default MessageBubble;
import React, { useEffect, useState, useCallback } from 'react';

import ViewportHandler from "./Viewport.js";
import DownloadResources from "./Download.js";

const fetchBase = process.env.REACT_APP_FETCH_BASE;

function EnvVars(props){
  const sessionUser = props?.user?.data;
  const userFunctions = props?.user?.functions;

  let viewport = ViewportHandler();
  const [overlay, setOverlay] = useState();

  const [isTyping, setIsTyping] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [keyHeld, setKeyHeld] = useState(false); // Track if a key is currently held down

  const debounce = (func, delay) => {
      let timer;
      return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              func(...args);
          }, delay);
      };
  };

  const debouncedSetIsTypingFalse = useCallback(
      debounce(() => setIsTyping(false), 500), // Adjust the delay as needed
      []
  );

  useEffect(() => {
      const handleKeyDown = (event) => {
          if (!['Shift', 'Control', 'Alt', 'Meta'].includes(event.key)) {
              setIsTyping(true);
              setKeyHeld(true); // Mark key as held
              debouncedSetIsTypingFalse();
          }
      };

      const handleKeyUp = () => {
          setKeyHeld(false); // Mark key as released
          debouncedSetIsTypingFalse();
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('keyup', handleKeyUp);
      };
  }, [debouncedSetIsTypingFalse]);

  useEffect(() => {
      if (!keyHeld) {
          debouncedSetIsTypingFalse();
      }
  }, [keyHeld, debouncedSetIsTypingFalse]);

  useEffect(() => {
    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    document.addEventListener('dragstart', handleDragStart);
    document.addEventListener('dragend', handleDragEnd);

    return () => {
        document.removeEventListener('dragstart', handleDragStart);
        document.removeEventListener('dragend', handleDragEnd);
    };
  }, []);
  
  
  function checkSessionExceptions(session, exception, stringent) {
    const userData = session?.user?.data;
    if (!userData) return undefined;
  
    try {
      const userExceptions = userData?.exceptions 
        ? JSON.parse(userData?.exceptions.replace(/(\w+)\s*:/g, '"$1":')) 
        : {};
      const accountExceptions = userData?.accountData ?? {};
  
      // Convert stringent values to numbers if present
      const stringentAccountID = stringent?.accountID ? Number(stringent?.accountID) : null;
      const stringentUserID = stringent?.userID ? Number(stringent?.userID) : null;
  
      // Handle stringent checking
      if (stringentAccountID) {
        return accountExceptions?.[stringentAccountID]?.exceptions 
          ? JSON.parse(accountExceptions?.[stringentAccountID]?.exceptions.replace(/(\w+)\s*:/g, '"$1":'))?.[exception] 
          : undefined;
      }
      
      if (stringentUserID) {
        return userExceptions?.[exception];
      }
  
      // Default behavior: check user exceptions, then iterate through account exceptions
      if (userExceptions?.[exception]) return userExceptions?.[exception];
  
      for (const accountID in accountExceptions) {
        const accountEx = accountExceptions?.[accountID]?.exceptions;
        const parsedAccountEx = accountEx ? JSON.parse(accountEx.replace(/(\w+)\s*:/g, '"$1":')) : {};
        if (parsedAccountEx?.[exception]) return parsedAccountEx?.[exception];
      }
  
    } catch (error) {
      console.error("Error parsing exceptions JSON:", error);
    }
  
    return undefined;
  }  

  function updateLocalStorage(branch, stem, key, value) {
    // Retrieve the existing object for the branch from localStorage, or initialize it if it doesn't exist
    const branchData = JSON.parse(localStorage.getItem(branch)) || {};

    // Ensure the stem exists within the branch, initialize it if it doesn't
    if (!branchData[stem]) {
        branchData[stem] = {};
    }

    // Update the stem object with the new key-value pair
    branchData[stem][key] = value;

    // Store the updated branch object back in localStorage
    localStorage.setItem(branch, JSON.stringify(branchData));
  }

  const functions = {
    convertFloatToPercentage,
    convertIntToCurrency,
    handleClose,
    updateInput,
    buildFetchRequest,
    reformatDate,
    calcAge,
    redirectLink,
    // homepage,
    // eligablePath,
    currentPath,
    timeAgo,
    checkSessionExceptions,
    calculateAttribute,
    updateLocalStorage,
    downloadPremiumSchedule,
    downloadLineGraph
  }

  function timeAgo(rawTimeStamp) {
    const now = new Date();
    const timeStamp = new Date(rawTimeStamp);
    const diffSec = Math.floor((now - timeStamp) / 1000);

    const floorDiv = (divisor) => Math.floor(diffSec / divisor);

    const units = [
        { max: 30, text: "Just now" },
        { max: 60, text: "Less than a min ago" },
        { max: 3600, text: () => `${floorDiv(60)} minute${floorDiv(60) > 1 ? 's' : ''} ago` },
        { max: 86400, text: () => `${floorDiv(3600)} hour${floorDiv(3600) > 1 ? 's' : ''} ago` },
        { max: 2592000, text: () => `${floorDiv(86400)} day${floorDiv(86400) > 1 ? 's' : ''} ago` },
        { max: 31536000, text: () => `${floorDiv(2592000)} month${floorDiv(2592000) > 1 ? 's' : ''} ago` },
        { max: Infinity, text: () => `${floorDiv(31536000)} year${floorDiv(31536000) > 1 ? 's' : ''} ago` },
    ];

    for (let unit of units) {
        if (diffSec < unit.max) {
            return typeof unit.text === 'function' ? unit.text() : unit.text;
        }
    }
  }

  function calculateAttribute({ data, attr, conditions = [], operation = "sum" }) {
    if (data === undefined) return "-";

    // Ensure conditions is an array and contains valid elements
    if (!Array.isArray(conditions) || conditions.length === 0) return 0;

    // Group conditions by groupID
    const groupedConditions = conditions.reduce((acc, condition) => {
        const { groupID, inlineOperator, conditionFn } = condition;
        if (!acc[groupID]) acc[groupID] = { inlineOperator, conditionFns: [] };
        acc[groupID].conditionFns.push(conditionFn);
        return acc;
    }, {});

    const filteredData = data.filter(obj => {
        return Object.values(groupedConditions).every(({ inlineOperator, conditionFns }) => {
            if (inlineOperator === "AND") {
                return conditionFns.every(fn => fn(obj));
            } else if (inlineOperator === "OR") {
                return conditionFns.some(fn => fn(obj));
            }
        });
    });

    if (!filteredData || filteredData.length === 0) return 0;

    const total = filteredData.reduce((result, obj) => {
        const value = obj[attr] || 0;

        switch (operation) {
            case "sum":
                return result + value;
            case "min":
                return result === null ? value : Math.min(result, value);
            case "max":
                return result === null ? value : Math.max(result, value);
            case "count":
                return result + 1;
            default:
                return result;
        }
    }, operation === "min" || operation === "max" ? null : 0);

    if (operation === "average") {
        return total / filteredData.length;
    }

    return operation === "count" ? total : total;
  }

  function currentPath(location) {
    const { pathname: path } = location;

    const match = path.match(/^\/([^\/]+)(?:\/([^\/]+))?(?:\/(\d+))?$/);

    if (match) {
        const [ , part1, part2, recordID ] = match;

        return recordID
            ? { branch: part1, stem: part2, recordID }
            : part2
            ? { stem: part1, recordID: part2 }
            : { stem: part1 };
    }

    return null; // Return null if no match
  }

  function convertFloatToPercentage(decimal) {
    return `${(decimal * 100).toFixed(2)}%`;
  }

  function convertIntToCurrency(value, mobileDevice, bold){
      if(isNaN(value)){
        return "$0";
      }
    
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    
      let currencyValue = 'N/A';
    
      const condenseCurrencyValue = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
        return 0;
      };
    
      if (value !== null) {
        currencyValue = formatter.format(Math.floor(value));
      }
      
      if (bold && !/^(\$0|N\/A)$/.test(currencyValue)) {
        if(mobileDevice){
          return (
            <b key="cashValue">
              ${condenseCurrencyValue(Math.floor(value))}
            </b>
          );
        }else{
          return [
            <b key="cashValue">
              ${currencyValue}
            </b>
          ];
        }
      }else {
        return mobileDevice ? "$" + condenseCurrencyValue(Math.floor(value)) : currencyValue;
      }
  }

  function handleClose(evt, overlay, setter) {
    if (
      !evt.target.classList.contains(overlay) &&
      !evt.target.closest(overlay) &&
      !evt.target.closest(`.${overlay} *`)
    ) {
      setter();
    }
  }

  const handleFetch = (fetch) => {
    return async function(url, options) {
      const response = await fetch(url, options);
      const clone = response.clone();
      const data = await clone.json();

      if(data.status === 500){
        console.log(data);
      }
  
      if (response.status === 401 || data.status === 401 || data.status === 509 || data.error == "Unauthorized" || response?.statusText === "Unauthorized") {
        console.log("Session timed out.");
        userFunctions?.logout();
      }

      return response;
    };
  };


  function authorizeRequest(call) {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    if (!sessionUser || !sessionUser?.sessionToken) {
      userFunctions?.logout();

      return "Unauthorized";
    }

    const currentToken = parseJWT(sessionUser?.sessionToken);
    
    if (currentToken === "Unauthorized" || ["userID", "email", "accountID"].some(attrName =>
      currentToken[attrName] !== sessionUser?.[attrName] ||
      currentToken[attrName] !== storedSessionUser?.[attrName]
    )) {
      userFunctions?.logout();

      return "Unauthorized";
    }

    return sessionUser.sessionToken;
  }

  function buildRequestOptions(bodyParams, directHeaders, directContentType){
    let defaultContentType = directContentType || "application/json";

    return {
      method: "POST",
      headers: {
        ...(directHeaders || {}),
        "Content-Type": defaultContentType
      },
      body: JSON.stringify({
        ...(bodyParams || {}),
        sessionToken: authorizeRequest(sessionUser?.sessionToken)
      })
    };
  }

  // function buildFetchRequest(url, params, headers, contentType) {
  //   // Determine if the response is expected to be non-JSON (like application/pdf or text/csv)
  //   const isNonJsonResponse = headers?.Accept && ["text/csv", "application/pdf"].includes(headers.Accept);

  //   const fetchHandler = isNonJsonResponse ? window.fetch : handleFetch(window.fetch);
  //   const requestOptions = buildRequestOptions(params, headers, contentType);
  
  //   return fetchHandler(fetchBase + url, requestOptions)
  //       .then(response => {
  //         // Check the response status
  //         if (!response.ok) {
  //           // throw new Error('Network response was not ok');
  //           return response;
  //         }
  //         if (isNonJsonResponse) {
  //           return response.blob(); // If it's non-JSON, get the blob directly.
  //         }
  //         return response;  // Otherwise, return the original response (JSON is parsed in handleFetch).
  //       })
  //       .catch(error => {
  //         console.error('Fetch error:', error);
  //         throw error; // Re-throw the error to propagate it.
  //       });
  // }

  function buildFetchRequest(url, params, headers, contentType) {
    // Include 'application/zip' and Excel MIME type in the check for non-JSON response types
    const isNonJsonResponse = headers?.Accept && [
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(headers.Accept);
  
    const requestOptions = buildRequestOptions(params, headers, contentType);
    const fetchHandler = isNonJsonResponse ? window.fetch : handleFetch(window.fetch);
  
    return fetchHandler(fetchBase + url, requestOptions)
      .then(response => {  
        if (!response.ok) {
          return response; // Return the response to handle errors outside this function
        }
        if (isNonJsonResponse) {
          return response.blob(); // Handle non-JSON responses by returning a blob
        }
        return response; // Return the original response for further handling
      })
      .catch(error => {
        console.error('Fetch error:', error); // This will log the specific error
        throw error; // Re-throw the error to allow further handling
      });
  }
  
  function parseJWT (sessionToken) {
    if(!sessionToken){
      return;
    }

    try {
        var base64Url = sessionToken?.split('.')[1];
        var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window?.atob(base64)?.split('')?.map(function(c) {
            return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })?.join(''));
    
        return JSON.parse(jsonPayload);
    } catch (e) {
        return "Unauthorized";
    }
  }

  function updateInput(evt, setter){
    setter(prev => ({...prev, [evt.target.name]: evt.target.value }));
  }

  function reformatDate(originalDate, type = false, viewType) {
    if (!originalDate) {
      return null;
    }
  
    // Check if the date is in ISO format with or without milliseconds, "YYYY-MM-DD", or "MM/DD/YYYY"
    let dateObject;
    
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/.test(originalDate)) {
      // If it's in ISO format with or without milliseconds (like "2024-09-18T18:40:32Z")
      dateObject = new Date(originalDate);
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(originalDate)) {
      // If it's in "YYYY-MM-DD" format, ensure we treat it as a local date
      const [year, month, day] = originalDate.split('-');
      dateObject = new Date(year, month - 1, day); // Create a local date without timezone shift
    } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(originalDate)) {
      // If it's in "MM/DD/YYYY" format
      const [month, day, year] = originalDate.split('/');
      dateObject = new Date(`${year}-${month}-${day}`);
    } else {
      return originalDate; // Return the originalDate if format is unrecognized
    }
  
    // Check if the created Date object is valid
    if (isNaN(dateObject.getTime())) {
      return originalDate; // Return the originalDate if it's not a valid date
    }
  
    // Format as MM/DD/YY if no type is provided
    if (!type) {
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      const year = dateObject.getFullYear().toString().slice(-2); // Get last two digits of the year
      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    }
  
    const now = new Date();
    const isMobile = viewType === "mobile";
  
    // Return a timestamp
    if (type === "timestamp") {
      return dateObject.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    } else if (type === "friendly") {
      // Calculate the friendly difference
      const diffInSeconds = Math.floor((now - dateObject) / 1000);
      const days = Math.floor(diffInSeconds / 86400);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);
  
      if (years > 0) return `${years} ${years > 1 ? 'years' : 'year'}${isMobile ? '' : " ago"}`;
      if (months > 0) return `${months} ${months > 1 ? 'months' : 'month'}${isMobile ? '' : " ago"}`;
      if (days > 1) return `${days} days${isMobile ? '' : " ago"}`;
      if (days === 1) return 'Yesterday';
      if (days === 0) return 'Today';
    } else {
      // Default to locale string
      return dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    }
  }  

  function calcAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }

  function redirectLink(link){
    sessionStorage.setItem('redirectLink', link);
  }

  // function homepage(views = sessionUser?.views) {
  //   const safePath = "/profile";
  //   console.log(321, sessionUser?.views);
  //   console.log(321, sessionUser);
  //   if(views === undefined){
  //     console.log(321, 1);
  //     return safePath;
  //   }

  //   const prioritizedViews = ['Dashboard', 'Reporting', 'Policies', 'Bids', 'Leads', 'Upload' ];
  //   const orderedViews = prioritizedViews.filter(view => views.includes(view));

  //   const matchingView = orderedViews[0];
  //   console.log(321, orderedViews, matchingView);

  //   if (matchingView) {
  //     const viewToRouteMapping = {
  //       Home: '/home',
  //       Policies : '/policies',
  //       Leads: '/leads',
  //       Bids: '/bids',
  //       Upload: '/upload',
  //       Reporting: '/reporting',
  //       Dashboard: '/dashboard',
  //       Tertiary: '/tertiary',
  //     };
  
  //     return viewToRouteMapping[matchingView];
  //   }
  
  //   console.log(321, 2);
  //   return safePath;
  // }

  function isStringInUserViews(path) {
    if(path === "profile"){
      return true;
    }

    const userViews = sessionUser?.views || []; // Replace with actual user views array
    return userViews.some(view => view?.toLowerCase() === path?.toLowerCase());
  }

  // function eligablePath(currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null) {
  //   let path = currentPath === "tertiary" ? "bids" : currentPath;
  //   if(path === "profile"){
  //     return true;
  //   }

  //   if (!isPathEligible(path) ||
  //     !navigation?.functions?.verifyPageAccess(path)
  //   ){
  //     return false;
  //   }

  //   return true;
  // }

  function downloadPremiumSchedule(session, selectedReport, fileType){
    const downloadResources = DownloadResources({session});
    downloadResources.functions.downloadPremiumSchedule(selectedReport, fileType);
  }

  function downloadLineGraph(session, illustration, reportName) {
    const downloadResources = DownloadResources({ session });

    // Check if the canvasRef exists or generate the graph if it doesn't
    const canvas = illustration?.current || downloadResources?.functions?.generateLineGraph(session, illustration);

    // Proceed with the download if the canvas exists
    if (canvas) {
        downloadResources?.functions?.downloadLineGraph(canvas, reportName);
    } else {
        console.error("Canvas not available for download");
    }
}

  const envVars = {
      functions,
      overlay,
      setOverlay,
      viewport,
      isTyping,
      isDragging,
  }

  return envVars;
};

export default EnvVars;
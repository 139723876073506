import React from 'react';
import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { convertIntToCurrency } from '../envFunctions';

function SearchBar(props){
    // function checkEligableFilterSearchBlock(){

    //     if(props.deathBenefitsRange.minVal === props.deathBenefitsRange.fixedMinVal && props.deathBenefitsRange.maxVal === props.deathBenefitsRange.fixedMaxVal){
    //    //     updateFilterCounter(false);
    //         return false;
    //     }else{
    //    //     updateFilterCounter(true);
    //         return (
    //             convertInt(props.deathBenefitsRange.minVal) + " - " + convertInt(props.deathBenefitsRange.maxVal)
    //         )
    //     }
    // }

    function printFilterBubbles(){
        const filterHandler = {...props?.filterHandler};
        if(!Object.keys(filterHandler).length === 0){
            return;
        }
        let currentBubbles = [];
        let statusCount = 0;
        let affiliateCount = 0;
        let leadSourceCount = 0;

        Object.keys(filterHandler).forEach(filterGroup => {
            if(filterGroup === "status"){
                Object.keys(filterHandler[filterGroup]).forEach(filterItem => {
                    const currentStatus = filterHandler?.[filterGroup]?.[filterItem];

                    if(currentStatus?.active === false){
                        statusCount += currentStatus?.count;
                    }
                });
            }

            if(filterGroup === "leadSource"){
                Object.keys(filterHandler[filterGroup]).forEach(filterItem => {
                    const currentLeadSource = filterHandler?.[filterGroup]?.[filterItem];

                    if(currentLeadSource?.active === false){
                        leadSourceCount += currentLeadSource?.count;
                    }
                });
            }

            if(filterGroup === "affiliates"){
                Object.keys(filterHandler[filterGroup]).forEach(filterItem => {
                    const currentAffiliate = filterHandler?.[filterGroup]?.[filterItem];
            
                    if(currentAffiliate?.active === false){
                        affiliateCount += currentAffiliate?.count;
                    }
            
                    if(currentAffiliate?.accountType === 'fund' && currentAffiliate?.active === true && currentAffiliate.funds){
                        Object.keys(currentAffiliate.funds).forEach(fundKey => {
                            const fund = currentAffiliate.funds[fundKey];
                            if(fund.active === false){
                                // Modify this line based on how you want to handle inactive funds
                                affiliateCount += fund.count;
                            }
                        });
                    }
                });
            }
        });

        if(props.deathBenefitsRange.minVal !== props.deathBenefitsRange.fixedMinVal || props.deathBenefitsRange.maxVal !== props.deathBenefitsRange.fixedMaxVal){
            currentBubbles.push(
                <div
                    key = "deathBenefit"
                    className="policiesFilteredSearchBlock" onClick={ () => { props.resetDeathBenefitsRange() }}>
                    <div className="policiesFilterSearchBlockString">
                    {convertIntToCurrency(props.deathBenefitsRange.minVal, true) + " - " + convertIntToCurrency(props.deathBenefitsRange.maxVal, true)}
                    </div>
                    <div className="resetSearchBarFilterBtn">
                        <CloseIcon/>
                    </div>
                </div>
            );
        }

        if(statusCount > 0){
            currentBubbles.push(
                <div
                    key = "status"
                    className="policiesFilteredSearchBlock" onClick={ () => { resetFilterGroup("status") }}>
                    <div className="policiesFilterSearchBlockString">
                        {statusCount + " filtered out by status"}
                    </div>
                    <div className="resetSearchBarFilterBtn">
                        <CloseIcon/>
                    </div>
                </div>
            )
        }

        if(leadSourceCount > 0){
            currentBubbles.push(
                <div
                    key = "status"
                    className="policiesFilteredSearchBlock" onClick={ () => { resetFilterGroup("leadSource") }}>
                    <div className="policiesFilterSearchBlockString">
                        {leadSourceCount + " filtered out by lead source"}
                    </div>
                    <div className="resetSearchBarFilterBtn">
                        <CloseIcon/>
                    </div>
                </div>
            )
        }

        if(affiliateCount > 0){
            currentBubbles.push(
                <div
                    key = "status"
                    className="policiesFilteredSearchBlock" onClick={ () => { resetFilterGroup("affiliates") }}>
                    <div className="policiesFilterSearchBlockString">
                        {affiliateCount + " filtered out by affiliate"}
                    </div>
                    <div className="resetSearchBarFilterBtn">
                        <CloseIcon/>
                    </div>
                </div>
            )
        }

        return (
            <div className="policiesSearchBarFilters bold">
                {currentBubbles}
            </div>
        );

    }

    function resetFilterGroup(filterGroup) {

        const newStatusGroup = { ...props.filterHandler?.[filterGroup] };

        if(filterGroup === "affiliates"){
            const adminHandler = { ...props?.policiesProps?.sessionVars?.envVars?.adminHandler };
            for (let key in newStatusGroup) {
                if (isNaN(key)) continue;
            
                newStatusGroup[key].active = true;
                if (newStatusGroup[key].accountType === "fund" && newStatusGroup[key].funds) {
                    for (let fundKey in newStatusGroup[key].funds) {
                        if (newStatusGroup[key].funds.hasOwnProperty(fundKey)) {
                            newStatusGroup[key].funds[fundKey].active = true;
                        }
                    }
                }
            }

            props.setFilterHandler({
                ...props.filterHandler,
                affiliates: newStatusGroup
            });

            for (let key in adminHandler) {
                if (isNaN(key)) continue;
            
                if (adminHandler[key].activeHandler) {
                    adminHandler[key].activeHandler.policies = true;
                }
            }
            
            props.policiesProps.sessionVars.envVars?.setAdminHandler(adminHandler);
        }else{
            Object.keys(newStatusGroup).forEach((filterItem) => {
                if (typeof newStatusGroup[filterItem] === "object"){
                    newStatusGroup[filterItem].active = true;
                }
            });
    
            props.setFilterHandler({
                ...props.filterHandler,
                [filterGroup]: newStatusGroup
            });
        }
    }


    // function resetAllSearchParameters(){
    //     props.resetDeathBenefitsRange();
    //     props.resetStatusGroupToggles(true);
    //     props.updateSearchVal(null, true);
    // }

    useEffect(() => {

    }, []);

    return (
        <div className="policiesSearchBarWrapper f g cC">
            <div className="policiesSearchBarInnerWrap">
                <input className="policiesSearchBar" placeholder="Search by Policy Number or Insured Name" onChange={props.updateSearchVal} value={props.searchVal}/>
                {/* {loadFiltersSearchBlocks()} */}
                {Object.keys(props.filterHandler).length > 0 && printFilterBubbles()}
            </div>
        </div>
    )
}

export default SearchBar;
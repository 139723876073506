import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';
import RecommendTwoToneIcon from '@mui/icons-material/RecommendTwoTone';

function BiddingOffer(props){
    const session = props?.session;
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;
    const checkStatus = timelineItem?.checkStatus

    const bidData = () => {
        const [bidAmount, bidRDB, bidNote] = timelineItem?.content.split(",").map(s => s.trim());
        const formattedBidRDB = session?.env?.functions?.convertIntToCurrency(timelineItem?.temp ? timelineItem?.rdb : bidRDB);

        return {
            "bidAmount": session?.env?.functions?.convertIntToCurrency( timelineItem?.temp ? timelineItem?.bidAmount : bidAmount),
            "bidNote" : timelineItem?.temp ? timelineItem?.content : bidNote,
            "bidRDB": formattedBidRDB === "$0" ? undefined : formattedBidRDB,
        };
    };

    return (
        <div className="treelinePost f cC g">
            <div className="f cC s e">
                <div className="treeline">
                </div>
            </div>
            <div className={`newTimelinePost default offer g f bR p ${timelineItem?.checkStatus ?? "Open"} ${props?.stem}`} onClick={props?.onClick}>
                <div className="header g f fR e dG">
                    {timelineItem?.readStatus === false && timelineItem?.originator === "Internal" &&
                        <div className="f cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    }
                    <div className="cL gC2 bold dG">
                        Bid Alert
                        <ErrorTwoToneIcon/>
                    </div>
                    <div className="cR gC3">
                        {timeAgo}
                    </div>
                </div>
                <div className="divider dT">
                </div>
                <div className="content g f cC dG b e">
                    {checkStatus === "Completed" ?
                        <RecommendTwoToneIcon className="dC"/>
                    : checkStatus === "Denied" ?
                            <ThumbDownAltTwoToneIcon className="dC"/>
                        :
                            <ThumbsUpDownTwoToneIcon className="dC"/>
                    }
                    <div className="f cL gC2">
                        {checkStatus === "Open" &&
                            "Pending Bid Offer"
                        }
                        {checkStatus === "Completed" &&
                            "Accepted Bid Offer"
                        }
                        {checkStatus === "Denied" &&
                            "Denied Bid Offer"
                        }
                    </div>
                    <div className="offerData f cR dG fR">
                        <div className="f cR g fR dG">
                            <span>Amount:</span>
                            <div className="dataPoint bold cC dT bR gC2">
                                {bidData()?.bidAmount}
                            </div>
                        </div>
                        {bidData()?.bidRDB &&
                            <div className="f cR g fR dG">
                                <span>RDB:</span>
                                <div className="dataPoint bold cC dT bR gC2">
                                    {bidData()?.bidRDB}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="vDivider gC1 dT"></div>
                    <div className="f cL gC2">
                        {bidData()?.bidNote ?? `No existing ${session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage")} Note to show.`}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BiddingOffer;
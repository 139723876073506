import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './Policies.css';

import SinglePolicy from './SinglePolicy/SinglePolicy';
import PoliciesOverview from './PoliciesOverview';

function Policies(props) {
  return (
    <div className="policiesPage">
      <Routes>
        <Route exact path="/" element={
          <PoliciesOverview
            policies={props.policies}
            policyList={props.policyList}
            setPolicyList={props.setPolicyList}
            filters={props.filters}
            updateFiltersNew={props.updateFiltersNew}
            accountID={props.accountID}
            activityView={props.activityView}
            changeActivityView={props.changeActivityView}
            account={props.account}
            chartData={props.chartData}
            updateChartData={props.updateChartData}
            sessionUser={props.sessionUser}
            loadAccountPolicies={props.loadAccountPolicies}
            filterHandler={props.filterHandler}
            setFilterHandler={props.setFilterHandler}
            deathBenefitsRange={props.deathBenefitsRange}
            setDeathBenefitsRange={props.setDeathBenefitsRange}
            updateDeathBenefitsRange={props.updateDeathBenefitsRange}
            searchVal={props.searchVal}
            setSearchVal={props.setSearchVal}
            searchValHandler={props.searchValHandler}
            setSearchValHandler={props.setSearchValHandler}
            emptyDeathBenefit={props.emptyDeathBenefit}
            setEmptyDeathBenefit={props.setEmptyDeathBenefit}
            filterStatus={props.filterStatus}
            setFilterStatus={props.setFilterStatus}
            sortedPolicies={props.sortedPolicies}
            setSortedPolicies={props.setSortedPolicies}
            deathBenefitsRangeReset={props.deathBenefitsRangeReset}
            setDeathBenefitsRangeReset={props.setDeathBenefitsRangeReset}
            updateDeathBenefitsRangeReset={props.updateDeathBenefitsRangeReset}
            visiblePolicies={props.visiblePolicies}
            setMaxVisiblePolicies={props.setMaxVisiblePolicies}
            maxVisiblePolicies={props.maxVisiblePolicies}
            updateSearchVal={props.updateSearchVal}
            orderPolicies={props.orderPolicies}
            policyOrderDirection={props.policyOrderDirection}
            mobileDevice={props.mobileDevice}
            navigation={props.navigation}
            policiesProps={props.policiesProps}
            session={props?.session}
            />
          }
        />
        <Route path="/:relatedPolicyID" element={
          <SinglePolicy
            policyNumber = {props.policyNumber}
            setPolicyNumber = {props.setPolicyNumber}
            changeActivityView = {props.changeActivityView}
            sessionUser={props.sessionUser}
            updatePage={props.updatePage}
            account={props.account}
            loadNotifications={props.loadNotifications}
            markSessionPolicyAsRead={props.markSessionPolicyAsRead}
            notifications={props.notifications}
            singlePolicyHash={props.singlePolicyHash}
            setSinglePolicyHash={props.setSinglePolicyHash}
            mobileDevice={props.mobileDevice}
            navigation={props.navigation}
            sessionVars={props.sessionVars}
            policiesProps={props.policiesProps}
            session={props?.session}
            />
            } />
        <Route path="/servicing/:relatedPolicyID" element={
          <SinglePolicy
            policyNumber = {props.policyNumber}
            setPolicyNumber = {props.setPolicyNumber}
            changeActivityView = {props.changeActivityView}
            sessionUser={props.sessionUser}
            updatePage={props.updatePage}
            account={props.account}
            loadNotifications={props.loadNotifications}
            markSessionPolicyAsRead={props.markSessionPolicyAsRead}
            notifications={props.notifications}
            singlePolicyHash={props.singlePolicyHash}
            setSinglePolicyHash={props.setSinglePolicyHash}
            mobileDevice={props.mobileDevice}
            navigation={props.navigation}
            sessionVars={props.sessionVars}
            policiesProps={props.policiesProps}
            session={props?.session}
          />
        }
      />
      </Routes>
    </div>
  );
}

export default Policies;
import React, { useEffect, useState } from 'react';

function SessionNavigation(props) {
  // Extract the first part of the path from the URL
  const env = props?.env;
  const user = props?.user?.data;
  const initialPath = env?.functions?.currentPath(window.location);

  const [data, setData] = useState({
    currentInstance: {view : initialPath?.stem, "instance" : initialPath?.recordID ? "single" : "all"},
    history : [{view : initialPath?.stem, "instance" : initialPath?.recordID ? "single" : "all"}],
  });

  const pathHandler = {
    "dashboard": "/dashboard",
    "reporting": "/reporting",
    "policies": "/policies",
    "bids": "/bids",
    "tertiary": "/tertiary",
    "leads": "/leads",
    "upload": "/upload",
  };
  // const initialPath = window.location.pathname.split('/')[1] || 'home';
  const validPaths = {
    "home" : {
      prompt : "Home",
      path : "home",
    },
    "policies" : {
      prompt : "View All Policies",
      path : "policies",
    },
    "leads" : {
      prompt : "View All Leads",
      path : "leads",
    },
    "bids" : {
      prompt : "View All Bids",
      path : "bids",
    },
    "reporting" : {
      prompt : "Reporting",
      path : "reporting",
    },
    "tertiary" : {
      prompt : "View All Tertiary",
      path : "tertiary",
    },
    "upload" : {
      prompt : "Upload",
      path : "upload",
    },
  }

  function isPathEligible(path) {
    const eligablePages = [
      "home",
      "policies",
      "upload",
      "leads",
      "bids",
      "profile",
      "reporting",
      "dashboard",
      "tertiary",
    ];

    const lowerCasePath = path?.toLowerCase();

    return eligablePages.some(page => page.toLowerCase() === lowerCasePath);
  }

  const homepage = (importedPages) => {
    const prioritizedViews = ["dashboard", "policies", "bids", "tertiary", "leads", "reporting", "upload"];
    const pages = importedPages ?? user?.pages;
  
    // Iterate through the prioritized views
    for (const view of prioritizedViews) {
      if (view in pages) {
        // If the view exists as a key in the pages object, return the corresponding path
        return pathHandler[view];
      }
    }
  
    // If no match is found, return the default
    return "/profile";
  };

  const verifyPageAccess = (page) => user?.pages && (Array.isArray(page) ? page.some(p => p in user?.pages) : page in user?.pages);

  function eligablePath(currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null) {
    let path = currentPath === "tertiary" ? "bids" : currentPath;

    console.log(path);
    if(path === "profile"){
      return true;
    }

    if (!isPathEligible(path) ||
      !verifyPageAccess(path)
    ){
      console.log("did not pass")
      return false;
    }

    return true;
  }

  function inlineNavigation(direction){
    const destination = {
      view : "home",
      instance : "all",
      prompt : "Dashboard",
    }

    if(direction === "back"){
      const view = data?.history[data?.history?.length - 1]?.view;

      destination.view = view;
      destination.instance = "all";
      destination.prompt = validPaths[view]?.prompt;
    }
    
    return destination;
  }

  const functions = {
    inlineNavigation,
    homepage,
    verifyPageAccess,
    eligablePath,
    isPathEligible,
  };

  return {
    data,
    setData,
    functions,
  };
}

export default SessionNavigation;
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import '../LeadsDashboard/Leads.css';
import '../BidsDashboard/BidCenter.css';

// import SingleBid from './SingleBid/SingleBid.js';
import BidCenter from '../BidsDashboard/BidCenter';
import SingleView from '../Components/SingleView.js';

import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineIcon from '@mui/icons-material/Timeline';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';

import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';
import CurrentActivity from '../InstanceTabs/CurrentActivity.js';
import TertiaryReportToggles from '../Components/Tertiary/TertiaryReportToggles.js';
import LoadingScreen from '../Components/LoadingScreen.js';

function Bids(props){
    const session = props?.session;
    const location = useLocation();
    const currentPath = session?.env?.functions?.currentPath(location);

    return (
        <div className="bidsPage">
            { currentPath?.recordID ? 
                <SingleView
                    session={session}
                    branch="bids"
                    stem="bids"
                    recordID={currentPath?.recordID}
                />
            :
                <BidCenter
                    updatePage={props.updatePage}
                    sessionUser={props.sessionUser}
                    bidProps={props.bidProps}
                    mobileDevice={props.mobileDevice}
                    navigation={props.navigation}
                    sessionVars={props.sessionVars}
                    session={props?.session}
                />
            }
        {/* <Routes>
            <Route
            exact
            path="/"
            element={
                <BidCenter
                updatePage={props.updatePage}
                sessionUser={props.sessionUser}
                bidProps={props.bidProps}
                mobileDevice={props.mobileDevice}
                navigation={props.navigation}
                sessionVars={props.sessionVars}
                session={props?.session}
                />
            }
            />
            <SingleView
                session={session}
                branch="bids"
                stem="tertiary"
                // permissionPath="tertiary-"
                // branch="policies"
                // stem="tertiary"
                recordID={currentPath?.recordID}
            />
            <Route
                path="/:recordID"
                element={
                    <SingleBid
                    relatedPolicyID={props.relatedPolicyID}
                    setRelatedPolicyID={props.setRelatedPolicyID}
                    policyNumber={props.policyNumber}
                    setPolicyNumber={props.setPolicyNumber}
                    changeActivityView={props.changeActivityView}
                    sessionUser={props.sessionUser}
                    updatePage={props.updatePage}
                    loadNotifications={props.loadNotifications}
                    markSessionPolicyAsRead={props.markSessionPolicyAsRead}
                    notifications={props.notifications}
                    singlePolicyHash={props.singlePolicyHash}
                    setSinglePolicyHash={props.setSinglePolicyHash}
                    mobileDevice={props.mobileDevice}
                    bidsList={props.bidProps.bidsList}
                    setLeadsList={props.setLeadsList}
                    setImportedUploadPolicy={props.setImportedUploadPolicy}
                    bidProps={props.bidProps}
                    navigation={props.navigation}
                    sessionVars={props.sessionVars}
                    session={props?.session}
                    />
                }
                />
            </Routes> */}
        </div>
    );
}

export default Bids;
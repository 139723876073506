import React from 'react';
import { useState, useEffect, useRef } from 'react';

import TimelineIcon from '@mui/icons-material/Timeline';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import VerifiedIcon from '@mui/icons-material/Verified';
import RuleIcon from '@mui/icons-material/Rule';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FolderIcon from '@mui/icons-material/Folder';
import StarsIcon from '@mui/icons-material/Stars';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HistoryIcon from '@mui/icons-material/History';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import TvIcon from '@mui/icons-material/Tv';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ForumIcon from '@mui/icons-material/Forum';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import WcIcon from '@mui/icons-material/Wc';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StartTwoToneIcon from '@mui/icons-material/StartTwoTone';

// import TimelineRequest from '../Components/TimelineRequest';
import Default from '../Components/Timeline2/Default.js';
import TimelineHandler from '../Components/Timeline2/TimelinePostHandler.js';
import Request from '../Components/Timeline2/Request - Origination.js';
import DefaultTimelinePost from '../Components/Timeline/DefaultTimelinePost';
import ServicingTimelinePost from '../Components/Timeline/ServicingTimelinePost';
import MessageBoard from '../Components/MessageBoard/MessageBoard';
import ClosingTab from '../PoliciesDashboard/SinglePolicy/ClosingTab';
import BidsReport from '../PoliciesDashboard/SinglePolicy/BidsReport';
import PremiumSchedule from '../PoliciesDashboard/SinglePolicy/PremiumSchedule';
import TimelinePostDetails from '../Components/Timeline/TimelinePostDetails.js';
import LineChart from "../Components/Chart/LineChart.js";

function TimelineTab(props){
    const session = props?.session;
    const branch = props?.branch;
    const stem = props?.stem;
    const loadingStatus = props?.loadingStatus;
    const currentCase = props?.currentCase?.data;
    const timelineBlock = [];
    const timelineData = props?.timelineData ?? session?.case?.data?.[branch]?.[stem]?.[props?.currentCase?.index]?.timeline;
    let tempNotificationCount = 0;

    const actionBar = () => {
        if(stem === "tertiary" || stem === "bids"){
            return (
                <div className="actionBar bid g cC dG fR bold dP bR">
                    <div
                        className="action g cC fR bR f e p"
                        onClick={()=>{
                            session?.env?.setOverlay({
                                name : "bidTransition",
                                props : {
                                    currentCase : props?.currentCase,
                                    selectedStatus : "Requested Info",
                                    branch : branch,
                                    stem : stem,
                                    portfolioID : props?.portfolioID,
                                    instance : props?.instance
                                }
                            })
                        }}
                    >
                        <PendingActionsIcon/>
                        <div
                            className="f cC gCW"
                        >
                            Request Info
                        </div>
                    </div>
                    <div
                        className={`action g cC fR bR f e nS${props?.instance === "portfolio" ? " inactive" : " p"}`}
                        onClick={()=>{
                            props?.instance !== "portfolio" &&
                            session?.env?.setOverlay({
                                name : "bidTransition",
                                props : {
                                    currentCase : props?.currentCase,
                                    selectedStatus : "Under Review",
                                    branch : branch,
                                    stem : stem,
                                }
                            })
                        }}
                    >
                        <SearchIcon/>
                        <div className="f cC gCW">
                            {session?.env?.functions?.checkSessionExceptions(session, "Under Review", {"accountID" : props?.currentCase?.data?.relatedAccountID}) ?? "Under Review"}
                        </div>
                    </div>
                    <div
                        className={`action g cC fR bR f e nS${props?.instance === "portfolio" ? " inactive" : " p"}`}
                        onClick={()=>{
                            props?.instance !== "portfolio" &&
                            session?.env?.setOverlay({
                                name : "bidTransition",
                                props : {
                                    currentCase : props?.currentCase,
                                    selectedStatus : "Bidding",
                                    branch : branch,
                                    stem : stem,
                                }
                            })
                        }}
                    >
                        <ThumbsUpDownIcon/>
                        <div className="f cC gCW">
                            {session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?
                                "Show Interest"
                            :
                                "Make Bid"
                            }
                        </div>
                    </div>
                    <div
                        className={`action g cC fR bR f e nS${props?.instance === "portfolio" ? " inactive" : " p"}`}
                        onClick={()=>{
                            props?.instance !== "portfolio" &&
                            session?.env?.setOverlay({
                                name : "bidTransition",
                                props : {
                                    currentCase : props?.currentCase,
                                    selectedStatus : "Declined",
                                    branch : branch,
                                    stem : stem,
                                }
                            })
                        }}
                    >
                        <DoDisturbOnIcon/>
                        <div className="f cC gCW">
                            Decline
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    function printTimeline(){
        if(loadingStatus){
            return (
                <div className="singlePolicyEmptyTimeline">
                    <div className="singlePolicyTimelineInner loading loadingDisplay">
                        <div>
                            <CircularProgress color="inherit"/>
                        </div>
                    </div>
                </div>
            )
        }

        if(!timelineData?.list && !currentCase?.submissionDate){
            return (
                <div className="f cC G">
                    No available timeline data
                </div>
            )
        }

        if(currentCase?.nextStep && timelineData?.timelineType === "default"){
            timelineBlock.push(
                <div key="nextStep" className="nextStepPost">
                    <div className="nextStepPostIcon">
                        <NextWeekIcon/>
                    </div>
                    <div className="nextStepPostInner cL">
                        <div className="nextStepIdentifier cC">
                            <span>
                                Next Step:
                            </span>
                        </div>
                        <div className="nextStepPrompt cL s">
                            {currentCase?.nextStep}
                        </div>
                    </div>
                </div>
            );
        }

        if(timelineData?.list){
            Object.keys(timelineData?.list)
            .sort((a, b) => new Date(timelineData.list?.[b]?.timeStamp) - new Date(timelineData?.list?.[a].timeStamp))
            .forEach(key => 
                timelineBlock.push(
                    <TimelineHandler
                        key={key}
                        session={session}
                        branch={branch}
                        stem={stem}
                        currentCase={props?.currentCase}
                        activityView={props?.activityView}
                        timelineItem={timelineData?.list[key]}
                        timelineData={timelineData}
                    />
                )
            );
        }

        if(timelineData?.requestCount == 0 && timelineData?.timelineType === "steps"){
            timelineBlock.push(
                <div
                    key="emptyRequests"
                    className="emptyRequests"
                >
                    <div className="emptyRequestsMessage">
                        <div className="emptyRequestsPrompt">
                            There are no current requests sent out for you to complete
                        </div>
                        <div className="emptyRequestsIcon">
                            <ThumbUpAltIcon/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="timelineActivities g fC dG">
                {timelineBlock?.length ?
                    timelineBlock
                :
                    <div className={`newTimelinePost inactive g cC f bR p ${props?.stem}`}>
                        <div className="content g f fR cC dG">
                            <div className="cL f">
                                No activities have taken place yet
                            </div>
                        </div>
                    </div>
                }
                {(currentCase?.submissionDate || currentCase?.dateCreated) &&
                    <div className="submissionDate f cC g dG">
                        <MoreVertIcon/>
                        <div className="f cC dG fR lH">
                            <StartTwoToneIcon/>
                            <div className="gC2 dG g">
                                Submission Date:
                                <span className="bold gC2">
                                    {session?.env?.functions?.reformatDate(currentCase?.submissionDate ?? currentCase?.dateCreated)}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div key="timeline" className={`timelineWrap g dG b${!timelineData?.list && props?.branch !== "bids" ? " empty" : ''}`}>
            {actionBar()}
            <div key="statuses" className="statuses tO">
                {printTimeline()}
            </div>
        </div>
    );
}

export default TimelineTab;
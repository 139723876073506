import { useState, useEffect } from 'react';

import { timeStamp, convertIntToCurrency } from '../envFunctions.js';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ErrorIcon from '@mui/icons-material/Error';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';

function TimelineFollowUp(props){

    const [checkStatusClass, setCheckStatusClass] = useState("open");

    function updateCheckStatus(toggle){
        if(toggle){
            if(checkStatusClass === "open"){
                setCheckStatusClass("completed");
                toggleCheckStatus("Completed");
                props.updateTimelinePost(props.leadKey, "checkStatus", "Completed", props.objectData.followUpDateTime);
            }else{
                setCheckStatusClass("open");
                toggleCheckStatus("Open");
                props.updateTimelinePost(props.leadKey, "checkStatus", "Open", props.objectData.followUpDateTime);
            }
            return;
        }

        if(props.objectData.checkStatus === "Completed"){
            setCheckStatusClass("completed");  
        }
        if(props.objectData.checkStatus === "Open"){
            setCheckStatusClass("open");
        }
    }

    function toggleCheckStatus(toggle){
        const paramVals = {
            "relatedActivityLogID" : props.objectData.relatedActivityLogID,
            "checkStatus" : toggle
        };

        props.session?.env?.functions?.buildFetchRequest("toggleCheckStatus", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){                        
                }else{
                    console.log("Error on check status toggle.");
                    console.log(resData);
                }
        });
    }

    var internal = '';

    function displayFollowUpcheckStatus(){
        var returningCheckStatus = null;
        if(props.objectData.checkStatus === "Completed"){
            returningCheckStatus = "completed";
        }

        if(props.objectData.checkStatus === "Open"){
            returningCheckStatus = "followUp";
        }

        if(Date.parse(props.objectData.followUpDateTime) < new Date().getTime() && props.objectData.checkStatus === "Open"){
            returningCheckStatus = "missed";
        }

        return returningCheckStatus;
    }

    function functionPointers(){
        return (props.setCurrentStatusKey(props.leadKey), props.setShowStatusDetail(true));
    }

    function convertTimeStamp(timestamp) {
        if(displayFollowUpcheckStatus() === "completed"){
            return " Completed";
        }
    
        var missed = false;
        var timePrompt = " from now";

        timestamp = Date.parse(timestamp);
        timestamp = timestamp/1000;

        var seconds = Math.floor(((timestamp - new Date().getTime()/1000))),
        interval = Math.floor(seconds / 31536000);

        if(seconds < 0){
            missed = true;
            timePrompt = " ago";
            seconds = Math.floor(((new Date().getTime()/1000 - timestamp)));
            interval = Math.floor(seconds / 31536000);
        }

        if (interval > 1) return interval + " yrs" + timePrompt;
    
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return interval + " mo" + timePrompt;
    
        interval = Math.floor(seconds / 86400);
        if (interval > 1){
            return interval + " days" + timePrompt;
        }
        if(internal === 1){
            return interval + " day" + timePrompt;
        }
    
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + "h" + timePrompt;
    
        interval = Math.floor(seconds / 60);
        if (interval > 1) return interval + "m " + timePrompt;
    
        return Math.floor(seconds) + "s" + timePrompt;
    }

    useEffect(() => {
        console.log(props);
        updateCheckStatus();
    }, []);

    return (
        <div className={"timelineFollowUp bR g " + displayFollowUpcheckStatus()} >
            <div className="header g">
                <div className="cC">
                    {/* {!props.data.readStatus &&
                        <div className="cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    } */}
                </div>
                <div className="cL f bold">
                    {displayFollowUpcheckStatus() === "completed" ?
                            "Confirmation"
                            :
                            [<div className="e">
                                Lead Alert
                            </div>,
                            <ErrorIcon/>]
                    }

                </div>
                <div className="cR f e">
                    {convertTimeStamp(props.objectData.followUpDateTime)}
                </div>
            </div>
            <div className="divider">
            </div>
            <div className="content g">
                <div className="followUpIcon">
                    {displayFollowUpcheckStatus() === "completed" &&
                        <SecurityUpdateGoodIcon/>
                    }
                    {displayFollowUpcheckStatus() === "missed" &&
                        <SecurityUpdateWarningIcon/>
                    }
                    {displayFollowUpcheckStatus() === "followUp" &&
                        <AccessAlarmIcon/>
                    }
                </div>
                <div className="followUpPrompt">
                    {displayFollowUpcheckStatus() === "completed" &&
                        "Follow Up Completed"
                    }
                    {displayFollowUpcheckStatus() === "missed" &&
                        "Follow Up Missed"
                    }
                    {displayFollowUpcheckStatus() === "followUp" &&
                        "Follow Up Created"
                    }
                </div>
                <div className="showMoreFollowUp ns" onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
                    Show Details
                </div>
                <div className="followUpCheckBox" onClick={()=>{updateCheckStatus(true)}}>
                    {checkStatusClass === "open" ?
                        <RadioButtonUncheckedIcon/>
                        : null
                    }
                    {checkStatusClass === "completed" ?
                        <CheckCircleIcon/> : null
                    }
                </div>
            </div>

        </div>
    )
}

export default TimelineFollowUp;